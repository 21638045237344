import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

import { Button } from '../../global/button/Button'
import { useSnackbar } from '../../global/context/SnackbarContext'
import IconButton from '../../global/iconButton/IconButton'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { DotsIcon } from '../../icons/DotsIcon'
import { DummyIcon } from '../../icons/DummyIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { WalletDto } from '../../model/WalletDto'
import { InfoCard } from '../../ui/Popups/InfoCard/InfoCard'
import { TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { useAccountActionAccessCheck } from '../../utils/AccountAccess/accountAccessCheck'
import { isZero } from '../../utils/validations'
import {
  WalletRestrictions,
  isDepositEnabledFromAction,
  isRestricted,
  isWalletSuspended,
} from '../../utils/wallet.utils'

import styles from './WalletCard.module.scss'

interface WalletCardProps {
  wallet: WalletDto
  shouldShowReservedBonus: boolean
  onSetWalletNumberModal({ visible, data }: { visible: boolean; data?: WalletDto }): void
  onDeposit: (wallet: WalletDto) => void
  getReservedData: (walletId: string) => void
  hasNegativeBalance: boolean
  // tradingAccountStatusLoading: boolean
  onWithdrawal: (walletId: string) => void
  onNonRestrictedTransfer?: (walletId: string) => void
  onSetModalVisible(v: boolean): void
  isLoading: boolean
}

export const WalletCard: React.FC<WalletCardProps> = ({
  wallet,
  onSetWalletNumberModal,
  shouldShowReservedBonus,
  onDeposit,
  getReservedData,
  hasNegativeBalance,
  // tradingAccountStatusLoading,
  onNonRestrictedTransfer,
  onWithdrawal,
  onSetModalVisible,
  isLoading,
}) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const { addSnackbar } = useSnackbar()

  const { checkAccountTradersRoomAccess, checkAccountOpenAccountAccess } =
    useAccountActionAccessCheck()

  const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
  const isDepositRestricted = isRestricted(WalletRestrictions.DEPOSIT, wallet.restrictions)
  const isCanDepositEnabled = isDepositEnabledFromAction(wallet.depositAction)

  const showSnackbarError = () => addSnackbar.error({ message: t('This action is not available') })

  const isTransferFromRestricted = isRestricted(
    WalletRestrictions.TRANSFER_FROM,
    wallet.restrictions
  )
  const isWithdrawRestricted = isRestricted(WalletRestrictions.WITHDRAW, wallet.restrictions)

  const handleTransferClick = () => {
    checkAccountOpenAccountAccess(() => {
      isTransferFromRestricted ? showSnackbarError() : onNonRestrictedTransfer?.(wallet.id)
    })
  }

  const handleWithdrawClick = () => {
    checkAccountTradersRoomAccess(() => {
      if (!isWithdrawRestricted && !hasNegativeBalance) {
        onWithdrawal(wallet.id)
      } else if (isWithdrawRestricted) {
        showSnackbarError()
      }
    })
  }

  return (
    <div className={styles.card}>
      <div className={styles.titleWrapper}>
        {hasAllRestrictions ? (
          <IconButton onClick={() => onSetModalVisible(true)}>
            <WarningIcon size={20} color='error' />
          </IconButton>
        ) : (
          <DummyIcon />
        )}
        <TextSmall>
          {wallet.currency.id} {t('Wallet.Wallet')}
        </TextSmall>

        <IconButton
          onClick={() =>
            onSetWalletNumberModal({
              visible: true,
              data: wallet,
            })
          }
        >
          <DotsIcon />
        </IconButton>
      </div>
      <h2 className={styles.title}>{formatMoney(wallet.balance, wallet.currency.id)}</h2>
      <div>
        <TextSmall className={styles.subLabel}>{t('Wallet.Reserved')}</TextSmall>
        <TextSmallStrong>{formatMoney(wallet.bonusReserved, wallet.currency.id)}</TextSmallStrong>
      </div>
      {shouldShowReservedBonus && (
        <div>
          <TextSmall className={styles.subLabel}>{t('Wallet.Reserved funds for Bonus')}</TextSmall>
          <TextSmallStrong>
            {isZero(wallet.bonusReserved) ? (
              formatMoney(wallet.bonusReserved, wallet.currency.id)
            ) : (
              <Button
                disabled={isZero(wallet.bonusReserved)}
                className={styles.clickable}
                appearance='link'
                onClick={() => getReservedData(wallet.id)}
              >
                {formatMoney(wallet.bonusReserved, wallet.currency.id)}
              </Button>
            )}
          </TextSmallStrong>
        </div>
      )}
      <div className='mt-4 mb-1'>
        <Button
          onClick={() => {
            if (isDepositRestricted || !isCanDepositEnabled) {
              showSnackbarError()
            } else if (!isDepositRestricted) {
              onDeposit(wallet)
            }
          }}
          size='M'
          fullWidth
          state={isDepositRestricted || !isCanDepositEnabled ? 'disabled' : 'normal'}
          appearance='primary'
        >
          <span className='is-flex is-align-items-center'>
            {!hasAllRestrictions && isDepositRestricted && (
              <WarningIcon size={20} className='mr-1' color='error' />
            )}
            {t('Deposit')}
          </span>
        </Button>
      </div>
      <div className='mb-1'>
        <Button
          size='M'
          state={isTransferFromRestricted ? 'disabled' : 'normal'}
          appearance='secondary'
          fullWidth
          onClick={handleTransferClick}
        >
          <span className={styles.buttonText}>
            {!hasAllRestrictions && isTransferFromRestricted && (
              <WarningIcon size={20} color='error' />
            )}

            {t('Transfer')}
          </span>
        </Button>
      </div>
      <div className='mb-4'>
        <InfoCard
          text={t('Wallet.Withdrawal is currently not available because your trading account')}
          fullWidth
          disabled={!hasNegativeBalance}
        >
          <Button
            size='M'
            state={isWithdrawRestricted || hasNegativeBalance ? 'disabled' : 'normal'}
            disabled={hasNegativeBalance}
            fullWidth
            appearance='secondary'
            onClick={handleWithdrawClick}
            loading={isLoading}
          >
            <span className='is-flex is-align-items-center'>
              {!hasAllRestrictions && isWithdrawRestricted && (
                <WarningIcon size={20} className='mr-1' color='error' />
              )}
              {t('Withdraw')}
            </span>
          </Button>
        </InfoCard>
      </div>
      <div>
        <Link
          className={styles.history}
          to='/dashboard/traders-room/transaction-history'
          state={{ walletId: wallet.id }}
        >
          {t('View Transaction History')}
        </Link>
      </div>
    </div>
  )
}
