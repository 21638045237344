import React, { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { ExternalLink } from '../../global/ExternalLink'
import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { useAccountInfo } from '../../global/context/AccountInfoContext/AccountInfoContext.Provider'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { RawFormCheckBoxField } from '../../global/formField/FormCheckBoxField'
import { createFormField } from '../../global/formField/FormField'
import IconButton from '../../global/iconButton/IconButton'
import { CancelActionModal } from '../../global/modal/CancleActionModal'
import { InfoModal } from '../../global/modal/InfoModal'
import { Modal } from '../../global/modal/Modal'
import { FormTemplate } from '../../global/templates/FormTemplate'
import { TransactionFormActionButtonTemplate } from '../../global/templates/TransactionFormActionButtonTemplate'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { DropArrowDownIcon } from '../../icons/DropArrowDownIcon'
import { DropArrowUpIcon } from '../../icons/DropArrowUpIcon'
import { InfoIcon } from '../../icons/InfoIcon'
import { PlatformInfoIcon } from '../../icons/PlatformInfoIcon'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { AccountTypeEnum } from '../../model/AccountGroupType'
import {
  CampaignCalculationType,
  isCampaignCalculationRoiPercentageType,
} from '../../model/CampaignCalculationType'
import {
  isCampaignClientContestType,
  isCampaignLiveRebateAccountType,
} from '../../model/CampaignType'
import {
  CreateCampaignTradingAccount,
  CreateTradingAccount,
} from '../../model/CreateTradingAccount'
import { LeverageTypeDto, isLeverageTypeDynamic } from '../../model/LeverageTypeDto'
import { NameDto } from '../../model/NameDto'
import {
  PlatformTypeEnum,
  isTMTPlatformType,
  isTradingViewPlatformType,
} from '../../model/PlatformTypeEnum'
import { TradingAccountPlatformTypesOptions } from '../../model/TradingAccountCreationOptions'
import { TradingAccountInfo } from '../../model/TradingAccountInfo'
import { CurrencyType, WalletCurrency, WalletDto } from '../../model/WalletDto'
import { TextSmall, TextStrong } from '../../ui/Typography/Typography'
import { useApiClient } from '../../utils/ApiClient'
import { usePathHistoryContext } from '../../utils/PathHistoryContext'
import { useEntityConfig } from '../../utils/TradingAccountEntityConfigurationContext'
import { ClientApiClient } from '../../utils/clientApi'
import {
  isTickmillAS,
  isTickmillEU,
  isTickmillSC,
  isTickmillUK,
} from '../../utils/companyName.utils'
import { FormSubmitValues, IBPattern } from '../../utils/formValidation'
import { isOne } from '../../utils/validations'
import { AccountTypeModal } from './AccountTypeModal'
import { CampaignCurrencyModal } from './CampaignCurrencyModal'
import { DynamicLeverageModal } from './DynamicLeverageModal'
import { LeverageModal } from './LeverageModal'
import { RegularCampaignAccountTypeModal } from './RegularCampaignAccounTypeModal'
import { TradingAccountWalletModal } from './TradingAccountWalletModal'
import { TradingPlatformModal } from './TradingPlatformModal'

import styles from './TradingAccountsPage.module.scss'

export interface CreateTradingAccountCampaignFormValues {
  tradingAccount: CampaignExtendedName
  wallet: WalletDto | undefined
  currencyId: CurrencyType | undefined
  calculationType: NameDto<CampaignCalculationType> | undefined
  platformType: NameDto<PlatformTypeEnum> | undefined
  accountType: NameDto | undefined
  leverageType: LeverageTypeDto | undefined
  receivesReports: boolean
  introducingBroker: undefined | { id: string; name: string; isCustomSpreadMarkup?: boolean }
  campaignId: string | null
  ibCode: string
  terms: boolean
  markup: boolean
}

type CampaignExtendedName = { id: string; name: string; platformTypes: NameDto[] } | undefined

export type CampaignExtendedNameDto = Omit<NameDto, 'id'> & {
  id: string
  termsAndConditions?: string
  isCampaign?: boolean
  type?: NameDto
  calculationType?: NameDto<CampaignCalculationType>
  platformTypes: NameDto[]
  campaignDescription?: string
}

const FormField = createFormField<CreateTradingAccountCampaignFormValues>()

type CreateTradingAccountCampaignFormProps = FormikProps<CreateTradingAccountCampaignFormValues> &
  OuterProps

const useAccountTypesFetch = (props: CreateTradingAccountCampaignFormProps) => {
  const { values } = props

  const apiClient = useApiClient(ClientApiClient)
  const [accountTypes, setAccountTypes] = useState<NameDto<number>[]>([])

  const fetchAccountTypes = async () => {
    if (values.platformType?.id) {
      if (values.wallet?.currency?.id) {
        setAccountTypes(
          await apiClient.getAccountType(values.platformType.id, values.wallet.currency.id)
        )
      } else if (values?.currencyId) {
        setAccountTypes(await apiClient.getAccountType(values.platformType.id, values.currencyId))
      }
    }
  }

  useEffect(() => {
    if (values.platformType?.id && (values.wallet?.currency?.id || values.currencyId)) {
      fetchAccountTypes()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.wallet?.currency, values.platformType, values.currencyId])

  return { fetchAccountTypes, accountTypes, setAccountTypes }
}

const useTradingAccountCreationOptionsFetch = (props: CreateTradingAccountCampaignFormProps) => {
  const { values } = props
  const { product } = useProductReadContext()
  const apiClient = useApiClient(ClientApiClient)
  const { accountInfo } = useAccountInfo()

  const [campaignType, setCampaignType] = useState<NameDto | undefined>()
  const [campaignCurrencies, setCampaignCurrencies] = useState<CurrencyType[]>([])
  const [platformTypes, setPlatformTypes] = useState<TradingAccountPlatformTypesOptions>([])
  const [isLoading, setIsLoading] = useState(false)

  const getRegularAccountPlatforms = async () => {
    const filteredPlatforms = accountInfo
      ?.filter((accountInfo) => accountInfo.product.id === product)
      .map((accountInfo) => ({ id: accountInfo.platform.id, name: accountInfo.platform.name }))
    setPlatformTypes(filteredPlatforms ?? [])
  }

  const fetchTradingAccountCreationOptions = async () => {
    setIsLoading(true)

    try {
      if (values.campaignId) {
        const creationOptions = await apiClient.getTradingAccountCreationOptions(
          values.campaignId,
          {
            platformTypeId: values.platformType?.id.toString(),
          }
        )
        setCampaignCurrencies(creationOptions.currencies)
        setPlatformTypes(creationOptions.platformTypes)
      } else {
        getRegularAccountPlatforms()
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setCampaignType(props.data.find((campaign) => campaign.id === values.campaignId)?.type)
    fetchTradingAccountCreationOptions()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.campaignId, values.platformType])

  return {
    campaignType,
    campaignCurrencies,
    platformTypes,
    isLoading,
  }
}

const useValidateAccountIntroducingBroker = (
  props: CreateTradingAccountCampaignFormProps,
  setAllFieldsDisabled: (value: boolean) => void
) => {
  const { values, errors, setFieldValue, setFieldError } = props

  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)

  useEffect(() => {
    let delayDebounceFn: NodeJS.Timeout
    if (values.ibCode && !errors.ibCode) {
      setAllFieldsDisabled(true)
      delayDebounceFn = setTimeout(async () => {
        try {
          const response = await apiClient.validateAccountIntroducingBrokerByCode(values.ibCode)
          if (response.isCustomSpreadMarkup) {
            // make this field unchecked to force user to check this one, by default it's checked
            // but we show it only when isCustomSpreadMarkup is true
            setFieldValue('markup', false)
          }
          setFieldValue('introducingBroker', response)
          setAllFieldsDisabled(false)
          setFieldError('ibCode', '')
        } catch {
          setAllFieldsDisabled(true)
          setFieldError('ibCode', t('Referral code does not exist'))
        }
      }, 1500)
    }

    return () => clearTimeout(delayDebounceFn)
  }, [
    apiClient,
    errors.ibCode,
    setAllFieldsDisabled,
    setFieldError,
    setFieldValue,
    t,
    values.ibCode,
  ])
}

const usePlatformData = (
  props: CreateTradingAccountCampaignFormProps,
  platformTypes: TradingAccountPlatformTypesOptions = [],
  allFieldsDisabled: boolean
) => {
  const { values, account } = props
  const { product } = useProductReadContext()
  const { accountInfo } = useAccountInfo()
  const { accountTypes = [] } = useAccountTypesFetch(props)
  const accountFilteredPlatforms = useMemo(
    () =>
      accountInfo
        .filter((x) => x.maxAccountsAllowed > 0)
        .filter((x) =>
          platformTypes.length ? platformTypes.map((x) => x.id).includes(x.platform.id) : true
        )
        .filter((x) => x.product.id === product)
        .filter((x) => {
          if (isTMTPlatformType(x.platform.id) || isTradingViewPlatformType(x.platform.id)) {
            return isTickmillSC(account) || isTickmillAS(account)
          } else {
            return true
          }
        }),
    [accountInfo, platformTypes, product, account]
  )

  const rearrangedTradingPlatforms: TradingAccountInfo[] = useMemo(
    () =>
      platformTypes
        .map((platformType) => {
          const matchingTradingPlatform = accountFilteredPlatforms.find(
            ({ platform }) => platform.id === platformType.id
          )
          return matchingTradingPlatform ? matchingTradingPlatform : null
        })
        .filter((item): item is TradingAccountInfo => Boolean(item)),
    [accountFilteredPlatforms, platformTypes]
  )

  const platformsAllowToOpenAccount = useMemo(
    () => rearrangedTradingPlatforms.filter((x) => x.allowToOpenAccount),
    [rearrangedTradingPlatforms]
  )

  const platformAllowToOpenAccount = useMemo(
    () => platformsAllowToOpenAccount[0],
    [platformsAllowToOpenAccount]
  )

  const platformsDisallowToOpenAccount = useMemo(
    () => rearrangedTradingPlatforms.filter((x) => !x.allowToOpenAccount),
    [rearrangedTradingPlatforms]
  )

  const isCampaign = useMemo(
    () => values.campaignId && values.campaignId !== '0',
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [values.campaignId]
  )

  const isAccountTypeDisabled = useMemo(
    () =>
      !isCampaign
        ? !values.wallet || allFieldsDisabled
        : !values.currencyId || !values.platformType || allFieldsDisabled,
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [isCampaign, values.wallet, values.currencyId, values.platformType, allFieldsDisabled]
  )

  const isWalletDisabled = useMemo(() => {
    return !values.platformType || allFieldsDisabled
  }, [values.platformType, allFieldsDisabled])

  const isLeverageDisabled = useMemo(
    () =>
      !isCampaign
        ? !values.wallet ||
          !values.platformType ||
          !values.accountType ||
          allFieldsDisabled ||
          isTMTPlatformType(values.platformType.id) ||
          isTradingViewPlatformType(values.platformType.id)
        : !values.currencyId ||
          !values.platformType ||
          !values.accountType ||
          allFieldsDisabled ||
          isTMTPlatformType(values.platformType.id) ||
          isTradingViewPlatformType(values.platformType.id),
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isCampaign,
      values.wallet,
      values.currencyId,
      values.platformType,
      values.accountType,
      allFieldsDisabled,
    ]
  )

  const accountTypesData = useMemo(
    () => {
      const isAccountFromBrazil = account?.addresses?.some(
        (address) => address?.country?.id === 'BRA'
      )

      const classicAccountType = accountTypes?.find(
        (accountType) => accountType?.id === AccountTypeEnum.Classic
      )

      const nonClassicAccountTypes = accountTypes?.filter(
        (accountType) => accountType?.id !== AccountTypeEnum.Classic
      )

      const accountTypesFilter = classicAccountType
        ? [classicAccountType, ...nonClassicAccountTypes]
        : nonClassicAccountTypes

      return isAccountFromBrazil ? accountTypesFilter : accountTypes
    },
    //eslint-disable-next-line react-hooks/exhaustive-deps
    [account?.addresses, accountTypes]
  )

  return {
    isCampaign,
    isWalletDisabled,
    isLeverageDisabled,
    isAccountTypeDisabled,
    accountTypes: accountTypesData,
    platformsAllowToOpenAccount,
    platformAllowToOpenAccount,
    platformsDisallowToOpenAccount,
    isOnlyOnePlatformsAllowToOpenAccount: platformsAllowToOpenAccount.length === 1,
  }
}

const CreateTradingAccountCampaignFormUI: React.FC<CreateTradingAccountCampaignFormProps> = (
  props
) => {
  const {
    handleSubmit,
    setSubmitting,
    values,
    currencies,
    setFieldValue,
    setFieldError,
    setTouched,
    isValid,
    isCurrenciesLoading,
    account,
    setSelectedPlatform,
  } = props

  const { t } = useTranslation()
  const apiClient = useApiClient(ClientApiClient)
  const { formatMoney } = useFormatNumber()
  const locale = useSessionLanguage()
  const { navigateToPreviousPath } = usePathHistoryContext()
  const entityConfig = useEntityConfig()

  const [isLoading, setIsLoading] = useState(true)

  const [ib, setIb] = useState<NameDto<string>>(createIbInitialState(values))

  const [isWalletModalOpen, setWalletModalOpen] = useState(false)
  const [isCurrencyModalOpen, setCurrencyModalOpen] = useState(false)
  const [isPlatformTypeModalOpen, setPlatformTypeModalOpen] = useState(false)
  const [isMarkupModalOpen, setMarkupModalOpen] = useState(false)
  const [isLeverageModalOpen, setLeverageModalOpen] = useState(false)
  const [isRegularCampaignAccountTypeModalOpen, setRegularCampaignAccountTypeModalOpen] =
    useState(false)
  const [isAccountTypeModalOpen, setAccountTypeModalOpen] = useState(false)
  const [isCancelModalOpen, setCancelModalOpen] = useState(false)
  const [isIntroducingBrokerInfoModalOpen, setIntroducingBrokerInfoModalOpen] = useState(false)
  const [isDynamicLeverageModalOpen, setDynamicLeverageModalOpen] = useState(false)

  const [allFieldsDisabled, setAllFieldsDisabled] = useState(false)
  const [isPlatformFormDisabled, setIsPlatformFormDisabled] = useState(false)
  const [isReferralSectionExpanded, setIsReferralSectionExpanded] = useState(false)

  const {
    campaignType,
    platformTypes,
    campaignCurrencies,
    isLoading: isTACreationOptionsLoading,
  } = useTradingAccountCreationOptionsFetch(props)
  const isCurrencyFieldDisabled =
    allFieldsDisabled ||
    !values.platformType ||
    isOne(campaignCurrencies.length) ||
    isCurrenciesLoading

  useValidateAccountIntroducingBroker(props, setAllFieldsDisabled)

  const {
    isCampaign,
    isWalletDisabled,
    isLeverageDisabled,
    isAccountTypeDisabled,
    accountTypes,
    platformAllowToOpenAccount,
    platformsAllowToOpenAccount,
    platformsDisallowToOpenAccount,
    isOnlyOnePlatformsAllowToOpenAccount,
  } = usePlatformData(props, platformTypes, allFieldsDisabled)

  useEffect(() => {
    handleCurrencyId()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignCurrencies])

  const handleCurrencyId = () => {
    if (isOne(campaignCurrencies.length)) {
      setFieldValue('currencyId', campaignCurrencies[0])
    } else {
      setFieldValue('currencyId', undefined)
    }
  }

  const handleWallet = (wallet: WalletDto) => {
    setFieldValue('wallet', wallet)
    setFieldValue('accountType', undefined)
    if (!isLeverageTypeDynamic(values.leverageType?.id)) {
      setFieldValue('leverageType', undefined)
    }
    setWalletModalOpen(false)
  }

  const handleCurrency = (currencyId: CurrencyType) => {
    setFieldValue('currencyId', currencyId)
    setCurrencyModalOpen(false)
  }

  const refreshLeverageType = async (platformId: PlatformTypeEnum | undefined) => {
    if (isTMTPlatformType(platformId) || isTradingViewPlatformType(platformId)) {
      const dynamicLeverage = (await apiClient.getLeverageTypes(platformId)).find((leverage) =>
        isLeverageTypeDynamic(leverage.id)
      )
      if (dynamicLeverage) {
        setFieldValue('leverageType', dynamicLeverage)
      } else {
        setFieldValue('leverageType', undefined)
      }
    } else {
      setFieldValue('leverageType', undefined)
    }
  }

  const handleSetTradingPlatform = (platform?: NameDto) => {
    setPlatformTypeModalOpen(false)
    setFieldValue('wallet', undefined)
    setFieldValue('accountType', undefined)
    setFieldValue('platformType', platform)
    setSelectedPlatform(platform)
    // TODO add when IB is set
    refreshLeverageType(platform?.id)
    if (platform) {
      props.onChangePlatform(platform?.id)
    }
  }

  const handleSetAccountOrCampaign = (value: CampaignExtendedNameDto) => {
    handleCurrencyId()
    refreshLeverageType(undefined)

    if (value.id === '0') {
      setFieldValue('tradingAccount', value)
      setFieldValue('campaignId', null)
      setFieldValue('platformType', undefined)
      setFieldValue('wallet', undefined)
      setFieldValue('accountType', undefined)
      return setRegularCampaignAccountTypeModalOpen(false)
    }
    setTouched({ currencyId: false })
    setFieldValue('terms', false)
    setFieldValue('calculationType', value.calculationType)
    setFieldValue('platformType', undefined)
    setFieldValue('accountType', undefined)
    setFieldValue('campaignId', value.id)
    setFieldValue('tradingAccount', value)
    setRegularCampaignAccountTypeModalOpen(false)
  }

  const handleSetLeverage = (leverage: LeverageTypeDto) => {
    setFieldValue('leverageType', leverage)
    setLeverageModalOpen(false)
  }

  const handleSetAccountType = (accountType: NameDto) => {
    setFieldValue('accountType', accountType)
    if (!isLeverageTypeDynamic(values.leverageType?.id)) {
      setFieldValue('leverageType', undefined)
    }
    setAccountTypeModalOpen(false)
  }

  const handleSubmitForm = async () => {
    setMarkupModalOpen(false)
    setIsLoading(true)
    setSubmitting(true)
    handleSubmit()
  }

  useEffect(() => {
    setIsLoading(true)
    if (platformsAllowToOpenAccount.length === 0) {
      setIsPlatformFormDisabled(true)
      handleSetTradingPlatform(undefined)
    }
    if (platformsAllowToOpenAccount.length <= 1) {
      const [allowedPlatform] = platformsAllowToOpenAccount
      setIsPlatformFormDisabled(true)
      if (allowedPlatform) {
        handleSetTradingPlatform(allowedPlatform.platform)
      }
    } else {
      setIsPlatformFormDisabled(false)
    }

    setIsLoading(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformsAllowToOpenAccount, locale])

  const hasReachedMaxAccounts = useMemo(
    () => platformsDisallowToOpenAccount.length > 0,
    [platformsDisallowToOpenAccount]
  )

  const getDefaultName = (name: string): string => {
    if (name.startsWith('Classic')) {
      return `Classic (${t('Most Popular')})`
    }
    return name
  }

  const updatedAccountTypeName = useMemo(() => {
    setFieldValue('accountType', undefined)

    return accountTypes.map((accountType) => ({
      ...accountType,
      name: getDefaultName(accountType.name),
    }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountTypes, locale, entityConfig])

  const toggleReferralSection = () => setIsReferralSectionExpanded((prev) => !prev)

  const renderReferral = useMemo(() => {
    if (isTickmillUK(account)) {
      if (!account?.introducingBroker) {
        return false
      }
    }

    if (isTickmillEU(account)) {
      return false
    }

    if (account?.affiliate) {
      return false
    }

    return true
  }, [account])

  return (
    <React.Fragment>
      {isIntroducingBrokerInfoModalOpen && (
        <Modal
          closeModal={() => setIntroducingBrokerInfoModalOpen(false)}
          render={({ closeModal }) => <IntroducingBrokerInfoModal closeModal={closeModal} />}
        />
      )}
      {isDynamicLeverageModalOpen && (
        <DynamicLeverageModal closeModal={() => setDynamicLeverageModalOpen(false)} />
      )}
      {isMarkupModalOpen && (
        <Modal
          closeModal={() => setMarkupModalOpen(false)}
          render={({ closeModal }) => (
            <MarkupInfoModal onSubmit={handleSubmitForm} closeModal={closeModal} />
          )}
        />
      )}
      {isWalletModalOpen && (
        <TradingAccountWalletModal
          currencies={currencies}
          onSelectOption={handleWallet}
          onClose={() => setWalletModalOpen(false)}
        />
      )}
      {isCurrencyModalOpen && (
        <CampaignCurrencyModal
          currencies={campaignCurrencies}
          onSelectOption={handleCurrency}
          onClose={() => setCurrencyModalOpen(false)}
          selectedCurrencyId={values.currencyId}
        />
      )}
      {isPlatformTypeModalOpen && (
        <TradingPlatformModal
          tradingAccountsAccountInfo={platformsAllowToOpenAccount}
          onSelectOption={handleSetTradingPlatform}
          onClose={() => setPlatformTypeModalOpen(false)}
        />
      )}
      {isRegularCampaignAccountTypeModalOpen && (
        <RegularCampaignAccountTypeModal
          campaigns={props.data}
          isCampaign={props.hasCampaignQuery}
          onSelectOption={handleSetAccountOrCampaign}
          onClose={() => setRegularCampaignAccountTypeModalOpen(false)}
        />
      )}
      {isLeverageModalOpen && (
        <LeverageModal
          title={t('Trading Account.Select Leverage')}
          platformTypeId={values.platformType?.id}
          restrictions={[]}
          onSelect={handleSetLeverage}
          onClose={() => setLeverageModalOpen(false)}
        />
      )}
      {isAccountTypeModalOpen && (
        <AccountTypeModal
          accountTypes={updatedAccountTypeName}
          accountType={values.accountType}
          onSelect={handleSetAccountType}
          onClose={() => setAccountTypeModalOpen(false)}
        />
      )}
      {isCancelModalOpen && (
        <Modal
          closeModal={() => setCancelModalOpen(false)}
          render={() => (
            <CancelActionModal
              onConfirm={() =>
                navigateToPreviousPath({
                  state: { fromAddCampaignTradingAccountForm: true, wallets: true },
                })
              }
              onCancel={() => setCancelModalOpen(false)}
            />
          )}
        />
      )}
      <Loading showLoadingIcon isLoading={isLoading}>
        <FormTemplate
          title={
            isCampaign
              ? t('Trading Account.Add Campaign Trading Account')
              : t('Trading Account.Add Trading Account')
          }
          goBack={() => setCancelModalOpen(true)}
        >
          <Form>
            {!isTickmillEU(props.account) && !isTickmillUK(props.account) && (
              <NameField
                data={props.data}
                campaignId={values.campaignId}
                calculationType={values.calculationType}
                campaignType={campaignType}
                allFieldsDisabled={allFieldsDisabled}
                onRegularCampaignAccountTypeModalOpen={() =>
                  setRegularCampaignAccountTypeModalOpen(true)
                }
              />
            )}

            <FormField
              name='platformType.name'
              label={t('Trading Account.Trading Platform')}
              placeholder={t('Trading Account.Trading Platform')}
              rightIcon={<DropArrowDownIcon />}
              disabled={allFieldsDisabled || isPlatformFormDisabled || isTACreationOptionsLoading}
              loading={isTACreationOptionsLoading}
              readOnly
              required
              onClick={() => setPlatformTypeModalOpen(true)}
              key={values.platformType?.name ? 'platformType' : 'no-platformType'}
            />
            {hasReachedMaxAccounts && (
              <div className={styles.platformInfo}>
                <PlatformInfoIcon />
                <p className='ml-1'>
                  <span>
                    {t(
                      'Trading Account.You have reached the maximum amount of Live Trading Accounts for platform'
                    )}
                  </span>
                  <span className={styles.platformInfoStrong}>
                    {' '}
                    {platformsDisallowToOpenAccount.map((x) => x.platform.name).join(', ')}
                  </span>
                </p>
              </div>
            )}
            {!isCampaign && (
              <FormField
                key={values.wallet ? 'has-wallet' : 'no-wallet'}
                name='wallet.name'
                label={t('Trading Account.Wallet')}
                placeholder={t('Trading Account.Wallet')}
                disabled={isWalletDisabled}
                required
                value={
                  values.wallet
                    ? `${t('Trading Account.Wallet')} ${values.wallet?.currency.id} (${
                        values?.wallet?.name
                      })`
                    : undefined
                }
                rightIcon={<DropArrowDownIcon />}
                readOnly
                hint={
                  values.wallet
                    ? `${t('Wallet.Balance')} ${formatMoney(
                        values.wallet.balance,
                        values.wallet.currency.id
                      )}`
                    : null
                }
                onClick={() => setWalletModalOpen(true)}
              />
            )}
            {isCampaign && (
              <FormField
                name='currencyId'
                label={t('Campaigns.Currency')}
                placeholder={t('Campaigns.Currency')}
                value={values.currencyId}
                disabled={isCurrencyFieldDisabled}
                loading={isCurrenciesLoading}
                required
                rightIcon={<DropArrowDownIcon />}
                readOnly
                onClick={() => setCurrencyModalOpen(true)}
                validateOnMount
                key={values.currencyId ? 'currencyId' : 'no-currencyId'}
              />
            )}
            <FormField
              name='accountType.name'
              label={t('Trading Account.Account Type')}
              placeholder={t('Trading Account.Account Type')}
              rightIcon={<DropArrowDownIcon />}
              readOnly
              required
              disabled={isAccountTypeDisabled || isCurrenciesLoading}
              onClick={() => setAccountTypeModalOpen(true)}
              key={values.accountType?.id ? 'accountType' : 'no-accountType'}
            />
            <FormField
              name='leverageType.name'
              label={t('Trading Account.Leverage')}
              disabled={isLeverageDisabled}
              placeholder={t('Trading Account.Leverage')}
              rightIcon={<DropArrowDownIcon />}
              readOnly
              required
              onClick={() => setLeverageModalOpen(true)}
              hint={
                isLeverageTypeDynamic(values.leverageType?.id) ? (
                  <span onClick={() => setDynamicLeverageModalOpen(true)} className='is-link'>
                    {t('Trading Account.Leverage Information')}
                  </span>
                ) : undefined
              }
              key={values.leverageType?.id ? 'leverageType' : 'no-leverageType'}
            />
            {/*show this field when we have new IB selected*/}
            {values.introducingBroker?.isCustomSpreadMarkup && (
              <RawFormCheckBoxField name='markup' disabled={!!allFieldsDisabled}>
                {t('Trading Account.Markup Modal')}
              </RawFormCheckBoxField>
            )}
            {values.campaignId && (
              <RawFormCheckBoxField
                name='terms'
                disabled={!!allFieldsDisabled}
                onChange={() => {
                  setFieldValue('terms', !values.terms)
                }}
              >
                {t('I have read all instructions and agree with')}{' '}
                <ExternalLink
                  className='is-link'
                  title={t('Terms & Conditions')}
                  url={props.data.find((x) => x.id === values?.campaignId)?.termsAndConditions}
                >
                  {t('Terms & Conditions')}
                </ExternalLink>{' '}
                {t('of the campaign')}
              </RawFormCheckBoxField>
            )}

            {renderReferral && (
              <>
                <div className={styles.referralTitleWrapper}>
                  <IconButton className='has-cursor-pointer' onClick={toggleReferralSection}>
                    <div className={styles.toggleButton}>
                      {isReferralSectionExpanded ? <DropArrowUpIcon /> : <DropArrowDownIcon />}
                    </div>
                    <TextStrong className={styles.textColor}>
                      {t('Trading Account.Referral Code')}
                    </TextStrong>
                  </IconButton>
                  <div className={styles.infoButton}>
                    <IconButton
                      className='has-cursor-pointer'
                      onClick={() => setIntroducingBrokerInfoModalOpen(true)}
                    >
                      <InfoIcon />
                    </IconButton>
                  </div>
                </div>
                {isReferralSectionExpanded && (
                  <div className={styles.radioGroup}>
                    {createReferralOptions(props)
                      .filter(filterReferralOptions(props))
                      .map((referral) => (
                        <ReferralField
                          referral={referral}
                          ib={ib}
                          account={account}
                          platformAllowToOpenAccount={platformAllowToOpenAccount}
                          isOnlyOnePlatformsAllowToOpenAccount={
                            isOnlyOnePlatformsAllowToOpenAccount
                          }
                          setIb={setIb}
                          setAllFieldsDisabled={setAllFieldsDisabled}
                        />
                      ))}
                  </div>
                )}
              </>
            )}
            {ib?.id === '0' ? null : ib.id === '1' ? (
              <React.Fragment>
                <FormField
                  name='ibCode'
                  label={t('Trading Account.Referral Code')}
                  placeholder={t('Trading Account.Referral Code')}
                  onChange={(event) => {
                    if (!event.target.value) {
                      setFieldError('ibCode', '')
                      setAllFieldsDisabled(true)
                    }
                    setFieldValue('ibCode', event.target.value)
                  }}
                  required
                />
              </React.Fragment>
            ) : (
              <FormField
                name='ibCode'
                label={t('Trading Account.Referral Code')}
                placeholder={t('Trading Account.Referral Code')}
                required
                disabled
              />
            )}

            <div className={styles.w8FormNote}>
              <TextSmall>
                {t('W8.To be able to view and trade the CFD Stocks that are available')}
              </TextSmall>
            </div>
            <TransactionFormActionButtonTemplate>
              <Button
                appearance='secondary'
                size='L'
                type='button'
                onClick={() => setCancelModalOpen(true)}
              >
                {t('Cancel')}
              </Button>
              <Button
                appearance='primary'
                size='L'
                type='button'
                onClick={async () => {
                  if (values.introducingBroker?.isCustomSpreadMarkup) {
                    setMarkupModalOpen(true)
                  } else {
                    await handleSubmitForm()
                  }
                }}
                disabled={!isValid || allFieldsDisabled}
              >
                {t('Confirm')}
              </Button>
            </TransactionFormActionButtonTemplate>
          </Form>
        </FormTemplate>
      </Loading>
    </React.Fragment>
  )
}

interface NameFieldProps {
  data: CampaignExtendedNameDto[]
  allFieldsDisabled: boolean
  campaignId: string | null
  calculationType: NameDto | undefined
  campaignType: NameDto | undefined
  onRegularCampaignAccountTypeModalOpen(): void
}

const NameField: React.FC<NameFieldProps> = (props) => {
  const {
    data,
    campaignId,
    calculationType,
    campaignType,
    allFieldsDisabled,
    onRegularCampaignAccountTypeModalOpen,
  } = props

  const { t } = useTranslation()

  const handleRegularCampaignAccountTypeModalOpen = () => {
    onRegularCampaignAccountTypeModalOpen()
  }

  const getHintText = () => {
    if (
      isCampaignCalculationRoiPercentageType(calculationType?.id) &&
      isCampaignClientContestType(campaignType?.id)
    ) {
      return data.find((c) => c.id === campaignId)?.campaignDescription || ''
    }

    if (!campaignId || isCampaignLiveRebateAccountType(campaignType?.id)) {
      return ''
    }

    return t('Trading Account.Very simply, get paid to trade')
  }

  return (
    <FormField
      name='tradingAccount.name'
      type='text'
      label={t('Trading Account.Trading Account Type')}
      placeholder={t('Trading Account.Trading Account Type')}
      rightIcon={<DropArrowDownIcon />}
      disabled={allFieldsDisabled || isOne(data.length)}
      required
      readOnly
      onClick={handleRegularCampaignAccountTypeModalOpen}
      hint={getHintText()}
    />
  )
}

interface ReferralFieldProps {
  ib: NameDto<string>
  referral: NameDto<string>
  platformAllowToOpenAccount: TradingAccountInfo
  isOnlyOnePlatformsAllowToOpenAccount: boolean
  account: AccountDetailedDto | undefined
  setIb(value: NameDto<string>): void
  setAllFieldsDisabled(value: boolean): void
}

const ReferralField: React.FC<ReferralFieldProps> = (props) => {
  const {
    account,
    referral,
    ib,
    platformAllowToOpenAccount,
    isOnlyOnePlatformsAllowToOpenAccount,
    setIb,
    setAllFieldsDisabled,
  } = props

  const { t } = useTranslation()
  const { setFieldValue, values, setFieldError } =
    useFormikContext<CreateTradingAccountCampaignFormValues>()

  const handleChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target
    const { campaignId, tradingAccount } = { ...values }
    setFieldValue('campaignId', campaignId)
    setFieldValue('tradingAccount', tradingAccount)

    if (account?.introducingBroker && value === account.introducingBroker.id) {
      setIb(referral)
      setFieldValue('ibCode', account.introducingBroker.name)
      setFieldValue('introducingBroker', account?.introducingBroker)
    } else if (value === GenericReferralType.New) {
      setFieldValue('ibCode', '')
      setFieldValue('terms', true)
      setFieldError('ibCode', '')
      setIb({
        id: '1',
        name: t('New Referral'),
      })
    } else {
      setAllFieldsDisabled(false)
      setFieldValue('ibCode', '')
      setFieldValue('introducingBroker', undefined)
      setIb(referral)
    }

    if (isOnlyOnePlatformsAllowToOpenAccount && platformAllowToOpenAccount) {
      setFieldValue('platformType', platformAllowToOpenAccount.platform)
    }
  }

  return (
    <div>
      <input
        id={referral.id}
        name={referral.id}
        value={referral.id}
        type='radio'
        checked={ib?.id === referral.id}
        onChange={handleChange}
        className='radio'
        key={referral.id}
      />
      <label className='text field-label'>{referral.name}</label>
    </div>
  )
}

function dataToSubmit(
  values: CreateTradingAccountCampaignFormValues
): FormSubmitValues<CreateTradingAccount | CreateCampaignTradingAccount> {
  const campaignId = values.campaignId && values.campaignId === '0' ? null : values.campaignId
  const data = {
    campaignId,
    introducingBrokerId: values.introducingBroker?.id || null,
    receivesReports: true,
    leverageTypeId: values.leverageType?.id ?? null,
    platformTypeId: values.platformType?.id ?? null,
    tradingAccountTypeId: values.accountType?.id ?? null,
    wallet: values?.wallet,
    tradingAccount: values?.tradingAccount,
  }
  return campaignId
    ? { ...data, currencyId: values.currencyId! }
    : { ...data, walletId: values.wallet?.id ?? null }
}

interface OuterProps {
  hasCampaignQuery: boolean
  currencies: WalletCurrency[]
  isCurrenciesLoading: boolean
  account: AccountDetailedDto | undefined
  data: CampaignExtendedNameDto[]
  setSelectedPlatform: (platform?: NameDto) => void
  selectedPlatform?: NameDto

  onChangePlatform(platformTypeId: number, introducingBrokerId?: string): void

  onSubmit(
    values: FormSubmitValues<CreateTradingAccount | CreateCampaignTradingAccount>
  ): Promise<void>
}

export const CreateTradingAccountCampaignForm = withFormik<
  OuterProps,
  CreateTradingAccountCampaignFormValues
>({
  mapPropsToValues: (props) => {
    return createInitialState(props)
  },
  handleSubmit: async (values, { props, setSubmitting }) => {
    try {
      await props.onSubmit(dataToSubmit(values))
    } finally {
      setSubmitting(false)
    }
  },
  validate: (values, props) => {
    const { hasCampaignQuery } = props
    const { campaignId } = values
    const validCampaignId = campaignId && campaignId !== '0'

    const errors: FormikErrors<CreateTradingAccountCampaignFormValues> = {}

    if (!validCampaignId && !values.wallet) {
      errors.wallet = t('Validation.Required')
    }

    if (validCampaignId && !values.currencyId) {
      errors.currencyId = t('Validation.Required')
    }

    if (!values.accountType) {
      errors.accountType = t('Validation.Required')
    }

    if (!values.platformType) {
      errors.platformType = t('Validation.Required')
    }

    if (!values.leverageType) {
      errors.leverageType = t('Validation.Required')
    }

    if (!values.terms && (hasCampaignQuery || validCampaignId)) {
      errors.terms = t('Validation.Required')
    }

    if (!values.markup) {
      errors.markup = t('Validation.Required')
    }

    if (values.ibCode && !IBPattern.test(values.ibCode)) {
      errors.ibCode = t('Validation.Referral code should begin with')
    }

    return errors
  },
  enableReinitialize: false,
  isInitialValid: false,
})(CreateTradingAccountCampaignFormUI)

interface ModalProps {
  onSubmit?(): void
  closeModal(): void
}

const MarkupInfoModal: React.FC<ModalProps> = (props) => {
  const { onSubmit = () => {}, closeModal } = props

  const { t } = useTranslation()

  return (
    <InfoModal
      onCancel={closeModal}
      title={t('Trading Account.Introducing Broker (IB)')}
      renderFooter={() => (
        <React.Fragment>
          <button className='button' onClick={onSubmit} type='button'>
            {t('Got It')}
          </button>
        </React.Fragment>
      )}
      renderBody={() => (
        <section className={'modal-card-body'}>
          <p
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: t('Trading Account.Markup Modal'),
            }}
          />
        </section>
      )}
      onConfirm={onSubmit}
    />
  )
}

const IntroducingBrokerInfoModal: React.FC<ModalProps> = (props) => {
  const { closeModal } = props

  const { t } = useTranslation()

  return (
    <InfoModal
      onCancel={closeModal}
      title={t('Trading Account.Referral Code')}
      renderFooter={() => (
        <button className='button' onClick={closeModal} type='button'>
          {t('Got It')}
        </button>
      )}
      renderBody={() => (
        <section className='modal-card-body'>
          <p className={styles.text}>
            {t('Trading Account.Referrals are agents who introduce new clients to Tickmill')}
          </p>
          <p className={styles.text}>
            {t(
              'Trading Account.Enter the code that was provided to you by your Referral. The code consists of a combination of letters and digits.'
            )}
          </p>
        </section>
      )}
      onConfirm={closeModal}
    />
  )
}

const createInitialState = (props: OuterProps): CreateTradingAccountCampaignFormValues => {
  const { account, hasCampaignQuery, data, selectedPlatform } = props

  const initialTradingAccount: CampaignExtendedNameDto = {
    id: '0',
    name: t('Trading Account.Regular Trading Account'),
    platformTypes: [],
  }

  const campaignType = data.find((x) => x.isCampaign) || initialTradingAccount
  const tradingAccount = !hasCampaignQuery ? initialTradingAccount : campaignType
  const campaignId = hasCampaignQuery ? campaignType.id : null

  return {
    tradingAccount,
    campaignId,
    calculationType: campaignType?.calculationType?.id
      ? {
          id: campaignType.calculationType.id,
          name: campaignType.calculationType.name,
        }
      : undefined,
    introducingBroker: account?.introducingBroker ? account.introducingBroker : undefined,
    leverageType: undefined,
    platformType: selectedPlatform,
    receivesReports: true,
    accountType: undefined,
    wallet: undefined,
    currencyId: undefined,
    ibCode: account?.introducingBroker ? account.introducingBroker.name : '',
    terms: false,
    markup: true,
  }
}

const filterReferralOptions = (props: OuterProps) => (referral: NameDto<string>) => {
  // Means there is no Actual IB
  if (!props.account?.introducingBroker) {
    return referral.name !== t('Existing Referral')
  }

  return referral
}

enum GenericReferralType {
  New = '1',
  None = '0',
}

const createIbInitialState = (values: CreateTradingAccountCampaignFormValues) => {
  if (values?.introducingBroker) {
    return {
      id: values.introducingBroker.id,
      name: t('Existing Referral'),
    }
  }

  return {
    id: GenericReferralType.None,
    name: t('No Referral'),
  }
}

const createReferralOptions = (props: OuterProps) => {
  const options = [
    {
      id: GenericReferralType.New,
      name: t('New Referral'),
    },
    {
      id: GenericReferralType.None,
      name: props.account?.introducingBroker ? t('No Referral with message') : t('No Referral'),
    },
    {
      id: props?.account?.introducingBroker?.id || '',
      name: props.account?.introducingBroker
        ? t('Existing Referral with message')
        : t('Existing Referral'),
    },
  ]

  if (isTickmillUK(props.account)) {
    return options.filter((option) => option.id !== GenericReferralType.New)
  }

  return options
}
