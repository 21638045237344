import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { useFormatNumber } from '../../../../hooks/useFormatNumber'
import { CheckOutlinedIcon } from '../../../../icons/CheckOutlinedIcon'
import { MasterTransactionDto } from '../../../../model/MasterTransactionDto'
import { TextH3, TextStrong, TextTiny } from '../../../../ui/Typography/Typography'

import styles from '../../WalletSuccessModal.module.scss'

interface Props {
  transaction: MasterTransactionDto | undefined
  onCancel(): void
}

interface BodyProps {
  transaction?: MasterTransactionDto
}

const WithdrawalSuccessModalBody: React.FC<BodyProps> = (props) => {
  const { transaction } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  if (!transaction) {
    return null
  }

  const [walletTransaction] = transaction.walletTransactions
  const amount = transaction.groupReference?.totalPaymentsAmount ?? transaction.groupReference?.totalWalletsAmount ?? walletTransaction?.otherAmount
  const paymentAgentTransaction = transaction.walletTransactions.find(
    (walletTransaction) => walletTransaction?.paymentAgentTransaction?.paymentAgent
  )

  return (
    <section className={classNames(styles.body, 'pt-3')}>
      <div className={styles.wrapper}>
        <div className={styles.iconWrapper}>
          <div className={styles.icon}>
            <CheckOutlinedIcon />
          </div>
          <TextH3>{t('Wallet.Withdraw done')}</TextH3>
        </div>
        <div className={styles.column}>
          <TextTiny isParagraph>{t('Wallet.From')}</TextTiny>
          <TextStrong isParagraph>
            {walletTransaction?.wallet?.currency?.id} {t('Wallet.Wallet')}
          </TextStrong>
        </div>
        <div className={styles.column}>
          <TextTiny isParagraph>{t('Wallet.Amount')}</TextTiny>
          <TextStrong isParagraph>
            {formatMoney(amount, walletTransaction?.otherCurrency?.id)}
          </TextStrong>
        </div>
        <div className={classNames('mb-5', styles.column)}>
          <TextTiny isParagraph>{t('Wallet.Payment Method')}</TextTiny>
          <TextStrong isParagraph className='px-5 has-text-centered'>
            {walletTransaction?.paymentProviderTransaction?.paymentProvider?.name ||
              paymentAgentTransaction?.paymentAgentTransaction?.paymentAgent?.name}
          </TextStrong>
        </div>
      </div>
    </section>
  )
}

export const WalletWithdrawSuccessModal: React.FC<Props> = ({ onCancel, transaction }) => {
  const { t } = useTranslation()

  return (
    <>
      <WithdrawalSuccessModalBody transaction={transaction} />
      <footer className={classNames('modal-card-foot', styles.footer)}>
        <button className='button' onClick={onCancel}>
          {t('OK')}
        </button>
      </footer>
    </>
  )
}
