import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavigateOptions } from 'react-router-dom'

import { Button } from '../../global/button/Button'
import { useSnackbar } from '../../global/context/SnackbarContext'
import { Table } from '../../global/table/Table'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { WarningIcon } from '../../icons/WarningIcon'
import { AlertIconWrapper } from '../../ui/AlertIconWrapper/AlertIconWrapper'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../utils/wallet.utils'
import { PaymentAgentWalletMappedDto } from './PaymentAgentWalletsPage'

interface Props {
  wallets: PaymentAgentWalletMappedDto[]
  walletWithdrawLoadingId: string
  handleWithdrawalNavigation: (withdrawalOptions?: {
    walletId?: string
    navigateOptions?: NavigateOptions
  }) => Promise<void>
}

export const PaymentAgentWalletsTable: React.FC<Props> = (props) => {
  const { wallets, walletWithdrawLoadingId, handleWithdrawalNavigation } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const { addSnackbar } = useSnackbar()

  const handleRestrictionModalOpen = (wallet: PaymentAgentWalletMappedDto) => {
    const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
    if (!hasAllRestrictions) {
      addSnackbar.error({ message: t('This action is not available') })
    }
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>{t('Wallet.Wallet')}</th>
          <th>{t('Wallet.Wallet Number')}</th>
          <th>{t('Wallet.Available Balance')}</th>
          <th>{t('Wallet.Reserved')}</th>
          <th>{t('Wallet.Transaction History')}</th>
          <th />
        </tr>
      </thead>
      <tbody>
        {!wallets.length ? (
          <tr>
            <td align='center' colSpan={7}>
              {t('No results')}
            </td>
          </tr>
        ) : (
          wallets.map((wallet: PaymentAgentWalletMappedDto) => {
            const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
            const isTransferFromRestricted = isRestricted(
              WalletRestrictions.TRANSFER_FROM,
              wallet.restrictions
            )
            const isWithdrawRestricted = isRestricted(
              WalletRestrictions.WITHDRAW,
              wallet.restrictions
            )
            const isDepositRestricted = isRestricted(
              WalletRestrictions.DEPOSIT,
              wallet.restrictions
            )

            return (
              <tr key={wallet.id}>
                <td>
                  {hasAllRestrictions && <WarningIcon size={20} color={'error'} />}
                  {wallet.currency.id} {t('Payment Agent.PA Wallet')}
                </td>
                <td>{wallet.name}</td>
                <td>
                  <strong>{formatMoney(wallet.balance, wallet.currency.id)}</strong>
                </td>
                <td>{formatMoney(wallet.reserved, wallet.currency.id)}</td>
                <td>
                  <Link
                    className='is-link'
                    to='/dashboard/payment-agent/transaction-history'
                    state={{ walletId: wallet.id }}
                  >
                    {t('View')}
                  </Link>
                </td>
                <td>
                  {isWithdrawRestricted && (
                    <AlertIconWrapper spaced isActive={!hasAllRestrictions}>
                      <Button
                        size='XS'
                        appearance='secondary'
                        state='disabled'
                        onClick={() => handleRestrictionModalOpen(wallet)}
                      >
                        {t('Withdraw')}
                      </Button>
                    </AlertIconWrapper>
                  )}
                  {!isWithdrawRestricted && (
                    <Button
                      size='XS'
                      appearance='secondary'
                      loading={walletWithdrawLoadingId === wallet.id}
                      onClick={() => handleWithdrawalNavigation({ walletId: wallet.id })}
                    >
                      {t('Withdraw')}
                    </Button>
                  )}
                </td>
                <td>
                  {isDepositRestricted && (
                    <AlertIconWrapper spaced isActive={!hasAllRestrictions}>
                      <Button
                        size='XS'
                        appearance='primary'
                        state='disabled'
                        onClick={() => handleRestrictionModalOpen(wallet)}
                      >
                        {t('Deposit')}
                      </Button>
                    </AlertIconWrapper>
                  )}
                  {!isDepositRestricted && (
                    <Link to={`/dashboard/payment-agent/wallets/${wallet.id}/deposit`}>
                      <Button size='XS' appearance='primary'>
                        {t('Deposit')}
                      </Button>
                    </Link>
                  )}
                </td>
                <td>
                  {isTransferFromRestricted && (
                    <AlertIconWrapper spaced isActive={!hasAllRestrictions}>
                      <Button
                        size='XS'
                        appearance='secondary'
                        state='disabled'
                        onClick={() => handleRestrictionModalOpen(wallet)}
                      >
                        {t('Transfer')}
                      </Button>
                    </AlertIconWrapper>
                  )}
                  {!isTransferFromRestricted && (
                    <Link to={`/dashboard/payment-agent/wallets/${wallet.id}/transfer`}>
                      <Button size='XS' appearance='secondary'>
                        {t('Transfer')}
                      </Button>
                    </Link>
                  )}
                </td>
              </tr>
            )
          })
        )}
      </tbody>
    </Table>
  )
}
