import React, { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import classNames from 'classnames'

import {
  canTakeTest,
  getRemainingWaitingTime,
} from '../../Profile/AppropriatenessTest/AppropriatenessTestPage'
import { AppropriatenessTestTakeTestForm } from '../../Profile/AppropriatenessTest/AppropriatenessTestTakeTestForm'
import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { useLossPercentagesReadContext } from '../../global/context/LossPercentagesContext'
import { useProductReadContext, useProductWriteContext } from '../../global/context/ProductContext'
import {
  useArabicSessionLanguage,
  useSessionLanguage,
} from '../../global/context/SessionSettingsContext'
import { CancelActionModal } from '../../global/modal/CancleActionModal'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { FormTemplate } from '../../global/templates/FormTemplate'
import { useAccountAppTestFetch } from '../../hooks/useAccountAppTestFetch'
import { useLogout } from '../../hooks/useLogout'
import { ScrollToIds, useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { ArrowLeftIcon } from '../../icons/ArrowLeftIcon'
import { ArrowRightIcon } from '../../icons/ArrowRightIcon'
import { isVerificationExpedited } from '../../model/AccountDetailedDto'
import { TestAnswerOutcomeStatus, TestAnswersDto } from '../../model/ClientTestAnswerDto'
import { DocumentCategoryType } from '../../model/DocumentCategories'
import { DocumentCategoryOptionType } from '../../model/DocumentCategoryOptionType'
import { DocumentPropertyType } from '../../model/DocumentPropertyType'
import { AppropriatenessTestTakeTestFormValues } from '../../model/Test'
import { TickmillProductType, isTickmillProductTypeCFD } from '../../model/TickmillProductType'
import { RiskWarningModal } from '../../pages/Signup/Modals/RiskWarningModal'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { Text, TextStrong } from '../../ui/Typography/Typography'
import { isAccountOlderThanOneMonth } from '../../utils/AccountAccess/accountAgeStatuses'
import {
  isKYCUpdateDue,
  requireDepositBeforeKYC,
} from '../../utils/AccountAccess/accountKYCStatuses'
import { isAppropriatenessTestMaximumRetakeLimitReached } from '../../utils/AccountAccess/accountProductStatuses'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { isTickmillPartner } from '../../utils/companyName.utils'
import { CommonPaths, navigateBasedOnProductType } from '../../utils/path'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { ProductRiskWarning } from '../ProductRiskWarning'
import { CFDSupportOptions, SupportOptions } from './SupportOptions'

import styles from './ProductAppropriatenessTestPage.module.scss'

const useProductAppTestMutate = () => {
  const apiClient = useApiClient(ClientApiClient)
  const locale = useSessionLanguage()
  const { product } = useProductReadContext()
  const navigate = useNavigate()
  const { logout } = useLogout()

  const [isLoading, setLoading] = useState(false)
  const [isSuccess, setSuccess] = useState(false)
  const [isFailed, setIsFailed] = useState(false)
  const [accountApTestAnswers, setAccountApTestAnswers] = useState<TestAnswersDto | undefined>()

  const mutate = async (
    values: AppropriatenessTestTakeTestFormValues,
    setAlreadyUploadedModal: (visible: boolean) => void
  ) => {
    try {
      setLoading(true)

      const selectedAnswers = Object.entries(values.selectedAnswers).map(([, value]) => value)
      const freeAnswers = Object.entries(values.freeAnswers).map(([key, value]) => {
        return { testQuestionId: key, answer: value }
      })

      await apiClient.createClientTests({
        clientId: values.clientId,
        category: values?.category || '',
        testId: values?.testId || '',
        dateTaken: values?.dateTaken || '',
        selectedAnswers,
        freeAnswers,
      })

      const accountApTestAnswers = await apiClient.getAccountAppTestAnswers(locale, product)
      if (accountApTestAnswers.isClientVulnerable) {
        await logout()
        return navigate('/blocked')
      }
      const files = Object.entries(values.documents || {})
        .map(([questionId, filesData]) => filesData.map((x) => ({ ...x, questionId })).flat())
        .flat()
        .filter((x) => !!x.fileName)

      if (files.length > 0) {
        try {
          await apiClient.uploadDocuments({
            documents: files.map((file) => ({
              categoryId: DocumentCategoryType.AppropriatenessTest,
              typeId: DocumentCategoryOptionType.TradingStatement,
              file: file.base64Content,
              filename: file.fileName,
              properties: {
                [DocumentPropertyType.ClientTestId]: accountApTestAnswers.id,
                [DocumentPropertyType.TestQuestionId]: file.questionId,
              },
            })),
          })
        } catch {
          setAlreadyUploadedModal(true)
        }
      }

      if (accountApTestAnswers.outcome.outcome === TestAnswerOutcomeStatus.FAILED) {
        setIsFailed(true)
      }

      if (accountApTestAnswers.outcome.outcome !== TestAnswerOutcomeStatus.FAILED) {
        setSuccess(true)
      }

      setAccountApTestAnswers(accountApTestAnswers)
    } catch (error: unknown) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  return { mutate, isLoading, isSuccess, isFailed, accountApTestAnswers }
}

const useProductAppropriatenessTest = () => {
  const navigate = useNavigate()

  const { isDefaultCFDProductType, product } = useProductReadContext()
  const { changeProductContext } = useProductWriteContext()
  const { account } = useAccountReadContext()

  const [formValues, setFormValues] = useState<AppropriatenessTestTakeTestFormValues>()
  const [step, setStep] = useState('')
  const [registerProductStep, setRegisterProductStep] = useState(0)
  const [exitConfirmationModal, setExitConfirmationModal] = useState(false)
  const [alreadyUploadedModal, setAlreadyUploadedModal] = useState(false)
  const [riskWarningModalVisible, setRiskWarningModalVisible] = useState(false)

  const { accountAppTest, accountAppTestAnswers, isLoading } = useAccountAppTestFetch()
  const mutation = useProductAppTestMutate()

  const isCFDProduct = isDefaultCFDProductType()
  const isPartner = isTickmillPartner(account)

  const isAllTestsPresent = useMemo(() => {
    const isCFDTestsExists = !!accountAppTest?.sections?.find(
      (appTest) => appTest?.code === 'cfd_investment_knowledge'
    )

    const isETDTestsExists = !!accountAppTest?.sections?.find(
      (appTest) => appTest?.code === 'futures_investment_knowledge'
    )

    return isCFDTestsExists && isETDTestsExists
  }, [accountAppTest?.sections])

  const defaultTestSections = isAllTestsPresent
    ? accountAppTest?.sections?.filter(
        (appTest) => appTest?.code !== 'futures_investment_knowledge'
      )
    : accountAppTest?.sections

  useEffect(() => {
    const isTakeTestAllowed = canTakeTest(account, accountAppTestAnswers, product)
    if (!isTakeTestAllowed && account && accountAppTestAnswers) {
      navigate('/profile/product-appropriateness-test')
    }

    const [section] = defaultTestSections || []
    setStep(section?.code || '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, accountAppTest, accountAppTestAnswers, navigate])

  const handleSubmit = async (values: AppropriatenessTestTakeTestFormValues) => {
    await mutation.mutate(values, setAlreadyUploadedModal)
  }

  const handleNextStep = (values: AppropriatenessTestTakeTestFormValues, callback?: () => void) => {
    const sections = defaultTestSections || []
    const stepIndex = sections.findIndex((x) => x.code === step)
    const section = sections?.[stepIndex + 1]

    const answers = {
      ...values,
      freeAnswers: {
        ...formValues?.freeAnswers,
        ...values.freeAnswers,
      },
      selectedAnswers: {
        ...formValues?.selectedAnswers,
        ...values.selectedAnswers,
      },
    }

    if (section?.code) {
      setRegisterProductStep(stepIndex + 1)
      setStep(section.code)
      setFormValues(answers)
    }

    callback?.()
  }

  const handlePrevStep = (callback?: () => void) => {
    const sections = defaultTestSections || []
    const stepIndex = sections.findIndex((x) => x.code === step)
    const section = sections?.[stepIndex - 1]

    if (section?.code) {
      setRegisterProductStep(stepIndex - 1)
      setStep(section.code)
    }

    callback?.()
  }

  const handleConfirmExit = () => {
    setExitConfirmationModal(false)
    changeProductContext(isCFDProduct ? TickmillProductType.ETD : TickmillProductType.CFD)
    navigateBasedOnProductType(isCFDProduct, navigate, isPartner)
  }

  const closeExitConfirmationModal = () => setExitConfirmationModal(false)

  return {
    isCFDProduct,
    formValues,
    handleConfirmExit,
    closeExitConfirmationModal,
    handlePrevStep,
    handleNextStep,
    handleSubmit,
    mutation,
    accountAppTest,
    accountAppTestAnswers,
    defaultTestSections,
    isLoading:
      isLoading ||
      !!(!canTakeTest(account, accountAppTestAnswers, product) && account && accountAppTestAnswers),
    hasAllTests: isAllTestsPresent,
    step,
    registerProductStep,
    account,
    exitConfirmationModal,
    setExitConfirmationModal,
    alreadyUploadedModal,
    setAlreadyUploadedModal,
    riskWarningModalVisible,
    setRiskWarningModalVisible,
  }
}

interface OuterProps {
  subtitle?: string | React.ReactNode
  onStepChange?(): void
}

export const ProductAppropriatenessTestPage: React.FC<OuterProps> = (props) => {
  const { subtitle, onStepChange } = props

  useScrollToTop()

  const { t } = useTranslation()
  const { changeProductContext } = useProductWriteContext()
  const appropriatenessTest = useProductAppropriatenessTest()
  const {
    isLoading,
    mutation,
    accountAppTest,
    formValues,
    step,
    account,
    isCFDProduct,
    accountAppTestAnswers,
    defaultTestSections,
    hasAllTests,
  } = appropriatenessTest
  const {
    handleSubmit,
    handleNextStep,
    handlePrevStep,
    setExitConfirmationModal,
    setRiskWarningModalVisible,
  } = appropriatenessTest

  const title = isCFDProduct
    ? t('Profile.Trading Experience Test')
    : t('Sign up.Registration Futures Trading')

  const handleChangeStepNext = async (values: AppropriatenessTestTakeTestFormValues) => {
    await handleNextStep(values, () => {
      onStepChange?.()
    })
  }

  const handleChangeStepPrev = async () => {
    await handlePrevStep(() => {
      onStepChange?.()
    })
  }

  const handleChangeProductContext = () => {
    changeProductContext(isCFDProduct ? TickmillProductType.ETD : TickmillProductType.CFD)
  }

  const isCFDTestsOnly = useMemo(() => {
    const isCFDTestsExists = accountAppTest?.sections?.find(
      (appTest) => appTest?.code === 'cfd_investment_knowledge'
    )

    const isETDTestsExists = accountAppTest?.sections?.find(
      (appTest) => appTest?.code === 'futures_investment_knowledge'
    )

    return isCFDTestsExists && !isETDTestsExists
  }, [accountAppTest?.sections])

  return (
    <Loading isLoading={isLoading} showLoadingIcon>
      <Modals {...appropriatenessTest} />
      <FormTemplate
        title={title}
        goBack={() => setExitConfirmationModal(true)}
        subtitle={subtitle}
        renderIcon={<ProductRiskWarning onClick={() => setRiskWarningModalVisible(true)} />}
        contentClassName={styles.container}
      >
        <div className='mt-4'>
          {!mutation.isSuccess && !mutation.isFailed && (
            <div className={classNames(styles.wrapper, 'is-flex')}>
              {isCFDTestsOnly ? <CFDSupportOptions /> : <SupportOptions />}
              <div className={styles.form}>
                <Loading isLoading={mutation.isLoading} showLoadingIcon>
                  {accountAppTest && (
                    <AppropriatenessTestTakeTestForm
                      data={accountAppTest}
                      step={step}
                      noPreviousStep
                      account={account}
                      values={formValues}
                      combineAllTests={hasAllTests}
                      defaultTestSections={defaultTestSections}
                      goPrevStep={handleChangeStepPrev}
                      goNextStep={handleChangeStepNext}
                      onSubmit={handleSubmit}
                    />
                  )}
                </Loading>
              </div>
            </div>
          )}
        </div>
        {mutation.isSuccess && <SuccessPage />}
        {mutation.isFailed && (
          <AppTestFailedPage
            accountApTestAnswers={accountAppTestAnswers}
            onChangeProductContext={handleChangeProductContext}
          />
        )}
      </FormTemplate>
    </Loading>
  )
}

export const ProductAppropriatenessTestProfilePage: React.FC = () => {
  const { t } = useTranslation()

  const appropriatenessTest = useProductAppropriatenessTest()
  const navigate = useNavigate()

  const {
    isLoading,
    mutation,
    accountAppTest,
    formValues,
    step,
    account,
    defaultTestSections,
    hasAllTests,
  } = appropriatenessTest
  const { handleNextStep, handleSubmit, handlePrevStep } = appropriatenessTest
  const { refreshAccount } = useAccountWriteContext()
  const { isDefaultCFDProductType } = useProductReadContext()
  const { scrollIntoView } = useScrollToElementIds()
  scrollIntoView([ScrollToIds.TradingInfoExperience])

  const locale = useSessionLanguage()

  const isProductTypeCFD = isDefaultCFDProductType()
  const isPartner = isTickmillPartner(account)

  useEffect(() => {
    if (mutation.isSuccess) {
      refreshAccountAndRedirect()
    } else if (mutation.isFailed) {
      navigate('/profile/product-appropriateness-test')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.isSuccess, mutation.isFailed])

  const refreshAccountAndRedirect = async () => {
    await refreshAccount(locale)

    if (requireDepositBeforeKYC(account) || isVerificationExpedited(account)) {
      return navigate(CommonPaths.FirstTimeDepositLoading)
    }

    navigateBasedOnProductType(isProductTypeCFD, navigate, isPartner)
  }

  return (
    <Loading isLoading={isLoading} showLoadingIcon>
      <Modals {...appropriatenessTest} />
      <PageHeader
        title={t('Profile.Trading Info & Experience')}
        id={ScrollToIds.TradingInfoExperience}
      />
      {!mutation.isSuccess && !mutation.isFailed && (
        <div className={classNames(styles.wrapper, 'mt-4')}>
          <div className={classNames(styles.box, 'is-flex')}>
            <SupportOptions />
            <div className={styles.form}>
              <Loading isLoading={mutation.isLoading} showLoadingIcon>
                {accountAppTest && (
                  <AppropriatenessTestTakeTestForm
                    data={accountAppTest}
                    step={step}
                    account={account}
                    values={formValues}
                    combineAllTests={hasAllTests}
                    defaultTestSections={defaultTestSections}
                    goPrevStep={handlePrevStep}
                    goNextStep={handleNextStep}
                    onSubmit={handleSubmit}
                  />
                )}
              </Loading>
            </div>
          </div>
        </div>
      )}
    </Loading>
  )
}

interface ModalsProps {
  alreadyUploadedModal: boolean
  riskWarningModalVisible: boolean
  exitConfirmationModal: boolean
  setExitConfirmationModal: React.Dispatch<React.SetStateAction<boolean>>
  setAlreadyUploadedModal: React.Dispatch<React.SetStateAction<boolean>>
  setRiskWarningModalVisible: React.Dispatch<React.SetStateAction<boolean>>
  handleConfirmExit(): void
}

const Modals: React.FC<ModalsProps> = (props) => {
  const {
    alreadyUploadedModal,
    riskWarningModalVisible,
    exitConfirmationModal,
    setExitConfirmationModal,
    setAlreadyUploadedModal,
    setRiskWarningModalVisible,
    handleConfirmExit,
  } = props

  const { t } = useTranslation()
  const { percentages } = useLossPercentagesReadContext()

  return (
    <>
      {riskWarningModalVisible && (
        <Modal
          closeModal={() => setRiskWarningModalVisible(false)}
          render={({ closeModal }) => (
            <RiskWarningModal onCancel={closeModal} risk={percentages?.percentage} />
          )}
        />
      )}
      {exitConfirmationModal && (
        <Modal
          closeModal={() => setExitConfirmationModal(false)}
          render={({ closeModal }) => (
            <CancelActionModal onCancel={closeModal} onConfirm={handleConfirmExit} />
          )}
        />
      )}
      {alreadyUploadedModal && (
        <Modal
          closeModal={() => setAlreadyUploadedModal(false)}
          render={({ closeModal }) => (
            <InformationModal
              onCancel={closeModal}
              onCancelText={t('Got It')}
              title={t('Upload failed')}
            >
              <Text>{t('The document has been already uploaded')}</Text>
            </InformationModal>
          )}
        />
      )}
    </>
  )
}

const SuccessPage: FC = () => {
  useScrollToTop()
  const { t } = useTranslation()
  const locale = useSessionLanguage()
  const navigate = useNavigate()

  const { isDefaultCFDProductType } = useProductReadContext()
  const { refreshAccount } = useAccountWriteContext()
  const apiClient = useApiClient(ClientApiClient)

  const isCFDProduct = isDefaultCFDProductType()

  const accountCallback = useCallback(
    () => {
      return apiClient.getAccountWithAppTestResults(locale)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )
  const { data: account, isLoading: isLoadingAccount } = useFetchOne(accountCallback)

  const navigateWithRefresh = (path: string) => {
    refreshAccount(locale)
    navigate(path)
  }

  const isClientRegisteredThreeMonthsAgo = useMemo(
    () => isAccountOlderThanOneMonth(account),
    [account]
  )

  const getKYCMessage = () =>
    isCFDProduct
      ? t('Check your email inbox for the confirmation.')
      : t('In the next step, we ask you to fill-in your details to update your profile.')

  const getSuccessMessage = () => {
    return isKYCUpdateDue(account)
      ? getKYCMessage()
      : isCFDProduct
      ? t('You have now completed the registration for your CFD account!')
      : t('You have now completed the registration for your Futures account')
  }

  const renderAction = () => {
    return isKYCUpdateDue(account) || (isClientRegisteredThreeMonthsAgo && isCFDProduct) ? (
      <Button
        appearance='primary'
        size='M'
        className='px-6'
        onClick={() => navigateWithRefresh('/dashboard/update-kyc')}
      >
        <span className='pl-6 pr-6'>{t('Update personal details')}</span>
      </Button>
    ) : (
      <Button
        appearance='primary'
        size='M'
        className='px-6'
        onClick={() => navigateWithRefresh('/dashboard/traders-room')}
      >
        <span className='pl-6 pr-6 is-flex is-align-items-center'>
          {t('Continue')}
          <span className='pl-2 pt-1'>
            <ArrowRightIcon color='white' />
          </span>
        </span>
      </Button>
    )
  }

  return (
    <Loading isLoading={isLoadingAccount} showLoadingIcon>
      <div className={classNames(styles.wrapper, 'mt-4')}>
        <div className={classNames(styles.box, 'is-flex', 'is-justify-content-center')}>
          <section className='pb-3 mt-3'>
            <div className='pt-6 pb-6 has-text-centered'>
              <div className='mb-5'>
                <h1 className='text-align-center'>
                  {(isKYCUpdateDue(account) || isClientRegisteredThreeMonthsAgo) && isCFDProduct
                    ? t('Test Completed')
                    : t('Congratulations')}
                </h1>
              </div>

              {isCFDProduct &&
              (isKYCUpdateDue(account) || isClientRegisteredThreeMonthsAgo) ? null : (
                <div className='mb-5'>
                  <Text>{t('You have successfully passed the test')}</Text>
                </div>
              )}

              <div className='mb-6'>
                <TextStrong>{getSuccessMessage()}</TextStrong>
              </div>
              <div>{renderAction()}</div>
            </div>
          </section>
        </div>
      </div>
    </Loading>
  )
}

interface AppTestFailedProps {
  accountApTestAnswers: TestAnswersDto | undefined
  onChangeProductContext(): void
}

const useAppTestFailedFetch = () => {
  const locale = useSessionLanguage()
  const { product } = useProductReadContext()
  const apiClient = useApiClient(ClientApiClient)

  const callback = useCallback(async () => {
    return Promise.all([
      apiClient.getAccountAppTestAnswers(locale, product),
      apiClient.getApTestAttempts(TickmillProductType.CFD),
      apiClient.getApTestAttempts(TickmillProductType.ETD),
    ])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data, isLoading } = useFetchOne(callback)
  const [apTestAnswers, attemptCFD, attemptETD] = data || []

  return { apTestAnswers, attemptCFD, attemptETD, isLoading }
}

const AppTestFailedPage: FC<AppTestFailedProps> = (props) => {
  useScrollToTop()
  const { onChangeProductContext } = props

  const { t } = useTranslation()
  const isArabic = useArabicSessionLanguage()
  const { product } = useProductReadContext()
  const { account } = useAccountReadContext()

  const data = useAppTestFailedFetch()
  const { apTestAnswers, attemptCFD, attemptETD, isLoading } = data

  const isProductTypeCFD = isTickmillProductTypeCFD(product)
  const isAppTestMaximumRetakeLimitReached = isAppropriatenessTestMaximumRetakeLimitReached(
    account,
    product
  )
  const remainingWaitingTime = getRemainingWaitingTime(account, apTestAnswers)
  const { days, hours, minutes, seconds } = remainingWaitingTime
  const isTimeLeft = !!(days + hours + minutes + seconds)
  const attempt = isProductTypeCFD ? attemptCFD : attemptETD

  const isTestFailedVisible = !isLoading && (isAppTestMaximumRetakeLimitReached || attempt === 3)

  if (isTestFailedVisible) {
    return <AppTestFailed />
  }

  return (
    <Loading isLoading={isLoading} showLoadingIcon>
      <div className={classNames(styles.wrapper, 'mt-4')}>
        <div className={classNames(styles.box, 'is-flex', 'is-justify-content-center')}>
          <section className='pb-2 mt-2'>
            <div className='pt-5 pb-5 has-text-centered'>
              <div className='mb-5'>
                <h1 className='text-align-center'>
                  {t('Profile.Trading Experience Test Failed!')}
                </h1>
              </div>
              <div className='is-flex is-flex-direction-column is-align-items-center mb-5'>
                {isTimeLeft && (
                  <>
                    <div className={styles.fixedWidth}>
                      <p className='text'>
                        {t(
                          'Profile.Client Area and opening a Trading Account will only be available upon satisfactory completion of appropriateness test.'
                        )}
                      </p>
                    </div>
                    <p className='text mt-4'>
                      {t(
                        'Profile.Your next appropriateness test (attempts {{attempt}} of 3) is due in {{days}} days {{hours}} hours {{minutes}} minutes {{seconds}} seconds.',
                        {
                          attempt,
                          days: days,
                          hours: hours,
                          minutes: minutes,
                          seconds: seconds,
                        }
                      )}
                    </p>
                  </>
                )}
                {!isTimeLeft && (
                  <div className={styles.fixedWidth}>
                    <Text>
                      {t(
                        'Profile.You may fully use Client Area functions and open a live trading account after satisfying appropriateness test requirements. Please note that live trading account opening and Client Area functions will be frozen until you satisfy appropriateness test requirements.'
                      )}
                    </Text>
                  </div>
                )}
              </div>

              <Button
                appearance='primary'
                size='L'
                className='px-6'
                onClick={onChangeProductContext}
              >
                <span className='pl-6 pr-6 text-strong'>
                  {isTimeLeft ? (
                    <span className='mr-3'>{t('Close')}</span>
                  ) : (
                    <>
                      <span className='mr-3'>{t('Close')}</span>
                      {isArabic ? (
                        <ArrowLeftIcon color='white' />
                      ) : (
                        <ArrowRightIcon color='white' />
                      )}
                    </>
                  )}
                </span>
              </Button>
            </div>
          </section>
        </div>
      </div>
    </Loading>
  )
}

const AppTestFailed: FC = () => {
  const { t } = useTranslation()

  return (
    <div className={classNames(styles.wrapper, 'mt-4')}>
      <div className={classNames(styles.box, 'is-flex', 'is-justify-content-center')}>
        <section className='pb-2 mt-2'>
          <div className='pt-5 pb-5 has-text-centered'>
            <div className='mb-5'>
              <h1 className='text-align-center'>{t('Profile.Trading Experience Test Failed!')}</h1>
            </div>
            <div className='mb-5'>
              <p>
                <Text>{t('Profile.Maximum attempts (3/3) reached')}</Text>
              </p>
              <p>
                <Text>
                  {t('Profile.You will be able to re-take the appropriateness test in 1 year')}
                </Text>
              </p>
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
