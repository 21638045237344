import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Paging, PagingEventType } from '../../global/Paging/Paging'
import { useAccountInfo } from '../../global/context/AccountInfoContext/AccountInfoContext.Provider'
import { useLegalLinksContext } from '../../global/context/LegalLinksContext'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useSnackbar } from '../../global/context/SnackbarContext'
import IconButton from '../../global/iconButton/IconButton'
import { ConfirmationModal } from '../../global/modal/ConfirmationModal'
import { InfoModal } from '../../global/modal/InfoModal'
import { InformationContactModal } from '../../global/modal/InformationContactModal'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { RestrictionActionModal } from '../../global/modal/RestrictionActionModal'
import { SortByModal } from '../../global/modal/SortByModal'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
} from '../../hooks/useScrollToElementIds'
import { InfoIcon } from '../../icons/InfoIcon'
import { AccountGroupType } from '../../model/AccountGroupType'
import { ClientAccountsConfigurationDto } from '../../model/ClientAccountsConfigurationDto'
import { NameDto } from '../../model/NameDto'
import { PlatformType } from '../../model/PlatformTypeDto'
import { PlatformTypeEnum, isTMTPlatformType } from '../../model/PlatformTypeEnum'
import {
  DemoTradingAccount,
  TradingAccount,
  TradingAccountStatusType,
  isLiveAccount,
} from '../../model/TradingAccount'
import { FirstTimeGuideCarousel } from '../../ui/FirstTimeGuideCarousel/FirstTimeGuideCarousel'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import {
  Text,
  TextH3,
  TextLarge,
  TextLargeStrong,
  TextSmall,
  TextStrong,
} from '../../ui/Typography/Typography'
import { useAccountActionAccessCheck } from '../../utils/AccountAccess/accountAccessCheck'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { Operator, PageQuery, useApiClient } from '../../utils/ApiClient'
import { AuthSessionContext } from '../../utils/AuthContext'
import { useEntityConfig } from '../../utils/TradingAccountEntityConfigurationContext'
import { ClientApiClient } from '../../utils/clientApi'
import { ElementResizeProperty, useElementResize, useWindowResize } from '../../utils/domUtils'
import { normalizeSortLabel } from '../../utils/format.utils'
import { getScrollToCardId } from '../../utils/getItemId'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { IFetchResult, useFetchAppendablePage, useFetchOne } from '../../utils/useFetch'
import { scrollToTop, useScrollToTop } from '../../utils/useScrollToTop'
import { isOne } from '../../utils/validations'
import { DemoTradingAccountCard } from './DemoTradingAccountCard'
import { LeverageModal } from './LeverageModal'
import { ManagePasswordsModal } from './ManagePasswordsModal'
import { ManageTickmillTraderPasswordsModal } from './ManageTickmillTraderPasswordsModal'
import { PasswordRestrictionMessageModal } from './PasswordRestrictionMessageModal'
import { ResetTradingAccountPasswordModal } from './ResetTradingAccountPasswordModal'
import { TradingAccountActionModal } from './TradingAccountActionModal'
import { TradingAccountCard } from './TradingAccountCard'
import { TradingAccountPasswordResetMessageModal } from './TradingAccountPasswordResetMessageModal'
import {
  DemoTradingAccountsTable,
  InactiveTradingAccountsTable,
  TradingAccountsTable,
  tradingAccountsTableColumnsNumber,
} from './TradingAccountsTable'
import { useTradingAccountsMessage } from './useTradingAccountsMessage'

import styles from './TradingAccountsPage.module.scss'

type SortState = {
  type: 'active' | 'inactive' | 'demo' | 'demo_inactive'
  visible: boolean
}

type LeverageChangeType = { tradingAccount: TradingAccount; restricted?: boolean }

const sortInitialState: SortState = { type: 'active', visible: false }

const useAddButtonLabel = () => {
  const { t } = useTranslation()

  const getButtonLabelWithPlus = () => {
    const addText = t('Trading Account.Add')
    return addText.trim().startsWith('+') ? addText : '+ ' + addText
  }

  return getButtonLabelWithPlus
}

const useSort = () => {
  const { t } = useTranslation()

  const sortOptions = [{ id: 'Name', name: t('IB.Account Number') }]

  return { sortOptions }
}

export const TradingAccountsPage: React.FC = () => {
  useScrollToTop()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const apiClient = useApiClient(ClientApiClient)
  const [auth] = useContext(AuthSessionContext)

  const entityConfig = useEntityConfig()
  const { accountInfoLimits } = useAccountInfo()

  const location = useLocation()

  const [addTradingAccountInfoModalVisible, setAddTradingAccountInfoModalVisible] = useState(false)
  const [webTraderMT4IsUnavailableModalVisible, setWebTraderMT4IsUnavailableModalVisible] =
    useState(false)
  const [webTraderMT5IsUnavailableModalVisible, setWebTraderMT5IsUnavailableModalVisible] =
    useState(false)
  const [
    webTraderTickmillTraderIsUnavailableModalVisible,
    setWebTraderTickmillTraderIsUnavailableModalVisible,
  ] = useState(false)
  const [displayCurrencyInfo, setDisplayCurrencyInfo] = useState(false)
  const [demoInfoModal, setDemoInfoModal] = useState<string>()
  const { product } = useProductReadContext()
  const { docLinks } = useLegalLinksContext()

  const { addSnackbar } = useSnackbar()
  const showSnackbarError = () => addSnackbar.error({ message: t('This action is not available') })

  const [leverageModal, setLeverageModal] = useState<{
    visible: boolean
    data?: TradingAccount
  }>({ visible: false })
  const [confirmationModal, setConfirmationModal] = useState<{
    visible: boolean
    previousValue?: NameDto
    newValue?: NameDto
    step: number
    data?: TradingAccount | DemoTradingAccount
    demo?: boolean
  }>({
    visible: false,
    step: 1,
  })

  const [resetPasswordMessageModal, setResetPasswordMessageModal] = useState(false)
  const [sortModalOpen, setSortModalOpen] = useState<SortState>(sortInitialState)

  const [resetPasswordModal, setResetPasswordModal] = useState(false)
  const [managePasswordsModal, setManagePasswordsModal] = useState(false)
  const [manageTickmillTraderPasswordsModal, setManageTickmillTraderPasswordsModal] =
    useState(false)
  const [selectedTradingAccount, setSelectedTradingAccount] = useState<
    TradingAccount | DemoTradingAccount
  >()
  const [tradingAccountModal, setTradingAccountModal] = useState(false)
  const [campaignModal, setCampaignModal] = useState(false)
  const tradingAccountMessage = useTradingAccountsMessage()
  const { sortOptions } = useSort()
  const [dynamicLeverageModal, setDynamicLeverageModal] = useState(false)

  const hasRestrictions = (
    account?: TradingAccount | DemoTradingAccount
  ): account is TradingAccount => {
    if (!account) {
      return false
    }
    return 'restrictions' in account
  }

  const [
    changeInvestorPasswordRestrictionMessageModal,
    setChangeInvestorPasswordRestrictionMessageModal,
  ] = useState(false)
  const [changePasswordRestrictionMessageModal, setChangePasswordRestrictionMessageModal] =
    useState(false)
  const [resetPasswordRestrictionMessageModal, setResetPasswordRestrictionMessageModal] =
    useState(false)

  const callback = useCallback(async () => apiClient.getTradingAccountsConfiguration(), [apiClient])

  const accountsConfiguration = useFetchOne(callback)

  const { callback: inactiveTAsCallback } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      return apiClient.getTradingAccounts({
        ...query,
        search: {
          AccountGroupType: {
            value: AccountGroupType.Live,
            operator: Operator.EQUAL,
          },
          Platform: {
            value: AccountGroupType.Archived,
            operator: Operator.NOT_EQUAL,
          },
          Status: {
            value: TradingAccountStatusType.Approved,
            operator: Operator.NOT_EQUAL,
          },
        },
      })
    },
    []
  )
  const taQuery = useFetchAppendablePage(inactiveTAsCallback)

  const { callback: activeTAsCallback, forceRefresh } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      return apiClient.getTradingAccounts({
        ...query,
        search: {
          AccountGroupType: {
            value: AccountGroupType.Live,
            operator: Operator.EQUAL,
          },
          Platform: {
            value: AccountGroupType.Archived,
            operator: Operator.NOT_EQUAL,
          },
          Status: {
            value: TradingAccountStatusType.Approved,
            operator: Operator.EQUAL,
          },
        },
      })
    },
    []
  )
  const activeTAQuery = useFetchAppendablePage(activeTAsCallback)

  const { callback: demoTAsCallback, forceRefresh: refreshDemoAccounts } =
    useCallbackWithForceRefresh(
      async (query?: PageQuery) => {
        return apiClient.getDemoTradingAccounts({
          ...query,
          search: {
            Status: {
              value: TradingAccountStatusType.Approved,
              operator: Operator.EQUAL,
            },
          },
        })
      },
      [entityConfig?.demoAccountAllowed]
    )
  const demoTAQuery = useFetchAppendablePage(demoTAsCallback)

  const { callback: inactiveDemoTAsCallback } = useCallbackWithForceRefresh(
    async (query?: PageQuery) => {
      return apiClient.getDemoTradingAccounts({
        ...query,
        search: {
          Status: {
            value: TradingAccountStatusType.Approved,
            operator: Operator.NOT_EQUAL,
          },
        },
      })
    },
    []
  )

  const inactiveDemoTAQuery = useFetchAppendablePage(inactiveDemoTAsCallback)

  const handleChangeLeverage = async (
    ta: TradingAccount | DemoTradingAccount,
    oldLeverage: NameDto,
    leverage: NameDto
  ) => {
    const modalData = {
      visible: true,
      previousValue: oldLeverage,
      newValue: leverage,
      step: 1,
      data: ta,
    }

    setConfirmationModal(modalData)
    setLeverageModal({ visible: false, data: undefined })
  }

  const handleConfirmChangeLeverage = async () => {
    try {
      if (confirmationModal.data && confirmationModal.previousValue && confirmationModal.newValue) {
        if (confirmationModal.demo) {
          await apiClient.updateDemoTradingAccount(
            confirmationModal.data.id,

            {
              leverageTypeId: confirmationModal.newValue.id,

              accountGroupId: confirmationModal.data.accountGroup.id,
            }
          )

          refreshDemoAccounts()
        } else {
          await apiClient.updateTradingAccountLeverage(
            confirmationModal.data.id,

            {
              leverageTypeId: confirmationModal.newValue.id,
            }
          )

          forceRefresh()
        }
      }

      setConfirmationModal({ ...confirmationModal, step: 2 })
    } catch (error: unknown) {
      setConfirmationModal({ visible: false, step: 1 })
      console.error(error, 'error')
    }
  }

  const handleResetPassword = async () => {
    try {
      if (selectedTradingAccount) {
        if (isLiveAccount(selectedTradingAccount?.accountGroupType)) {
          await apiClient.resetTradingAccountPassword({
            tradingAccountId: selectedTradingAccount.id,
            changeInvestorPassword: false,
          })
        } else {
          await apiClient.resetDemoTradingAccountPassword({
            tradingAccountId: selectedTradingAccount.id,
            changeInvestorPassword: false,
          })
        }
      }
    } finally {
      setResetPasswordMessageModal(true)
      setResetPasswordModal(false)
    }
  }

  const platformTypesCallback = useCallback(async () => {
    return apiClient.getPlatformTypes(product)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: platformTypes = [] } = useFetchOne(platformTypesCallback)

  const handleChangeTickmillTraderPasswordClick = () => {
    navigate(
      `/dashboard/traders-room/trading-accounts/${selectedTradingAccount?.id}/change-password?changeTickmillTraderPassword=true`
    )
  }

  const handleChangeTradingAccountPasswordClick = () => {
    navigate(
      `/dashboard/traders-room/trading-accounts/${selectedTradingAccount?.id}/change-password?changeInvestorPassword=false`
    )
  }
  const handleChangeInvestorPasswordClick = () => {
    navigate(
      `/dashboard/traders-room/trading-accounts/${selectedTradingAccount?.id}/change-password?changeInvestorPassword=true`
    )
  }
  const handleSetChangeInvestorPasswordRestrictionMessageModal = () => {
    setManagePasswordsModal(false)
    setChangeInvestorPasswordRestrictionMessageModal(true)
  }
  const handleSetChangePasswordRestrictionMessageModal = () => {
    setManagePasswordsModal(false)
    setChangePasswordRestrictionMessageModal(true)
  }
  const handleSetResetPasswordRestrictionMessageModal = () => {
    setManagePasswordsModal(false)
    setResetPasswordRestrictionMessageModal(true)
  }

  const handleLeverageChange = ({ tradingAccount, restricted }: LeverageChangeType) => {
    if (restricted) {
      return showSnackbarError()
    }

    if (tradingAccount.platformType.id === PlatformTypeEnum.TickmillTrader) {
      return setDynamicLeverageModal(true)
    }

    return setLeverageModal({
      data: tradingAccount,
      visible: true,
    })
  }

  const handleSetSelectedTradingAccount = (tradingAccount: TradingAccount | DemoTradingAccount) => {
    setSelectedTradingAccount?.(tradingAccount)
    setTradingAccountModal?.(true)
  }

  const createResizeTableColumnElements = (amount: number) => {
    return Array.from({ length: amount }, useElementResize<HTMLTableHeaderCellElement>)
  }

  const tableColumnsProperties: ElementResizeProperty<HTMLTableHeaderCellElement>[] =
    createResizeTableColumnElements(tradingAccountsTableColumnsNumber)

  useEffect(() => {
    if (
      location?.state?.fromAddCampaignTradingAccountForm ||
      location.state?.fromAddDemoTradingAccountForm ||
      location.state?.fromDepositTradingAccountForm
    ) {
      scrollToTop()
    }
  }, [
    location.state?.fromAddCampaignTradingAccountForm,
    location.state?.fromAddDemoTradingAccountForm,
    location.state?.fromDepositTradingAccountForm,
  ])

  const [isTAPaginationEntriesSelected, setIsTAPaginationEntriesSelected] = useState(false)
  const [isDemoTAPaginationEntriesSelected, setIsDemoTAPaginationEntriesSelected] = useState(false)
  const [isInactiveTAPaginationEntriesSelected, setIsInactiveTAPaginationEntriesSelected] =
    useState(false)
  const [isInactiveDemoTAPaginationEntriesSelected, setIsInactiveDemoTAPaginationEntriesSelected] =
    useState(false)

  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.LiveTradingAccountsHeader,
    isTAPaginationEntriesSelected,
    activeTAQuery.isLoading,
    setIsTAPaginationEntriesSelected
  )
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.DemoTradingAccountsHeader,
    isDemoTAPaginationEntriesSelected,
    demoTAQuery.isLoading,
    setIsDemoTAPaginationEntriesSelected
  )
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.InactiveLiveTradingAccountsHeader,
    isInactiveTAPaginationEntriesSelected,
    taQuery.isLoading,
    setIsInactiveTAPaginationEntriesSelected
  )
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.InactiveDemoTradingAccountsHeader,
    isInactiveDemoTAPaginationEntriesSelected,
    inactiveDemoTAQuery.isLoading,
    setIsInactiveDemoTAPaginationEntriesSelected
  )

  useScrollAfterLoad(
    ScrollToIds.LiveTradingAccountsHeader,
    activeTAQuery.isLoading,
    activeTAQuery.meta?.pageSize
  )
  useScrollAfterLoad(
    ScrollToIds.DemoTradingAccountsHeader,
    demoTAQuery.isLoading,
    demoTAQuery.meta?.pageSize
  )
  useScrollAfterLoad(
    ScrollToIds.InactiveLiveTradingAccountsHeader,
    taQuery.isLoading,
    taQuery.meta?.pageSize
  )
  useScrollAfterLoad(
    ScrollToIds.InactiveDemoTradingAccountsHeader,
    inactiveDemoTAQuery.isLoading,
    inactiveDemoTAQuery.meta?.pageSize
  )

  return (
    <React.Fragment>
      <FirstTimeGuideCarousel />
      {resetPasswordRestrictionMessageModal && (
        <Modal
          closeModal={() => setResetPasswordRestrictionMessageModal(false)}
          render={({ closeModal }) => (
            <PasswordRestrictionMessageModal
              onCancel={closeModal}
              onConfirm={() => {
                setResetPasswordRestrictionMessageModal(false)
              }}
              title={t('Trading Account.Reset Password not Possible')}
              message={t('Trading Account.Reset Password is not possible at the moment')}
            />
          )}
        />
      )}
      {demoInfoModal && (
        <Modal
          closeModal={() => setDemoInfoModal(undefined)}
          render={({ closeModal }) => (
            <InformationModal
              onCancel={closeModal}
              title={demoInfoModal}
              onCancelText={t('Got it!')}
            >
              <p>{t('Trading Account.The password for demo trading accounts')}</p>
            </InformationModal>
          )}
        />
      )}
      {changeInvestorPasswordRestrictionMessageModal && (
        <Modal
          closeModal={() => setChangeInvestorPasswordRestrictionMessageModal(false)}
          render={({ closeModal }) => (
            <PasswordRestrictionMessageModal
              onCancel={closeModal}
              onConfirm={() => {
                setChangeInvestorPasswordRestrictionMessageModal(false)
              }}
              title={t('Trading Account.Change Investor Trading Account Password')}
              message={t('Trading Account.Trading Account Restricted Investor')}
            />
          )}
        />
      )}
      {changePasswordRestrictionMessageModal && (
        <Modal
          closeModal={() => setChangePasswordRestrictionMessageModal(false)}
          render={({ closeModal }) => (
            <PasswordRestrictionMessageModal
              onCancel={closeModal}
              onConfirm={() => {
                setChangePasswordRestrictionMessageModal(false)
              }}
              title={t('Trading Account.Change Trading Account Password not Possible')}
              message={t('Trading Account.Changing Trading Account Password is not possible')}
            />
          )}
        />
      )}
      {tradingAccountModal && selectedTradingAccount && (
        <Modal
          closeModal={() => setTradingAccountModal(false)}
          render={({ closeModal }) => (
            <TradingAccountActionModal
              tradingAccount={selectedTradingAccount}
              setManagePasswordsModal={
                isTMTPlatformType(selectedTradingAccount.platformType.id)
                  ? setManageTickmillTraderPasswordsModal
                  : setManagePasswordsModal
              }
              setTradingAccountModal={setTradingAccountModal}
              onCancel={closeModal}
            />
          )}
        />
      )}
      {manageTickmillTraderPasswordsModal && selectedTradingAccount && (
        <Modal
          closeModal={() => setManageTickmillTraderPasswordsModal(false)}
          render={({ closeModal }) => (
            <ManageTickmillTraderPasswordsModal
              handleChangeTradingAccountPassword={handleChangeTickmillTraderPasswordClick}
              setResetPassword={setResetPasswordModal}
              onCancel={closeModal}
              restrictions={
                hasRestrictions(selectedTradingAccount) ? selectedTradingAccount?.restrictions : []
              }
              setChangePasswordRestrictionMessageModal={
                handleSetChangePasswordRestrictionMessageModal
              }
              setResetPasswordRestrictionMessageModal={
                handleSetResetPasswordRestrictionMessageModal
              }
            />
          )}
        />
      )}
      {managePasswordsModal && selectedTradingAccount && (
        <Modal
          closeModal={() => setManagePasswordsModal(false)}
          render={({ closeModal }) => (
            <ManagePasswordsModal
              handleChangeTradingAccountPassword={handleChangeTradingAccountPasswordClick}
              handleChangeInvestorPassword={handleChangeInvestorPasswordClick}
              setResetPassword={setResetPasswordModal}
              onCancel={closeModal}
              restrictions={
                hasRestrictions(selectedTradingAccount) ? selectedTradingAccount?.restrictions : []
              }
              setChangeInvestorPasswordRestrictionMessageModal={
                handleSetChangeInvestorPasswordRestrictionMessageModal
              }
              setChangePasswordRestrictionMessageModal={
                handleSetChangePasswordRestrictionMessageModal
              }
              setResetPasswordRestrictionMessageModal={
                handleSetResetPasswordRestrictionMessageModal
              }
            />
          )}
        />
      )}
      {resetPasswordModal && (
        <Modal
          closeModal={() => setResetPasswordModal(false)}
          render={({ closeModal }) => (
            <ResetTradingAccountPasswordModal
              onCancel={closeModal}
              onConfirm={() => {
                handleResetPassword()
              }}
            />
          )}
        />
      )}
      {resetPasswordMessageModal && (
        <Modal
          closeModal={() => setResetPasswordMessageModal(false)}
          render={({ closeModal }) => (
            <TradingAccountPasswordResetMessageModal email={auth?.email} onCancel={closeModal} />
          )}
        />
      )}
      {dynamicLeverageModal && (
        <Modal
          closeModal={() => setDynamicLeverageModal(false)}
          render={({ closeModal }) => (
            <InfoModal
              onCancel={closeModal}
              title={t('Trading Account.Dynamic Leverage')}
              renderBody={() => (
                <section className='modal-card-body'>
                  <Text className={styles.text}>
                    {t('Trading Account.Dynamic Leverage is a tool')}{' '}
                    <a
                      target='_blank'
                      href={docLinks?.dynamicLeverageInfo}
                      rel='noopener noreferrer'
                    >
                      {t('here')}
                    </a>
                    .
                  </Text>
                </section>
              )}
              renderFooter={() => (
                <button className='button' onClick={closeModal} type='button'>
                  {t('Got It')}
                </button>
              )}
              onConfirm={() => {
                setDynamicLeverageModal(false)
              }}
            />
          )}
        />
      )}
      {leverageModal.visible && (
        <LeverageModal
          restrictions={leverageModal.data?.restrictions || []}
          platformTypeId={leverageModal.data?.platformType.id || undefined}
          onClose={() => setLeverageModal({ visible: false })}
          currentLeverage={leverageModal.data?.leverageType}
          onSelect={(leverage) =>
            handleChangeLeverage(leverageModal.data!, leverageModal.data!.leverageType, leverage)
          }
        />
      )}
      {confirmationModal.visible && (
        <Modal
          closeModal={() => setConfirmationModal({ visible: false, step: 1 })}
          render={({ closeModal }) => {
            if (isOne(confirmationModal.step)) {
              return (
                <ConfirmationModal
                  title={t('Trading Account.Change Leverage')}
                  onCancel={closeModal}
                  onConfirm={handleConfirmChangeLeverage}
                >
                  <div>
                    <TextLarge
                      isParagraph
                      className={styles.switch}
                    >{`${confirmationModal.previousValue?.name} > ${confirmationModal.newValue?.name}`}</TextLarge>
                    <br />
                    <p>
                      <span className='has-error'>{t('Trading Account.Warning')} </span>
                      {t('Trading Account.Warning affect')}
                    </p>
                    <br />
                    {t('Trading Account.Sufficient funds')}
                    <br /> <br />
                    <p>{t('Trading Account.Re login')}</p>
                    <br />
                    <p>{t('Trading Account.Contact support')}</p>
                  </div>
                </ConfirmationModal>
              )
            }

            if (confirmationModal.step === 2) {
              return (
                <ConfirmationModal
                  title={t('Leverage changed')}
                  onCancel={closeModal}
                  onConfirm={() =>
                    setConfirmationModal({
                      visible: false,
                      step: 1,
                      previousValue: undefined,
                      newValue: undefined,
                      data: undefined,
                    })
                  }
                  renderFooter={() => (
                    <React.Fragment>
                      <button
                        className='button'
                        onClick={() =>
                          setConfirmationModal({
                            visible: false,
                            step: 1,
                            previousValue: undefined,
                            newValue: undefined,
                            data: undefined,
                          })
                        }
                        type='button'
                      >
                        <TextStrong>{t('Got It')}</TextStrong>
                      </button>
                    </React.Fragment>
                  )}
                >
                  <div>
                    <p>
                      {t('Trading Account.Leverage changed from to', {
                        oldValue: confirmationModal.previousValue?.name,
                        newValue: confirmationModal.newValue?.name,
                      })}
                    </p>
                  </div>
                </ConfirmationModal>
              )
            }
          }}
        />
      )}
      {addTradingAccountInfoModalVisible && (
        <Modal
          closeModal={() => setAddTradingAccountInfoModalVisible(false)}
          render={({ closeModal }) => (
            <InformationContactModal
              title={t('Trading Account.Demo Account unavailable in your Country')}
              onCancel={closeModal}
            >
              <Text
                isParagraph
                dangerouslySetInnerHTML={{
                  __html: t(
                    'Trading Account.It appears that the Demo Trading Account is not available in your country. Please contact our Support team if you have any questions.'
                  ),
                }}
              />
            </InformationContactModal>
          )}
        />
      )}
      {webTraderMT4IsUnavailableModalVisible && (
        <Modal
          closeModal={() => setWebTraderMT4IsUnavailableModalVisible(false)}
          render={({ closeModal }) => (
            <InformationContactModal
              title={t('Trading Account.WebTrader is unavailable')}
              onCancel={closeModal}
            >
              <Text isParagraph className={styles.text}>
                {t(
                  'Trading Account.The WebTrader is currently not available. Check the other available MT4 applications'
                )}{' '}
                <span
                  className='is-link'
                  onClick={() => navigate('/dashboard/tools/platforms/mt4')}
                >
                  {t('here')}
                </span>
                .
              </Text>
            </InformationContactModal>
          )}
        />
      )}
      {webTraderMT5IsUnavailableModalVisible && (
        <Modal
          closeModal={() => setWebTraderMT5IsUnavailableModalVisible(false)}
          render={({ closeModal }) => (
            <InformationContactModal
              title={t('Trading Account.WebTrader is unavailable')}
              onCancel={closeModal}
            >
              <Text isParagraph className={styles.text}>
                {t(
                  'Trading Account.The WebTrader is currently not available. Check the other available MT5 applications'
                )}{' '}
                <span
                  className='is-link'
                  onClick={() => navigate('/dashboard/tools/platforms/mt5')}
                >
                  {t('here')}
                </span>
                .
              </Text>
            </InformationContactModal>
          )}
        />
      )}
      {webTraderTickmillTraderIsUnavailableModalVisible && (
        <Modal
          closeModal={() => setWebTraderTickmillTraderIsUnavailableModalVisible(false)}
          render={({ closeModal }) => (
            <InformationContactModal
              title={t('Trading Account.Tickmill Trader Platform is unavailable')}
              onCancel={closeModal}
            >
              <Text isParagraph className={styles.text}>
                {t('Trading Account.Tickmill Trader Platform is currently not available')}{' '}
                <span className='is-link' onClick={() => navigate('/dashboard/tools/platforms')}>
                  {t('here')}
                </span>
                .
              </Text>
            </InformationContactModal>
          )}
        />
      )}
      {campaignModal && (
        <Modal
          closeModal={() => setCampaignModal(false)}
          render={({ closeModal }) => (
            <RestrictionActionModal
              title={t('Trading Account.Campaign Account')}
              description={t('Trading Account.All relevant information about campaign')}
              onConfirm={closeModal}
            />
          )}
        />
      )}
      {displayCurrencyInfo && (
        <Modal
          closeModal={() => setDisplayCurrencyInfo(false)}
          render={() => (
            <InformationModal
              title={t('Trading Account.Total Live Trading Accounts Balance')}
              onCancel={() => setDisplayCurrencyInfo(false)}
              onCancelText={t('Got It')}
            >
              <Trans
                i18nKey='Wallet.Your current balance is the total of your {{ value }} balance(s)'
                components={{
                  1: <Link title={t('Currency you selected')} to={`/profile/account-settings`} />,
                }}
                values={{ value: 'live trading account' }}
              />
            </InformationModal>
          )}
        />
      )}
      {sortModalOpen.visible && (
        <Modal
          closeModal={() => setSortModalOpen({ visible: false, type: 'active' })}
          render={({ closeModal }) => (
            <SortByModal
              onCancel={closeModal}
              options={sortOptions}
              onConfirm={(option, sortBy, sortName) => {
                const newPageQuery = {
                  ...activeTAQuery.pageQuery,
                  sort: option,
                  sortName: sortName,
                  sortOrder: sortBy,
                }

                if (sortModalOpen.type === 'active') {
                  activeTAQuery.setPageQuery?.(newPageQuery)
                } else if (sortModalOpen.type === 'demo') {
                  demoTAQuery.setPageQuery?.(newPageQuery)
                } else if (sortModalOpen.type === 'demo_inactive') {
                  inactiveDemoTAQuery.setPageQuery?.(newPageQuery)
                } else {
                  taQuery.setPageQuery?.(newPageQuery)
                }
                closeModal()
              }}
            />
          )}
        />
      )}
      {isMobile ? (
        <ActiveTACards
          query={activeTAQuery}
          hasReachedMaxTradingAccounts={accountInfoLimits?.hasReachedMaxTradingAccounts}
          tradingAccountMessage={tradingAccountMessage}
          onDisplayCurrencyInfo={setDisplayCurrencyInfo}
          showSnackbarError={showSnackbarError}
          onSortModalOpen={setSortModalOpen}
          onLeverageChange={handleLeverageChange}
          platformTypes={platformTypes}
          onSelectedTradingAccount={handleSetSelectedTradingAccount}
          onDisplayDynamicLeverageInfo={setDynamicLeverageModal}
          setIsTAPaginationEntriesSelected={setIsTAPaginationEntriesSelected}
        />
      ) : (
        <ActiveTATable
          query={activeTAQuery}
          hasReachedMaxTradingAccounts={accountInfoLimits?.hasReachedMaxTradingAccounts}
          onAddTradingAccountInfoModalVisible={setAddTradingAccountInfoModalVisible}
          onWebTraderMT4IsUnavailableModalVisible={setWebTraderMT4IsUnavailableModalVisible}
          onWebTraderMT5IsUnavailableModalVisible={setWebTraderMT5IsUnavailableModalVisible}
          onWebTraderTickmillTraderIsUnavailableModalVisible={
            setWebTraderTickmillTraderIsUnavailableModalVisible
          }
          onCampaignModal={setCampaignModal}
          onDisplayCurrencyInfo={setDisplayCurrencyInfo}
          showSnackbarError={showSnackbarError}
          onLeverageChange={handleLeverageChange}
          onSelectedTradingAccount={handleSetSelectedTradingAccount}
          onDisplayDynamicLeverageInfo={setDynamicLeverageModal}
          columnsProperties={tableColumnsProperties}
          platformTypes={platformTypes}
          isTAPaginationEntriesSelected={isTAPaginationEntriesSelected}
          setIsTAPaginationEntriesSelected={setIsTAPaginationEntriesSelected}
        />
      )}
      {accountsConfiguration.data?.demoAccountViewAllowed &&
        (isMobile ? (
          <DemoTACards
            query={demoTAQuery}
            accountsConfiguration={accountsConfiguration?.data}
            onSortModalOpen={setSortModalOpen}
            showSnackbarError={showSnackbarError}
            onDemoInfoModal={setDemoInfoModal}
            platformTypes={platformTypes}
            onSelectedTradingAccount={handleSetSelectedTradingAccount}
            onDisplayDynamicLeverageInfo={setDynamicLeverageModal}
            setIsDemoTAPaginationEntriesSelected={setIsDemoTAPaginationEntriesSelected}
          />
        ) : (
          <DemoTATable
            query={demoTAQuery}
            accountsConfiguration={accountsConfiguration?.data}
            showSnackbarError={showSnackbarError}
            onDemoInfoModal={setDemoInfoModal}
            onDisplayCurrencyInfo={setDisplayCurrencyInfo}
            onWebTraderMT4IsUnavailableModalVisible={setWebTraderMT4IsUnavailableModalVisible}
            onWebTraderMT5IsUnavailableModalVisible={setWebTraderMT5IsUnavailableModalVisible}
            onWebTraderTickmillTraderIsUnavailableModalVisible={
              setWebTraderTickmillTraderIsUnavailableModalVisible
            }
            onDisplayDynamicLeverageInfo={setDynamicLeverageModal}
            onCampaignModal={setCampaignModal}
            onSelectedTradingAccount={handleSetSelectedTradingAccount}
            platformTypes={platformTypes}
            isDemoTickmillTraderCreateAllowed={accountInfoLimits?.isDemoTickmillTraderCreateAllowed}
            setIsDemoTAPaginationEntriesSelected={setIsDemoTAPaginationEntriesSelected}
          />
        ))}
      {isMobile ? (
        <InactiveTACards
          query={taQuery}
          onSortModalOpen={setSortModalOpen}
          showSnackbarError={showSnackbarError}
          onSelectedTradingAccount={handleSetSelectedTradingAccount}
          setIsInactiveTAPaginationEntriesSelected={setIsInactiveTAPaginationEntriesSelected}
        />
      ) : (
        <InactiveTATable
          query={taQuery}
          hasReachedMaxTradingAccounts={accountInfoLimits?.hasReachedMaxTradingAccounts}
          columnsProperties={tableColumnsProperties}
          setIsInactiveTAPaginationEntriesSelected={setIsInactiveTAPaginationEntriesSelected}
        />
      )}
      {(entityConfig?.demoAccountAllowed || !!inactiveDemoTAQuery.hasInitialResults) &&
        (isMobile ? (
          <InactiveDemoTACards
            query={inactiveDemoTAQuery}
            onSortModalOpen={setSortModalOpen}
            onInfoModal={setDemoInfoModal}
            showSnackbarError={showSnackbarError}
            setIsInactiveDemoTAPaginationEntriesSelected={
              setIsInactiveDemoTAPaginationEntriesSelected
            }
          />
        ) : (
          <InactiveDemoTATable
            query={inactiveDemoTAQuery}
            columnsProperties={tableColumnsProperties}
            hasReachedMaxTradingAccounts={accountInfoLimits?.hasReachedMaxTradingAccounts}
            onDemoInfoModal={setDemoInfoModal}
            setIsInactiveDemoTAPaginationEntriesSelected={
              setIsInactiveDemoTAPaginationEntriesSelected
            }
          />
        ))}
    </React.Fragment>
  )
}

interface ActiveTACardsProps {
  query: IFetchResult<TradingAccount[]>
  tradingAccountMessage: string | null
  hasReachedMaxTradingAccounts?: boolean
  onSortModalOpen(data: SortState): void
  showSnackbarError: () => void
  onDisplayCurrencyInfo(value: boolean): void
  onLeverageChange(value: LeverageChangeType): void
  onSelectedTradingAccount(value: TradingAccount | DemoTradingAccount): void
  onDisplayDynamicLeverageInfo?(v: boolean): void
  platformTypes?: PlatformType[]
  setIsTAPaginationEntriesSelected: (isTAPaginationEntriesSelected: boolean) => void
}

const ActiveTACards: React.FC<ActiveTACardsProps> = (props) => {
  const { query, platformTypes, hasReachedMaxTradingAccounts, tradingAccountMessage } = props
  const { onLeverageChange, onDisplayDynamicLeverageInfo, setIsTAPaginationEntriesSelected } = props
  const { onSortModalOpen, showSnackbarError, onDisplayCurrencyInfo, onSelectedTradingAccount } =
    props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { checkAccountOpenAccountAccess } = useAccountActionAccessCheck()

  const { sortOptions } = useSort()
  const getButtonLabelWithPlus = useAddButtonLabel()
  const { formatMoney } = useFormatNumber()

  const { account } = useAccountReadContext()

  const handleDisplayCurrencyInfo = () => {
    onDisplayCurrencyInfo(true)
  }

  const handleSortModalOpen = () => {
    onSortModalOpen({
      type: 'active',
      visible: true,
    })
  }

  return (
    <React.Fragment>
      <PageHeader
        id={ScrollToIds.LiveTradingAccountsHeader}
        renderTitle={() => (
          <TextSmall className='text-secondary'>
            {t('Trading Account.Live Trading Accounts')}
          </TextSmall>
        )}
        renderSubtitle={() => (
          <div className='is-flex is-align-items-center'>
            <TextH3>{`≈${formatMoney(
              query.data.reduce(
                (acc, account) => (acc += account.platformOverview?.balanceInDisplayCurrency || 0),
                0
              ),
              account?.displayCurrency?.id
            )}`}</TextH3>

            <IconButton className='ml-2' onClick={handleDisplayCurrencyInfo}>
              <InfoIcon size={14} />
            </IconButton>
          </div>
        )}
        actions={[
          {
            disabled: hasReachedMaxTradingAccounts,
            size: 'S',
            onClick: () =>
              checkAccountOpenAccountAccess(() =>
                navigate('/dashboard/traders-room/trading-accounts/new')
              ),
            label: getButtonLabelWithPlus(),
          },
        ]}
        filterToggles={
          query.data.length > 0
            ? {
                sortLabel: normalizeSortLabel(t, sortOptions, query.pageQuery?.sort),
                openSortModal: handleSortModalOpen,
                className: 'is-flex is-justify-content-end',
              }
            : undefined
        }
        showDetails={true}
        detailsContent={tradingAccountMessage ? <Text>{tradingAccountMessage}</Text> : undefined}
      />
      <div
        className={classNames('is-flex is-flex-direction-column', {
          'mt-2': query.data.length > 0,
          'mt-6': !query.data.length,
        })}
      >
        {query.data.map((ta, index) => (
          <TradingAccountCard
            platformTypes={platformTypes}
            isActive
            tradingAccount={ta}
            showSnackbarError={showSnackbarError}
            onLeverageChange={onLeverageChange}
            handleSetSelectedTradingAccount={onSelectedTradingAccount}
            key={index}
            onDisplayDynamicLeverageInfo={onDisplayDynamicLeverageInfo}
            cardId={getScrollToCardId(index, query.data.length, 'live-trading-accounts', 'end')}
          />
        ))}
        {query.meta && (
          <Paging
            maxPageSize={500}
            pageData={query.meta}
            isLoading={query.isLoading}
            onPageChanged={(pageIndex, pageSize, pagingEventType) => {
              if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
                setIsTAPaginationEntriesSelected(true)
              }
              query.setPageQuery!({
                pageIndex,
                pageSize,
              })
            }}
            scrollToHeaderId={ScrollToIds.LiveTradingAccountsHeader}
          />
        )}
      </div>
    </React.Fragment>
  )
}

interface ActiveTATableProps {
  query: IFetchResult<TradingAccount[]>
  hasReachedMaxTradingAccounts?: boolean
  showSnackbarError: () => void
  onDisplayCurrencyInfo(value: boolean): void
  onCampaignModal(value: boolean): void
  onLeverageChange(value: LeverageChangeType): void
  onAddTradingAccountInfoModalVisible(value: boolean): void
  onWebTraderMT4IsUnavailableModalVisible(value: boolean): void
  onWebTraderMT5IsUnavailableModalVisible(value: boolean): void
  onWebTraderTickmillTraderIsUnavailableModalVisible(value: boolean): void
  onSelectedTradingAccount(value: TradingAccount | DemoTradingAccount): void
  onDisplayDynamicLeverageInfo?(v: boolean): void
  columnsProperties: ElementResizeProperty<HTMLTableHeaderCellElement>[]
  platformTypes: PlatformType[]
  isTAPaginationEntriesSelected: boolean
  setIsTAPaginationEntriesSelected: (isTAPaginationEntriesSelected: boolean) => void
}

const ActiveTATable: React.FC<ActiveTATableProps> = (props) => {
  const {
    query,
    hasReachedMaxTradingAccounts,
    onDisplayCurrencyInfo,
    showSnackbarError,
    onAddTradingAccountInfoModalVisible,
    onWebTraderMT4IsUnavailableModalVisible,
    onWebTraderMT5IsUnavailableModalVisible,
    onWebTraderTickmillTraderIsUnavailableModalVisible,
    onSelectedTradingAccount,
    onCampaignModal,
    onLeverageChange,
    onDisplayDynamicLeverageInfo,
    columnsProperties,
    platformTypes,
    setIsTAPaginationEntriesSelected,
  } = props

  return (
    <React.Fragment>
      <TradingAccountsTable
        data={query.data || []}
        onLeverageChange={onLeverageChange}
        hasReachedMaxTradingAccounts={hasReachedMaxTradingAccounts}
        isLoading={query.isLoading}
        setAddTradingAccountInfoModalVisible={onAddTradingAccountInfoModalVisible}
        setWebTraderMT4IsUnavailableModalVisible={onWebTraderMT4IsUnavailableModalVisible}
        setWebTraderMT5IsUnavailableModalVisible={onWebTraderMT5IsUnavailableModalVisible}
        setWebTraderTickmillTraderIsUnavailableModalVisible={
          onWebTraderTickmillTraderIsUnavailableModalVisible
        }
        setCampaignModal={onCampaignModal}
        showSnackbarError={showSnackbarError}
        handleSetSelectedTradingAccount={onSelectedTradingAccount}
        pageQuery={query.pageQuery}
        setPageQuery={query.setPageQuery}
        setDisplayCurrencyInfo={onDisplayCurrencyInfo}
        setDisplayDynamicLeverageInfo={onDisplayDynamicLeverageInfo}
        columnsProperties={columnsProperties}
        platformTypes={platformTypes}
      />
      {query.meta && (
        <Paging
          maxPageSize={500}
          pageData={query.meta}
          isLoading={query.isLoading}
          onPageChanged={(pageIndex, pageSize, pagingEventType) => {
            if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
              setIsTAPaginationEntriesSelected(true)
            }
            query.setPageQuery!({
              pageIndex,
              pageSize,
            })
          }}
          scrollToHeaderId={ScrollToIds.LiveTradingAccountsHeader}
        />
      )}
    </React.Fragment>
  )
}

interface DemoTACardsProps {
  query: IFetchResult<DemoTradingAccount[]>
  accountsConfiguration: ClientAccountsConfigurationDto
  onSortModalOpen(data: SortState): void
  showSnackbarError(): void
  onDemoInfoModal(value: string): void
  onDisplayDynamicLeverageInfo?(v: boolean): void

  platformTypes: PlatformType[]
  onSelectedTradingAccount(value: TradingAccount | DemoTradingAccount): void
  setIsDemoTAPaginationEntriesSelected: (isDemoTAPaginationEntriesSelected: boolean) => void
}

const DemoTACards: React.FC<DemoTACardsProps> = (props) => {
  const {
    query,
    accountsConfiguration,
    onSortModalOpen,
    onDemoInfoModal,
    showSnackbarError,
    onSelectedTradingAccount,
    onDisplayDynamicLeverageInfo,
    platformTypes,
    setIsDemoTAPaginationEntriesSelected,
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()

  const { sortOptions } = useSort()
  const getButtonLabelWithPlus = useAddButtonLabel()
  const entityConfig = useEntityConfig()

  const isCreateAllowed = accountsConfiguration.demoAccountCreateAllowed || false
  const isCountryAllowed = entityConfig?.demoAccountAllowed || false

  const handleSortModalOpen = () => {
    onSortModalOpen({
      type: 'demo',
      visible: true,
    })
  }

  const infoMessage = () => {
    if (!isCreateAllowed && !isCountryAllowed) {
      return t(
        'Trading Account.It appears that the Demo Trading Account is not available in your country'
      )
    }

    if (!isCreateAllowed) {
      return t('Trading Account.You have reached the maximum number of Demo Trading Accounts')
    }

    return undefined
  }

  return (
    <React.Fragment>
      <PageHeader
        id={ScrollToIds.DemoTradingAccountsHeader}
        renderTitle={() => (
          <TextLargeStrong>{t('Trading Account.Demo Trading Accounts')}</TextLargeStrong>
        )}
        actions={[
          {
            size: 'S',
            onClick: () => navigate('/dashboard/traders-room/trading-accounts/new/demo'),
            label: getButtonLabelWithPlus(),
            disabled: !isCreateAllowed,
            tooltipText: infoMessage(),
          },
        ]}
        filterToggles={
          query.data.length > 0
            ? {
                sortLabel: normalizeSortLabel(t, sortOptions, query.pageQuery?.sort),
                openSortModal: handleSortModalOpen,
                className: 'is-flex is-justify-content-end',
              }
            : undefined
        }
      />
      <div
        className={classNames('is-flex is-flex-direction-column', {
          'mt-2': query.data.length > 0,
          'mt-6': !query.data.length,
        })}
      >
        {' '}
        {query.data.map((ta, id) => (
          <DemoTradingAccountCard
            key={id}
            handleSetSelectedTradingAccount={onSelectedTradingAccount}
            handleInfoModal={onDemoInfoModal}
            isActive
            platformTypes={platformTypes}
            tradingAccount={ta}
            showSnackbarError={showSnackbarError}
            onDisplayDynamicLeverageInfo={onDisplayDynamicLeverageInfo}
            cardId={getScrollToCardId(id, query.data.length, 'demo-trading-accounts', 'end')}
          />
        ))}
        {query.meta && (
          <Paging
            pageData={query.meta}
            isLoading={query.isLoading}
            onPageChanged={(pageIndex, pageSize, pagingEventType) => {
              if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
                setIsDemoTAPaginationEntriesSelected(true)
              }
              query.setPageQuery!({
                pageIndex,
                pageSize,
              })
            }}
            scrollToHeaderId={ScrollToIds.DemoTradingAccountsHeader}
          />
        )}
      </div>
    </React.Fragment>
  )
}

interface DemoTATableProps {
  query: IFetchResult<DemoTradingAccount[]>
  accountsConfiguration: ClientAccountsConfigurationDto
  showSnackbarError(): void
  onDemoInfoModal(value: string): void
  onDisplayCurrencyInfo(value: boolean): void
  onWebTraderMT4IsUnavailableModalVisible(value: boolean): void
  onWebTraderMT5IsUnavailableModalVisible(value: boolean): void
  onWebTraderTickmillTraderIsUnavailableModalVisible(value: boolean): void
  onCampaignModal(value: boolean): void
  onSelectedTradingAccount(value: TradingAccount | DemoTradingAccount): void
  onDisplayDynamicLeverageInfo?(v: boolean): void
  platformTypes: PlatformType[]
  isDemoTickmillTraderCreateAllowed?: boolean
  setIsDemoTAPaginationEntriesSelected: (isDemoTAPaginationEntriesSelected: boolean) => void
}

const DemoTATable: React.FC<DemoTATableProps> = (props) => {
  const {
    query,
    accountsConfiguration,
    onDemoInfoModal,
    onDisplayCurrencyInfo,
    showSnackbarError,
    onWebTraderMT4IsUnavailableModalVisible,
    onWebTraderMT5IsUnavailableModalVisible,
    onWebTraderTickmillTraderIsUnavailableModalVisible,
    onDisplayDynamicLeverageInfo,
    onSelectedTradingAccount,
    onCampaignModal,
    platformTypes,
    isDemoTickmillTraderCreateAllowed,
    setIsDemoTAPaginationEntriesSelected,
  } = props

  const entityConfig = useEntityConfig()

  return (
    <React.Fragment>
      <DemoTradingAccountsTable
        handleInfoModal={onDemoInfoModal}
        data={query.data || []}
        isLoading={query.isLoading}
        isCreateAllowed={accountsConfiguration.demoAccountCreateAllowed || false}
        isDemoTickmillTraderCreateAllowed={isDemoTickmillTraderCreateAllowed}
        isCountryAllowed={entityConfig?.demoAccountAllowed || false}
        setWebTraderMT4IsUnavailableModalVisible={onWebTraderMT4IsUnavailableModalVisible}
        setWebTraderMT5IsUnavailableModalVisible={onWebTraderMT5IsUnavailableModalVisible}
        setWebTraderTickmillTraderIsUnavailableModalVisible={
          onWebTraderTickmillTraderIsUnavailableModalVisible
        }
        setCampaignModal={onCampaignModal}
        showSnackbarError={showSnackbarError}
        setDisplayDynamicLeverageInfo={onDisplayDynamicLeverageInfo}
        handleSetSelectedTradingAccount={onSelectedTradingAccount}
        pageQuery={query.pageQuery}
        setPageQuery={query.setPageQuery}
        setDisplayCurrencyInfo={onDisplayCurrencyInfo}
        platformTypes={platformTypes}
      />
      {query.meta && (
        <Paging
          pageData={query.meta}
          isLoading={query.isLoading}
          onPageChanged={(pageIndex, pageSize, pagingEventType) => {
            if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
              setIsDemoTAPaginationEntriesSelected(true)
            }
            query.setPageQuery!({
              pageIndex,
              pageSize,
            })
          }}
          scrollToHeaderId={ScrollToIds.DemoTradingAccountsHeader}
        />
      )}
    </React.Fragment>
  )
}

interface InactiveTACardsProps {
  query: IFetchResult<TradingAccount[]>
  onSortModalOpen(data: SortState): void
  onSelectedTradingAccount(value: TradingAccount | DemoTradingAccount): void
  showSnackbarError(): void
  setIsInactiveTAPaginationEntriesSelected: (isInactiveTAPaginationEntriesSelected: boolean) => void
}

const InactiveTACards: React.FC<InactiveTACardsProps> = (props) => {
  const {
    query,
    onSortModalOpen,
    onSelectedTradingAccount,
    showSnackbarError,
    setIsInactiveTAPaginationEntriesSelected,
  } = props

  const { t } = useTranslation()
  const { sortOptions } = useSort()

  const handleSortModalOpen = () => {
    onSortModalOpen({
      type: 'inactive',
      visible: true,
    })
  }

  return (
    <React.Fragment>
      <PageHeader
        id={ScrollToIds.InactiveLiveTradingAccountsHeader}
        renderTitle={() => (
          <TextLargeStrong className='text-primary'>
            {t('Trading Account.Inactive Trading Accounts')}
          </TextLargeStrong>
        )}
        filterToggles={
          query?.data?.length > 0
            ? {
                sortLabel: normalizeSortLabel(t, sortOptions, query.pageQuery?.sort),
                openSortModal: handleSortModalOpen,
                className: 'is-flex is-justify-content-end',
              }
            : undefined
        }
      />
      <div
        className={classNames('is-flex is-flex-direction-column', {
          'mt-2': query.data.length > 0,
          'mt-6': !query.data.length,
        })}
      >
        {query.data.map((ta: TradingAccount, index: number) => (
          <TradingAccountCard
            tradingAccount={ta}
            isActive={false}
            showSnackbarError={showSnackbarError}
            handleSetSelectedTradingAccount={onSelectedTradingAccount}
            key={index}
            cardId={getScrollToCardId(
              index,
              query.data.length,
              'inactive-live-trading-account',
              'end'
            )}
          />
        ))}
        {query.meta && (
          <Paging
            pageData={query.meta}
            isLoading={query.isLoading}
            onPageChanged={(pageIndex, pageSize, pagingEventType) => {
              if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
                setIsInactiveTAPaginationEntriesSelected(true)
              }
              query.setPageQuery!({
                pageIndex,
                pageSize,
              })
            }}
            scrollToHeaderId={ScrollToIds.InactiveLiveTradingAccountsHeader}
          />
        )}
      </div>
    </React.Fragment>
  )
}

interface InactiveTATable {
  query: IFetchResult<TradingAccount[]>
  hasReachedMaxTradingAccounts?: boolean
  columnsProperties?: ElementResizeProperty<HTMLTableHeaderCellElement>[]
  setIsInactiveTAPaginationEntriesSelected: (isInactiveTAPaginationEntriesSelected: boolean) => void
}

const InactiveTATable: React.FC<InactiveTATable> = (props) => {
  const {
    query,
    hasReachedMaxTradingAccounts,
    columnsProperties,
    setIsInactiveTAPaginationEntriesSelected,
  } = props

  return (
    <div className='mb-3'>
      <InactiveTradingAccountsTable
        data={query.data || []}
        hasReachedMaxTradingAccounts={hasReachedMaxTradingAccounts}
        isLoading={query.isLoading}
        pageQuery={query.pageQuery}
        setPageQuery={query.setPageQuery}
        columnsProperties={columnsProperties}
      />
      {query.meta && (
        <Paging
          scrollToHeaderId={ScrollToIds.InactiveLiveTradingAccountsHeader}
          pageData={query.meta}
          isLoading={query.isLoading}
          onPageChanged={(pageIndex, pageSize, pagingEventType) => {
            if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
              setIsInactiveTAPaginationEntriesSelected(true)
            }
            query.setPageQuery!({
              pageIndex,
              pageSize,
            })
          }}
        />
      )}
    </div>
  )
}

interface InactiveDemoTACardsProps {
  query: IFetchResult<DemoTradingAccount[]>
  onSortModalOpen(data: SortState): void
  showSnackbarError(): void
  onInfoModal(value: string): void
  setIsInactiveDemoTAPaginationEntriesSelected: (
    isInactiveDemoTAPaginationEntriesSelected: boolean
  ) => void
}

const InactiveDemoTACards: React.FC<InactiveDemoTACardsProps> = (props) => {
  const {
    query,
    onSortModalOpen,
    onInfoModal,
    showSnackbarError,
    setIsInactiveDemoTAPaginationEntriesSelected,
  } = props

  const { t } = useTranslation()
  const { sortOptions } = useSort()

  const handleSortModalOpen = () => {
    onSortModalOpen({
      type: 'demo_inactive',
      visible: true,
    })
  }

  return (
    <React.Fragment>
      <PageHeader
        id={ScrollToIds.InactiveDemoTradingAccountsHeader}
        renderTitle={() => (
          <TextLargeStrong className='text-primary'>
            {t('Trading Account.Inactive Demo Trading Accounts')}
          </TextLargeStrong>
        )}
        filterToggles={
          query.data.length > 0
            ? {
                sortLabel: normalizeSortLabel(t, sortOptions, query.pageQuery?.sort),
                openSortModal: handleSortModalOpen,
                className: 'is-flex is-justify-content-end',
              }
            : undefined
        }
      />
      <div
        className={classNames('is-flex is-flex-direction-column', {
          'mt-2': query.data.length > 0,
          'mt-6': !query.data.length,
        })}
      >
        {' '}
        {query.data.map((ta, id) => (
          <DemoTradingAccountCard
            tradingAccount={ta}
            isActive={false}
            showSnackbarError={showSnackbarError}
            handleInfoModal={onInfoModal}
            key={id}
            cardId={getScrollToCardId(
              id,
              query.data.length,
              'inactive-demo-trading-accounts',
              'end'
            )}
          />
        ))}
        {query.meta && (
          <Paging
            pageData={query.meta}
            isLoading={query.isLoading}
            onPageChanged={(pageIndex, pageSize, pagingEventType) => {
              if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
                setIsInactiveDemoTAPaginationEntriesSelected(true)
              }
              query.setPageQuery!({
                pageIndex,
                pageSize,
              })
            }}
            scrollToHeaderId={ScrollToIds.InactiveDemoTradingAccountsHeader}
          />
        )}
      </div>
    </React.Fragment>
  )
}

interface InactiveDemoTATable {
  query: IFetchResult<DemoTradingAccount[]>
  hasReachedMaxTradingAccounts?: boolean
  setIsInactiveDemoTAPaginationEntriesSelected: (
    isInactiveDemoTAPaginationEntriesSelected: boolean
  ) => void

  onDemoInfoModal(value: string): void
  columnsProperties?: ElementResizeProperty<HTMLTableHeaderCellElement>[]
}

const InactiveDemoTATable: React.FC<InactiveDemoTATable> = (props) => {
  const {
    query,
    hasReachedMaxTradingAccounts,
    onDemoInfoModal,
    setIsInactiveDemoTAPaginationEntriesSelected,
  } = props

  const { t } = useTranslation()

  return (
    <React.Fragment>
      <DemoTradingAccountsTable
        handleInfoModal={onDemoInfoModal}
        data={query.data || []}
        hasReachedMaxTradingAccounts={hasReachedMaxTradingAccounts}
        isLoading={query.isLoading}
        isDemoTradingInActive={true}
        title={t('Trading Account.Archived')}
        pageQuery={query.pageQuery}
        setPageQuery={query.setPageQuery}
      />
      {query.meta && (
        <Paging
          pageData={query.meta}
          isLoading={query.isLoading}
          onPageChanged={(pageIndex, pageSize, pagingEventType) => {
            if (pagingEventType === PagingEventType.ENTRIES_CHANGED) {
              setIsInactiveDemoTAPaginationEntriesSelected(true)
            }
            query.setPageQuery!({
              pageIndex,
              pageSize,
            })
          }}
          scrollToHeaderId={ScrollToIds.InactiveDemoTradingAccountsHeader}
        />
      )}
    </React.Fragment>
  )
}
