import { useState } from 'react'
import { NavigateOptions, useLocation, useNavigate } from 'react-router-dom'

import { AuthActionIds } from '../Profile/AccountSettings/SecuritySettingsPage'
import { NameDto } from '../model/NameDto'
import { TwoFAProviderItem } from '../model/TwoFactorAuthentication'
import { errorOptions } from '../routing/RouterToaster'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { ResponseError, instanceOfAxiosError, useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { isTickmillPartner } from '../utils/companyName.utils'

export interface TwoFactorWarningState {
  visible: boolean
  walletId: string | null
}

interface UseWithdrawalPageNavigationProps {
  handleWithdrawalNavigation: (withdrawalOptions?: {
    walletId?: string
    navigateOptions?: NavigateOptions
  }) => Promise<void>
  twoFactorWarningModalState: TwoFactorWarningState
  onDismissModal(): void
  getWithdrawalPath: (walletId?: string | null) => string
  walletWithdrawLoadingId: string
}

const DEFAULT_MODAL_STATE = { visible: false, walletId: null }

export const useWithdrawalPageNavigation = (): UseWithdrawalPageNavigationProps => {
  const [activeTwoFAProviders, setActiveTwoFAProviders] = useState<TwoFAProviderItem[]>([])
  const [providerTypes, setProviderTypes] = useState<NameDto<number>[]>([])
  const [authTypes, setAuthTypes] = useState<NameDto[]>([])

  const [twoFactorWarningModalState, setTwoFactorWarningModalState] =
    useState<TwoFactorWarningState>(DEFAULT_MODAL_STATE)
  const [loadingWalletId, setLoadingWalletId] = useState<string>('')
  const navigate = useNavigate()
  const location = useLocation()
  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()

  function getWithdrawalPath(walletId?: string | null): string {
    const isPAWithdrawal = location.pathname.includes('/dashboard/payment-agent/')
    const isIBPath = location.pathname.includes('/dashboard/introducing-broker/')
    const isIBOrPartnerWithdrawal =
      (account?.clientIntroducingBroker?.id || isTickmillPartner(account)) && isIBPath

    if (isPAWithdrawal) {
      return walletId
        ? `/dashboard/payment-agent/wallets/${walletId}/withdrawal`
        : '/dashboard/payment-agent/withdrawal'
    } else if (isIBOrPartnerWithdrawal) {
      return walletId
        ? `/dashboard/introducing-broker/wallets/${walletId}/withdrawal`
        : '/dashboard/introducing-broker/withdrawal'
    } else {
      return walletId
        ? `/dashboard/traders-room/wallets/${walletId}/withdrawal`
        : '/dashboard/traders-room/wallets/withdrawal'
    }
  }

  const fetchActive2FAProviders = async (walletId?: string) => {
    try {
      const response = await apiClient.get2FAProviders()
      setActiveTwoFAProviders(response)
      return response
    } catch (error: unknown) {
      if (instanceOfAxiosError((error as ResponseError)?.response)) {
        const errorResponse = (error as ResponseError).response.response?.data
        if (
          errorOptions.two_factor_authentication_disabled_for_county_of_residence_of_user ===
          errorResponse?.code
        ) {
          navigate(
            walletId
              ? `/dashboard/traders-room/wallets/${walletId}/withdrawal`
              : '/dashboard/traders-room/wallets/withdrawal/'
          )
        } else {
          throw error
        }
      }
    }
  }

  const fetch2FAProviderTypes = async () => {
    const response = await apiClient.get2FAProviderTypes()
    setProviderTypes(response)
    return response
  }

  const fetch2FAProviderActionTypes = async () => {
    const response = await apiClient.get2FAProviderActionTypes()
    setAuthTypes(response)
    return response
  }

  const handleWithdrawalNavigation = async (withdrawalOptions?: {
    walletId?: string
    navigateOptions?: NavigateOptions
  }) => {
    const { walletId, navigateOptions = { state: {} } } = withdrawalOptions ?? {}
    setLoadingWalletId(walletId ?? 'general')

    try {
      const availableTwoFactorProviderTypes =
        providerTypes.length > 0 ? providerTypes : await fetch2FAProviderTypes()
      const isAny2FAProviderTypeAvailable = !!availableTwoFactorProviderTypes.length
      if (!isAny2FAProviderTypeAvailable) {
        navigate(getWithdrawalPath(walletId), navigateOptions)
        return
      }

      const availableTwoFactorActionTypes =
        authTypes.length > 0 ? authTypes : await fetch2FAProviderActionTypes()
      const has2FAWithdrawActionTypeAvailable = availableTwoFactorActionTypes.some(
        (actionType) => actionType.id === AuthActionIds.Withdraw
      )
      if (!has2FAWithdrawActionTypeAvailable) {
        navigate(getWithdrawalPath(walletId), navigateOptions)
        return
      }

      const active2FAProviders =
        activeTwoFAProviders.length > 0
          ? activeTwoFAProviders
          : await fetchActive2FAProviders(walletId)
      const hasWithdrawTwoFactorEnabled =
        active2FAProviders?.[0]?.authActions?.some(({ id }) => id === AuthActionIds.Withdraw) ??
        false

      if (hasWithdrawTwoFactorEnabled) {
        navigate(getWithdrawalPath(walletId), navigateOptions)
      } else {
        setTwoFactorWarningModalState({ visible: true, walletId: walletId ?? null })
      }
    } finally {
      setLoadingWalletId('')
    }
  }

  const handleDismissModal = () => setTwoFactorWarningModalState(DEFAULT_MODAL_STATE)

  return {
    handleWithdrawalNavigation,
    twoFactorWarningModalState,
    onDismissModal: handleDismissModal,
    getWithdrawalPath,
    walletWithdrawLoadingId: loadingWalletId,
  }
}
