import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom'

import { isVerificationCodeError } from '../../../../../Profile/PhoneNumbers/Modals/PhoneNumberVerifyForm'
import { useDeviceReferral } from '../../../../../Referral/DeviceReferral'
import { Loading } from '../../../../../global/Loading/Loading'
import { Button } from '../../../../../global/button/Button'
import { useEntitySettings } from '../../../../../global/context/EntityContext'
import { useSignup } from '../../../../../global/context/SignupContext'
import { PhoneVerificationWorkflowEnum } from '../../../../../model/CreatePhoneNumberVerification'
import { LeadDto, isLeadSourceMobileRegistration } from '../../../../../model/LeadDto'
import { Text } from '../../../../../ui/Typography/Typography'
import { ResponseError, instanceOfAxiosError, useApiClient } from '../../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../../utils/clientApi'
import { useWindowResize } from '../../../../../utils/domUtils'
import { useLocallyPersistedState } from '../../../../../utils/useStorage'
import { NoteWrapper } from '../../../Components/NoteWrapper'
import { SignupError } from './SignupErrorPage'

export const VerifyEmailRedirect: React.FC = () => {
  const { leadId } = useParams()
  const [searchParams] = useSearchParams()

  if (leadId) {
    return (
      <Navigate
        to={`/sign-up/personal-details/verify-email?${searchParams.toString()}`}
        state={{ leadId }}
      />
    )
  } else {
    return <Navigate to='/login' />
  }
}

export const VerifyEmailPage: React.FC = () => {
  const [searchParams] = useSearchParams()
  const [, setEntity] = useEntitySettings()

  const location = useLocation()
  const { leadId } = location.state || {}

  const { setLead, signupData } = useSignup()

  const apiClient = useApiClient(ClientApiClient)
  const navigate = useNavigate()
  const leadEmail = useRef('')

  const [loading, setLoading] = useState(true)
  const [, persistLead] = useLocallyPersistedState<LeadDto | undefined>('lead', undefined)

  const verifyEmail = useCallback(async () => {
    const hash = searchParams.get('hash')
    const token = searchParams.get('token')

    try {
      if (!leadId || !token) {
        return navigate('/login')
      }

      const leadDto = await apiClient.getLeadByIdAndToken(leadId, token)
      setEntity({ entity: leadDto.tickmillCompany.id })
      persistLead(leadDto)

      try {
        if (hash) {
          await apiClient.verifyEmail({ email: leadDto.email, token: hash })
        }
      } catch (e: unknown) {
        if (instanceOfAxiosError((e as ResponseError)?.response)) {
          const errorResponse = (e as ResponseError).response.response?.data
          if (errorResponse?.code === 'lead_already_verified') {
            await setLead(leadDto)
            if (
              !leadDto.phoneVerificationRequired &&
              !isLeadSourceMobileRegistration(leadDto.leadSource)
            ) {
              navigate(`/sign-up/personal-details/4/?${searchParams.toString()}`, {
                state: { leadId },
              })
              return
            }
          } else if (errorResponse?.code) {
            throw e
          }
        }
      }

      if (isLeadSourceMobileRegistration(leadDto?.leadSource)) {
        leadEmail.current = leadDto.email
        return await setLead(leadDto)
      }

      if (leadDto.phoneVerificationRequired) {
        const createdPhoneVerification = await apiClient.createPhoneNumberVerification(leadDto.id, {
          phoneNumber: leadDto.phoneNumber,
          leadId: leadDto.id,
          countryCode: leadDto.countryCode,
          countryId: leadDto.country.id,
          workflowId: PhoneVerificationWorkflowEnum.WorkflowSMS,
        })
        leadEmail.current = leadDto.email
        await setLead(leadDto)
        return navigate(
          `/sign-up/personal-details/verify-phone/${leadId}?token=${token}&phoneVerificationId=${createdPhoneVerification.phoneVerificationId}`
        )
      } else {
        leadEmail.current = leadDto.email
        await setLead(leadDto)
        navigate(`/sign-up/personal-details/4/?${searchParams.toString()}`, {
          state: { leadId },
        })
      }
    } catch (err: unknown) {
      if ((err as ResponseError).response?.response?.data.code) {
        const errorResponse = (err as ResponseError).response?.response?.data
        if (errorResponse?.code) {
          if (isVerificationCodeError(errorResponse?.code)) {
            navigate(`/sign-up/personal-details/4/?${searchParams.toString()}`, {
              state: { leadId },
            })
          } else if (errorResponse?.code === SignupError.BackToLogin) {
            return navigate('/login')
          } else {
            const errorSearchParams = new URLSearchParams()
            errorSearchParams.set('error', errorResponse?.code)
            errorSearchParams.set('leadId', leadId)
            if (leadEmail.current) {
              errorSearchParams.set('leadEmail', leadEmail.current || '')
            }
            navigate(`/sign-up/personal-details/error?${errorSearchParams.toString()}`)
          }
        }
      }
    } finally {
      setLoading(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [leadId, searchParams])

  useEffect(() => {
    verifyEmail()
  }, [verifyEmail])

  return (
    <React.Fragment>
      {!isLeadSourceMobileRegistration(signupData.lead?.leadSource) && (
        <Loading showLoadingIcon isLoading={loading} />
      )}
      {isLeadSourceMobileRegistration(signupData.lead?.leadSource) && (
        <MobileAppRedirectSuccessPage lead={signupData.lead} />
      )}
    </React.Fragment>
  )
}

interface MobileAppRedirectSuccessPageProps {
  lead?: LeadDto
}

const MobileAppRedirectSuccessPage: React.FC<MobileAppRedirectSuccessPageProps> = (props) => {
  const { lead } = props
  const { referralAppLink } = useDeviceReferral()
  const isMobile = useWindowResize()

  const { t } = useTranslation()

  const goToMobileApp = () => {
    if (lead) {
      window.location.href = referralAppLink({
        id: lead.id,
        token: lead.token,
        type: 'register',
      })
    }
  }

  return (
    <NoteWrapper
      title={t('Sign up.Your email has been verified!')}
      text={
        <Text>
          {t('Sign up.Please continue your registration by going back to the mobile app.')}
        </Text>
      }
      button={
        <Button
          fullWidth={isMobile}
          appearance='primary'
          size='L'
          type='button'
          onClick={goToMobileApp}
        >
          {t('Sign up.Back to the app')}
        </Button>
      }
    />
  )
}
