import React, {
  ForwardRefExoticComponent,
  PropsWithChildren,
  RefAttributes,
  forwardRef,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react'
import classNames from 'classnames'
import { Variants, motion } from 'framer-motion'

import { useTooltipMove } from './useTooltipMove'

import styles from './Tooltip.module.scss'

export type TooltipDirection =
  | 'top'
  | 'bottom'
  | 'left'
  | 'right'
  | 'topRight'
  | 'topLeft'
  | 'bottomRight'
  | 'bottomLeft'

interface TooltipProps {
  content?: string | null
  direction?: TooltipDirection
  autoHide?: number
  disabled?: boolean
  className?: string
  isNew?: boolean
  wrapperClassName?: string
}

type UseImperativeHandleRef = { activate: () => void }

interface TooltipComponent
  extends ForwardRefExoticComponent<
    PropsWithChildren<TooltipProps> & RefAttributes<UseImperativeHandleRef>
  > {
  New: ForwardRefExoticComponent<
    PropsWithChildren<TooltipProps> & RefAttributes<UseImperativeHandleRef>
  >
}

export const Tooltip = forwardRef<UseImperativeHandleRef, PropsWithChildren<TooltipProps>>(
  (props, ref) => {
    const {
      content,
      direction = 'top',
      children,
      disabled,
      className,
      wrapperClassName,
      isNew,
    } = props
    const { autoHide } = props

    const [active, setActive] = useState(false)

    const { boxRef, newDirection } = useTooltipMove(direction)

    const variants = useMemo<Variants>(() => {
      return {
        open: {
          opacity: 1,
          transition: {
            delay: isNew ? 0.1 : 0,
            type: 'spring',
            bounce: 0,
            duration: 0.5,
          },
        },
        closed: {
          opacity: 0,
          transition: {
            type: 'spring',
            bounce: 0,
            duration: 0.25,
          },
        },
      }
    }, [isNew])

    useImperativeHandle(ref, () => ({
      activate() {
        if (!active && !disabled && !!content) {
          setActive(true)
          if (autoHide) {
            setTimeout(() => setActive(false), autoHide)
          }
        }
      },
    }))

    const showTip = () => {
      if (disabled || !content) {
        return
      }
      setActive(true)
    }

    const hideTip = () => {
      if (disabled || !content) {
        return
      }
      setActive(false)
    }

    return (
      <div
        onMouseEnter={showTip}
        onMouseLeave={hideTip}
        className={classNames(styles.tooltipWrapper, { [styles.new]: isNew }, wrapperClassName)}
      >
        {children}
        <motion.div
          animate={active ? 'open' : 'closed'}
          initial='closed'
          variants={variants}
          className={classNames(styles.tooltip, styles[newDirection], className, {
            [styles.active]: active,
          })}
          ref={boxRef}
        >
          <span>{content}</span>
        </motion.div>
      </div>
    )
  }
) as TooltipComponent

Tooltip.New = forwardRef<UseImperativeHandleRef, TooltipProps>((props, ref) => {
  return <Tooltip {...props} isNew ref={ref} />
})
