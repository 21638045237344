import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router-dom'

import { WalletDepositFormValues } from '../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/WalletDepositForm'
import { useGetDepositCurrencyLimits } from '../../Traders-Room/Wallets/WalletDeposit/WalletDepositForm/hooks/useGetDepositCurrencyLimits'
import { useWalletDepositMutate } from '../../Traders-Room/Wallets/WalletDeposit/WalletDepositPage/WalletDepositPage'
import { Loading } from '../../global/Loading/Loading'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { FirstTimeDepositForm } from '../../pages/Signup/Pages/FirstTimeDeposit/Components/FirstTimeDepositForm'
import { useDepositSubmit } from '../../pages/Signup/Pages/FirstTimeDeposit/hooks/useDepositSubmit'
import { Text, TextH3 } from '../../ui/Typography/Typography'
import { isAccountProofOfIdentificationVerified } from '../../utils/AccountAccess/accountCompanyStatuses'
import { useAccountReadContext, useAccountWriteContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { CommonPaths } from '../../utils/path'
import { useFetchOne } from '../../utils/useFetch'

import styles from './FirstTimeDepositPage.module.scss'

export const FirstTimeDepositPage: React.FC = () => {
  const { account } = useAccountReadContext()
  const skipRedirectPath = isAccountProofOfIdentificationVerified(account)
    ? CommonPaths.TradersRoomWallets
    : '/profile/document-management/'

  const location = useLocation()
  const apiClient = useApiClient(ClientApiClient)
  const { formatMoney } = useFormatNumber()
  const { t } = useTranslation()
  const { refreshAccount } = useAccountWriteContext()
  const locale = useSessionLanguage()
  const { handleDepositSubmit } = useDepositSubmit()
  const { getDepositCurrencyLimits } = useGetDepositCurrencyLimits()

  const getAvailableWalletsCallback = useCallback(async () => {
    if (location?.state?.wallets?.length) {
      return location.state.wallets
    }
    return apiClient.getWallets()
  }, [apiClient, location?.state?.wallets])

  const { data: wallets, isLoading: isLoadingWallets } = useFetchOne(getAvailableWalletsCallback)

  const walletDepositMutate = useWalletDepositMutate({
    onSubmit: (data, values) => {
      handleDepositSubmit(data, values)
      refreshAccount(locale)
    },
  })

  const handleFormSubmit = async (values: WalletDepositFormValues) => {
    walletDepositMutate.mutate(values)
  }

  return (
    <section className={styles.firstTimeDepositPage}>
      <div className={styles.header}>
        <TextH3>{t('Fund your Wallet')}</TextH3>

        <Text isParagraph className={styles.description}>
          {t(
            'Profile.Secure your account by making your first deposit. Choose your preferred payment method to get started!'
          )}
        </Text>
      </div>

      <Loading showLoadingIcon isLoading={isLoadingWallets}>
        <FirstTimeDepositForm
          wallets={wallets ?? []}
          onSubmit={handleFormSubmit}
          getDepositCurrencyLimits={getDepositCurrencyLimits}
          formatMoney={formatMoney}
          skipButtonLink={skipRedirectPath}
        />
      </Loading>
    </section>
  )
}
