import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate } from 'react-router-dom'

import { TwoFactorAuthEvents } from '../../TwoFactorAuth/useTwoFactorAuth'
import { Loading } from '../../global/Loading/Loading'
import { Button } from '../../global/button/Button'
import { useSnackbar } from '../../global/context/SnackbarContext'
import { ToastContext, errorToast } from '../../global/toast/Toast'
import { useLogout } from '../../hooks/useLogout'
import { getVerifiedPhoneNumbers } from '../../model/AccountDetailedDto'
import { NameDto } from '../../model/NameDto'
import {
  Change2FAProvidersData,
  GoogleAuthenticator2FAProviderDataDto,
  TwoFAProviderEnum,
  TwoFAProviderItem,
  VerifyFirstTimeUseData,
} from '../../model/TwoFactorAuthentication'
import { errorOptions } from '../../routing/RouterToaster'
import { Text, TextH2, TextH3 } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { ResponseError, instanceOfAxiosError, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { FormSubmitValues } from '../../utils/formValidation'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { AccountSettingsBox } from './AccountSettingsBox'
import {
  ChangePasswordForm,
  ChangePasswordFormValues,
  PasswordInfoModal,
} from './ChangePasswordForm'
import {
  GoogleAuthenticatorFormModal,
  GoogleAuthenticatorModalFormValues,
} from './GoogleAuthenticatorFormModal'
import { SecuritySettingsInfoModal } from './SecuritySettingsInfoModal'
import {
  ConfirmTurnOffTwoFactorAuthenticationModal,
  TwoFactorAuthentication,
  TwoFactorAuthenticationGenerateRecoveryCodesModal,
  TwoFactorAuthenticationInfoModal,
  TwoFactorAuthenticationSelectPhoneModal,
} from './TwoFactorAuthentication'
import { handleBaseTwoFactorErrors } from './handleBaseTwoFactorErrors'

import styles from './AccountSettingsPage.module.scss'

export enum AuthActionIds {
  Login = 1,
  Withdraw = 2,
  PasswordChange = 3,
}

export const SecuritySettingsPage: React.FC = () => {
  useScrollToTop()
  const navigate = useNavigate()
  const setToast = useContext(ToastContext)
  const apiClient = useApiClient(ClientApiClient)
  const { logout } = useLogout()
  const { account } = useAccountReadContext()
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()

  const isMobile = useWindowResize()
  const location = useLocation()

  const [passwordModal, setPasswordModal] = useState(false)
  const [twoFactorAuthenticationInfoModal, setTwoFactorAuthenticationInfoModal] = useState(false)
  const [activeProviders, setActiveProviders] = useState<TwoFAProviderItem[]>([])
  const [selectedProvider, setSelectedProvider] = useState<NameDto>()
  const [selectedAuthAction, setSelectedAuthAction] = useState<NameDto>()
  const [selectPhoneModal, setSelectPhoneModal] = useState(false)
  const [turnOffConfirmationModal, setTurnOffConfirmationModal] = useState(false)
  const [activeTab, setActiveTab] = useState<'2fa' | 'password'>('2fa')
  const [infoModalData, setInfoModalData] = useState({
    text: '',
    isVisible: false,
  })

  const [twoFactorGoogleAuthenticatorModal, setTwoFactorGoogleAuthenticatorModal] = useState(false)
  const [generateRecoveryCodesModal, setGenerateRecoveryCodesModal] = useState(false)
  const [selectedPhone, setSelectedPhone] = useState<string>()
  const [googleAuthenticatorData, setGoogleAuthenticatorData] =
    useState<GoogleAuthenticator2FAProviderDataDto | null>(null)
  const [googleAuthenticatorError, setGoogleAuthenticatorError] = useState(false)
  const [isGoogleAuthenticatorDataLoading, setIsGoogleAuthenticatorDataLoading] =
    useState<boolean>(false)
  const [isTwoFactorFunctionalityEnabled, setIsTwoFactorFunctionalityEnabled] = useState(true)
  const verifiedPhones = getVerifiedPhoneNumbers(account)
  const email = useMemo(() => account?.emails.find((x) => x.isPrimary)?.address, [account])
  const [previousActive2FAProviders, setPreviousActive2FAProviders] =
    useState<TwoFAProviderItem[]>()

  useEffect(() => {
    const state = location.state || {}
    const {
      isPasswordTabSelected,
      isTooManyCodeInsertAttempts,
      isTooManyResendAttempts,
      newCodeCantBeSent,
      cooldownInMinutes,
      cooldownInSeconds,
    } = state

    if (isPasswordTabSelected) {
      setActiveTab('password')
    } else if (isTooManyCodeInsertAttempts && cooldownInMinutes) {
      setToast(
        errorToast({
          type: 'danger',
          title: t(
            'TwoFactorAuth.2FA max insert code attempts reached. Logging in to your account is suspended for the remaining duration of X minutes',
            {
              minutes: cooldownInMinutes,
            }
          ),
        })
      )
    } else if (isTooManyResendAttempts && cooldownInMinutes) {
      setToast(
        errorToast({
          type: 'danger',
          title: t(
            "TwoFactorAuth.You've reached the maximum amount of verification attempts. Try again in X minutes",
            {
              minutes: cooldownInMinutes,
            }
          ),
        })
      )
    } else if (newCodeCantBeSent && cooldownInSeconds) {
      setToast(
        errorToast({
          type: 'danger',
          title: t(
            'TwoFactorAuth.2FA requesting a new one time code cannot executed for the next {{ seconds }} seconds',
            {
              seconds: cooldownInSeconds,
            }
          ),
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  const get2FAProviderTypesCallback = useCallback(async () => {
    const response = await apiClient.get2FAProviderTypes()
    if (!response?.length) {
      setActiveTab('password')
    }
    return response
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const fetch2FAProviders = useCallback(async () => {
    try {
      const twoFAProviders = await apiClient.get2FAProviders()
      setActiveProviders(twoFAProviders)
      setPreviousActive2FAProviders(twoFAProviders)

      return twoFAProviders
    } catch (error: unknown) {
      if (instanceOfAxiosError((error as ResponseError)?.response)) {
        const errorResponse = (error as ResponseError).response.response?.data

        if (
          errorOptions.two_factor_authentication_disabled_for_county_of_residence_of_user ===
          errorResponse?.code
        ) {
          setIsTwoFactorFunctionalityEnabled(false)
          setActiveTab('password')
        }
        throw error
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: twoFAProvidersTypes = [], isLoading: isTwoFAProvidersTypesLoading } = useFetchOne(
    get2FAProviderTypesCallback
  )

  const { isLoading: isTwoFAProvidersLoading } = useFetchOne(fetch2FAProviders)

  const fetch2FAProviderActionTypes = useCallback(async () => {
    return await apiClient.get2FAProviderActionTypes()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: twoFAProvidersActionTypes = [], isLoading: isTwoFAProvidersActionTypesLoading } =
    useFetchOne(fetch2FAProviderActionTypes)

  useEffect(() => {
    if (location.state?.isProviderEnabled && location.state?.previousActive2FAProviders) {
      const hasNoPreviousActiveProviders = location.state.previousActive2FAProviders.length === 0

      if (hasNoPreviousActiveProviders) {
        setPreviousActive2FAProviders(activeProviders)
        handleInitialShowRecoveryCodes()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, activeProviders])

  const handleCloseGoogleAuthenticatorModal = () => {
    setTwoFactorGoogleAuthenticatorModal(false)
    setGoogleAuthenticatorError(false)
    setGoogleAuthenticatorData(null)
    setSelectedProvider(undefined)
  }

  const getGoogleAuthenticatorData = async () => {
    try {
      const payload = { typeId: TwoFAProviderEnum.GoogleAuthenticator }
      setIsGoogleAuthenticatorDataLoading(true)
      const result: GoogleAuthenticator2FAProviderDataDto =
        await apiClient.enableGoogleAuthenticator2FAProviders(payload)
      result && setGoogleAuthenticatorData(result)
    } catch (err: ResponseError | any) {
      console.error(err)
      handleCloseGoogleAuthenticatorModal()
    } finally {
      setIsGoogleAuthenticatorDataLoading(false)
    }
  }

  const clearSessionAndLogout = async () => {
    logout()
    navigate('/logout')
  }

  const onClosePasswordModal = async () => {
    setPasswordModal(false)
    await clearSessionAndLogout()
  }

  const onProviderToggle = (provider: NameDto, turnOn: boolean) => {
    if (!turnOn) {
      setTurnOffConfirmationModal(true)
    } else {
      switch (provider.id) {
        case TwoFAProviderEnum.GoogleAuthenticator:
          getGoogleAuthenticatorData()
          setTwoFactorGoogleAuthenticatorModal(true)
          break
        case TwoFAProviderEnum.Email:
          handle2FAProviderSelect(true, provider)
          break
        case TwoFAProviderEnum.SMS:
          const [verifiedPhone] = verifiedPhones || []
          if (verifiedPhone) {
            setSelectedPhone(verifiedPhone.countryCode + verifiedPhone.number)
          }
          setSelectPhoneModal(true)
          break
        default:
          break
      }
    }
  }

  const onAuthActionsProviderToggle = async (turnOn: boolean, authAction?: NameDto) => {
    const selectedAuthActionType = selectedAuthAction ?? authAction
    const isGoogleAuthProviderActive = activeProviders.find(
      (activeProvider) => activeProvider.type.id === TwoFAProviderEnum.GoogleAuthenticator
    )

    try {
      if (selectedAuthActionType) {
        const payload = { actionTypeId: selectedAuthActionType.id }
        const response = turnOn
          ? await apiClient.enable2FAProvidersActionType(payload)
          : await apiClient.disable2FAProvidersActionType(payload)

        if (response && response.twoFactor) {
          const hasTwoFactor = response.twoFactor.length > 0

          if (hasTwoFactor) {
            const authMediumFallback = isGoogleAuthProviderActive
              ? t('Profile.Google Authenticator app')
              : ''

            navigate('/2fa/confirm', {
              state: {
                twoFactorAuthData: {
                  twoFactorAuthEvent: turnOn
                    ? TwoFactorAuthEvents.EnableAuthAction
                    : TwoFactorAuthEvents.DisableAuthAction,
                  twoFactor: isGoogleAuthProviderActive
                    ? [
                        {
                          ...response.twoFactor[0],
                          authMedium: authMediumFallback,
                        },
                      ]
                    : response.twoFactor,
                  actionTypeId: selectedAuthActionType.id,
                },
              },
            })
          }
        } else {
          fetch2FAProviders()
        }
      }
    } catch (error: unknown) {
      setInfoModalData({ text: '', isVisible: false })
      const isErrorCaught = handleBaseTwoFactorErrors(error, navigate)
      if (!isErrorCaught) {
        throw error
      }
    } finally {
      setSelectedAuthAction(undefined)
    }
  }

  const get2FAProviderAuthMediumFallback = (id: number) => {
    const isSMS2FAProvider = id === TwoFAProviderEnum.SMS && selectedPhone
    const isGoogleAuthProvider = id === TwoFAProviderEnum.GoogleAuthenticator

    if (isSMS2FAProvider) {
      return selectedPhone
    } else if (isGoogleAuthProvider) {
      return t('Profile.Google Authenticator app')
    } else {
      return email
    }
  }

  const handleSubmitChangePassword = async (values: ChangePasswordFormValues) => {
    try {
      const response = await apiClient.updatePassword(values)
      const hasTwoFactor = (response?.twoFactor?.length ?? 0) > 0

      if (hasTwoFactor) {
        const googleAuthProvider = response.twoFactor?.find(
          (twoFactor) => twoFactor?.provider?.id === TwoFAProviderEnum.GoogleAuthenticator
        )

        const authMedium = googleAuthProvider
          ? get2FAProviderAuthMediumFallback(googleAuthProvider.provider.id)
          : response.twoFactor?.[0]?.authMedium

        navigate('/2fa/confirm', {
          state: {
            twoFactorAuthData: {
              twoFactorAuthEvent: TwoFactorAuthEvents.ChangePassword,
              twoFactor: googleAuthProvider
                ? [{ ...response.twoFactor?.[0], authMedium }]
                : response.twoFactor,

              email: response.email,
              password: values.currentPassword,
              newPassword: values.newPassword,
            },
          },
        })
      } else {
        addSnackbar.success({ message: t('Password has been changed') })
        setPasswordModal(true)
      }
    } catch (error: unknown) {
      const isErrorCaught = handleBaseTwoFactorErrors(error, navigate)
      if (!isErrorCaught) {
        throw error
      }
    }
  }

  const handleProviderToggle = async (data: Change2FAProvidersData, isEnabled: boolean) => {
    const isSMS2FAProvider = data.type.id === TwoFAProviderEnum.SMS && selectedPhone
    const authMediumFallback = get2FAProviderAuthMediumFallback(data.type.id)

    try {
      const response = isEnabled
        ? await apiClient.enable2FAProviders({
            typeId: data.type.id,
            ...(isSMS2FAProvider && selectedPhone && { authMedium: selectedPhone }),
          })
        : await apiClient.disable2FAProviders({
            typeId: data.type.id,
            ...(isSMS2FAProvider && selectedPhone && { authMedium: selectedPhone }),
          })

      if (isEnabled && 'data' in response) {
        const twoFactorAuthData = {
          twoFactorAuthEvent: TwoFactorAuthEvents.EnableProvider,
          twoFactor: [response?.data],
          previousActive2FAProviders: previousActive2FAProviders,
        }

        navigate('/2fa/confirm', {
          state: {
            twoFactorAuthData,
          },
        })
      } else if ('twoFactor' in response) {
        const targetTwoFactorProvider = response?.twoFactor?.find(
          (twoFactor) => twoFactor.provider.id === data.type.id
        )
        const remainingActiveTwoFactorProviders = response?.twoFactor?.filter(
          (twoFactor) => twoFactor.provider.id !== data.type.id
        )

        // Move target (selected) provider to the 1st index to make sure correct ID is passed to the request
        // in case if there are multiple providers active (Email + SMS)
        const rearrangedTwoFactors = targetTwoFactorProvider
          ? [targetTwoFactorProvider, ...remainingActiveTwoFactorProviders]
          : response.twoFactor

        const isGoogleAuthProviderActive = activeProviders.find(
          (activeProvider) => activeProvider.type.id === TwoFAProviderEnum.GoogleAuthenticator
        )

        const twoFactorAuthData = {
          twoFactorAuthEvent: TwoFactorAuthEvents.DisableProvider,
          twoFactor: isGoogleAuthProviderActive
            ? [{ ...rearrangedTwoFactors[0], authMedium: authMediumFallback }]
            : rearrangedTwoFactors,
        }

        navigate('/2fa/confirm', {
          state: {
            twoFactorAuthData,
          },
        })
      }
    } catch (error: unknown) {
      setInfoModalData({ text: '', isVisible: false })

      const isErrorCaught = handleBaseTwoFactorErrors(error, navigate)
      if (!isErrorCaught) {
        throw error
      }
    } finally {
      setSelectedProvider(undefined)
    }
  }

  const handleInitialShowRecoveryCodes = async () => {
    setGenerateRecoveryCodesModal(true)
  }

  const onGoogleAuthenticatorFormModalSubmit = async (
    values: FormSubmitValues<GoogleAuthenticatorModalFormValues>
  ) => {
    if (selectedProvider?.id && values.verificationCode) {
      const payload: VerifyFirstTimeUseData = {
        typeId: selectedProvider.id,
        code: values.verificationCode,
      }

      try {
        setIsGoogleAuthenticatorDataLoading(true)
        await apiClient.verify2FACodesFirstTimeUse(payload)
        await fetch2FAProviders()
        handleCloseGoogleAuthenticatorModal()
        navigate('.', { state: { isProviderEnabled: true, previousActive2FAProviders } })
      } catch (err: ResponseError | any) {
        setGoogleAuthenticatorError(true)
        console.error(err)
      } finally {
        setIsGoogleAuthenticatorDataLoading(false)
      }
    }
  }

  const handle2FAProviderSelect = async (is2FAProviderTurnedOn: boolean, provider?: NameDto) => {
    const selectedType = selectedProvider ?? provider

    if (selectedType) {
      const payload = { type: selectedType }

      handleProviderToggle(payload, is2FAProviderTurnedOn)
    }
    return
  }

  const isLoading =
    isTwoFAProvidersTypesLoading || isTwoFAProvidersLoading || isTwoFAProvidersActionTypesLoading

  const isTwoFactorProvidersDataProvided =
    isTwoFactorFunctionalityEnabled &&
    twoFAProvidersTypes?.length > 0 &&
    twoFAProvidersActionTypes?.length > 0

  return (
    <Loading isLoading={isLoading} showLoadingIcon>
      <SecuritySettingsInfoModal
        isOpen={infoModalData.isVisible}
        onClose={() => setInfoModalData({ text: '', isVisible: false })}
        text={infoModalData.text}
      />
      <TwoFactorAuthenticationInfoModal
        isOpen={twoFactorAuthenticationInfoModal}
        close={() => setTwoFactorAuthenticationInfoModal(false)}
      />
      <PasswordInfoModal isOpen={passwordModal} close={onClosePasswordModal} />
      <GoogleAuthenticatorFormModal
        isOpen={twoFactorGoogleAuthenticatorModal}
        close={handleCloseGoogleAuthenticatorModal}
        onSubmit={onGoogleAuthenticatorFormModalSubmit}
        isError={googleAuthenticatorError}
        data={googleAuthenticatorData}
        isLoading={isGoogleAuthenticatorDataLoading}
      />
      {!!verifiedPhones?.length && (
        <TwoFactorAuthenticationSelectPhoneModal
          isOpen={selectPhoneModal}
          close={() => {
            setSelectedProvider(undefined)
            setSelectPhoneModal(false)
          }}
          selectedPhone={selectedPhone}
          selectPhone={setSelectedPhone}
          phoneNumbers={verifiedPhones}
          onConfirm={() => {
            setSelectPhoneModal(false)
            handle2FAProviderSelect(true)
          }}
        />
      )}
      <ConfirmTurnOffTwoFactorAuthenticationModal
        onConfirm={() => {
          setTurnOffConfirmationModal(false)
          if (selectedProvider) {
            handle2FAProviderSelect(false)
          } else {
            onAuthActionsProviderToggle(false)
          }
        }}
        close={() => {
          setSelectedProvider(undefined)
          setSelectedAuthAction(undefined)
          setTurnOffConfirmationModal(false)
        }}
        isOpen={turnOffConfirmationModal}
      />
      <TwoFactorAuthenticationGenerateRecoveryCodesModal
        close={() => setGenerateRecoveryCodesModal(false)}
        isOpen={generateRecoveryCodesModal}
      />

      <div className={styles.tabContainer}>
        <div className={styles.tabHeaders}>
          <TextH3>{t('Profile.Security Settings')}</TextH3>
          <div className={styles.tabButtonsContainer}>
            {isTwoFactorProvidersDataProvided && (
              <Button
                appearance='plain'
                className={`${styles.tabButton} ${activeTab === '2fa' ? styles.active : ''}`}
                onClick={() => setActiveTab('2fa')}
              >
                <Text>
                  {isMobile ? t('TwoFactorAuth.2FA') : t('TwoFactorAuth.Two-factor authentication')}
                </Text>
              </Button>
            )}
            <Button
              appearance='plain'
              className={`${styles.tabButton} ${activeTab === 'password' ? styles.active : ''}`}
              onClick={() => setActiveTab('password')}
            >
              <Text>{t('Profile.Password Change')}</Text>
            </Button>
          </div>
        </div>
        <div className={styles.tabContent}>
          {activeTab === '2fa' && isTwoFactorProvidersDataProvided && (
            <AccountSettingsBox
              infoToggle={() => setTwoFactorAuthenticationInfoModal(true)}
              renderTitle={() => <TextH2>{t('TwoFactorAuth.Two-factor authentication')}</TextH2>}
            >
              <TwoFactorAuthentication
                setInfoModalData={setInfoModalData}
                onPhoneNumberChange={(phone, provider) => {
                  setSelectedPhone(phone)
                  setSelectedProvider(provider)
                  setSelectPhoneModal(true)
                }}
                providers={twoFAProvidersTypes}
                activeProviders={activeProviders}
                twoFAProvidersActionTypes={twoFAProvidersActionTypes}
                onProviderToggle={(provider, turnOn) => {
                  setSelectedProvider(provider)
                  onProviderToggle(provider, turnOn)
                }}
                onAuthActionsProviderToggle={(authAction, turnOn) => {
                  if (turnOn) {
                    onAuthActionsProviderToggle(true, authAction)
                  } else {
                    setSelectedAuthAction(authAction)
                    setTurnOffConfirmationModal(true)
                  }
                }}
                onGenerateRecoveryCodes={() => setGenerateRecoveryCodesModal(true)}
                account={account}
                isTwoFAProvidersLoading={isTwoFAProvidersLoading}
              />
            </AccountSettingsBox>
          )}
          {activeTab === 'password' && (
            <AccountSettingsBox title={t('Profile.Password Change')}>
              <ChangePasswordForm onSubmit={handleSubmitChangePassword} />
            </AccountSettingsBox>
          )}
        </div>
      </div>
    </Loading>
  )
}
