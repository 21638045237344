import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import classNames from 'classnames'

import { Loading } from '../global/Loading/Loading'
import { ActionIcon } from '../global/actionIcon/ActionIcon'
import { Button } from '../global/button/Button'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { SelectModalOption } from '../global/field/SelectModal'
import { WalletModalItem, WalletRestrictionsModal } from '../global/modal/WalletRestrictionsModal'
import { FormTemplate } from '../global/templates/FormTemplate'
import { useFormatNumber } from '../hooks/useFormatNumber'
import { CircleForwardIcon } from '../icons/CircleForwardIcon'
import { DropArrowDownIcon } from '../icons/DropArrowDownIcon'
import { TickmillBackgroundIcon } from '../icons/TickmillBackgroundIcon'
import { WalletIcon } from '../icons/WalletIcon'
import PSPLogo from '../images/online-banking-icon-48x48px.png'
import { AccountGroupType } from '../model/AccountGroupType'
import { CampaignStatusEnum } from '../model/CampaignStatusEnum'
import { PlatformTypeEnum } from '../model/PlatformTypeEnum'
import { WalletDto, WalletTypeEnum } from '../model/WalletDto'
import { WalletPaymentProvider } from '../model/WalletPaymentProvider'
import { TextSmall, TextStrong, TextTiny } from '../ui/Typography/Typography'
import { Operator, useApiClient } from '../utils/ApiClient'
import { useWalletsReadContext } from '../utils/UserWalletContext'
import { ClientApiClient } from '../utils/clientApi'
import { useWindowResize } from '../utils/domUtils'
import { useFetchOne } from '../utils/useFetch'
import { useScrollToTop } from '../utils/useScrollToTop'
import { WalletRestrictions } from '../utils/wallet.utils'

import styles from './FirstDepositPage.module.scss'

type DepositToType = { name: string; id: string }
const CAMPAIGN_ACCOUNT = 'Campaign Account'

export const FirstDepositPage: FC<{ walletType: WalletTypeEnum }> = ({ walletType }) => {
  useScrollToTop()

  const locale = useSessionLanguage()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const apiClient = useApiClient(ClientApiClient)
  const [depositModalOption, setDepositModalOption] = useState<DepositToType>()
  const [selectedDepositOption, setSelectedDepositOption] = useState<DepositToType>()
  const { wallets = [] } = useWalletsReadContext()
  const isMobile = useWindowResize()

  const tradingAccountsCallback = useCallback(
    () =>
      apiClient.getTradingAccounts({
        search: {
          Campaign: {
            value: true,
            operator: Operator.IS_NOT_EMPTY,
          },
          AccountGroupType: {
            value: AccountGroupType.Live,
            operator: Operator.EQUAL,
          },
          Platform: {
            value: PlatformTypeEnum.CQGCAST,
            operator: Operator.NOT_EQUAL,
          },
          Status: {
            value: CampaignStatusEnum.Enabled,
            operator: Operator.EQUAL,
          },
        },
      }),
    [apiClient]
  )

  const { data: tradingAccounts, isLoading: isTradingAccountsLoading } =
    useFetchOne(tradingAccountsCallback)

  const defaultWallet = wallets.find((wallet) => wallet.isDefault) || wallets[0]

  const campaignAccount = useMemo(() => {
    return tradingAccounts?.items.find((x) => !!x.campaigns.length)
  }, [tradingAccounts])

  useEffect(() => {
    if (campaignAccount) {
      setSelectedDepositOption({ id: campaignAccount.id, name: CAMPAIGN_ACCOUNT })
    } else if (defaultWallet) {
      setSelectedDepositOption({ id: defaultWallet.id, name: defaultWallet.name })
    } else if (wallets.length > 0) {
      setSelectedDepositOption({ id: wallets[0].id, name: wallets[0].name })
    }
  }, [campaignAccount, defaultWallet, wallets])

  const walletDepositMethodsCallback = useCallback(async () => {
    const depositMethodWalletId =
      selectedDepositOption?.name === CAMPAIGN_ACCOUNT && campaignAccount
        ? campaignAccount.wallet.id
        : selectedDepositOption?.id

    if (depositMethodWalletId) {
      return apiClient.getWalletDepositMethods(locale, depositMethodWalletId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignAccount, selectedDepositOption, locale])

  const { data: walletPaymentProviders = [], isLoading: isWalletDepositMethodsLoading } =
    useFetchOne(walletDepositMethodsCallback)

  const setHasDismissedFirstDepositPrompt = () =>
    localStorage.setItem('hasDismissedFirstDepositPrompt', 'true')

  const handleGoBack = () => {
    setHasDismissedFirstDepositPrompt()
    navigate('dashboard/traders-room/wallets')
  }

  const closeWalletModal = () => setDepositModalOption(undefined)

  const handleWalletSelection = (depositTo: DepositToType) => {
    setSelectedDepositOption(depositTo)
    setDepositModalOption(undefined)
  }

  const handleSelectOption = (depositTo: DepositToType) => {
    setDepositModalOption(depositTo)
  }

  const WalletButton = () => {
    const hasMultipleDepositChoices =
      (wallets?.length > 0 && campaignAccount) || (wallets?.length > 1 && !campaignAccount)

    return selectedDepositOption ? (
      <Button
        type='button'
        onClick={() => hasMultipleDepositChoices && setDepositModalOption(selectedDepositOption)}
        renderLeftIcon={() => <WalletIcon color='white' />}
        renderRightIcon={
          hasMultipleDepositChoices ? () => <DropArrowDownIcon color='alwaysWhite' /> : undefined
        }
        appearance='primary'
        state={hasMultipleDepositChoices ? undefined : 'static'}
        className={styles.walletActionButton}
      >
        {selectedDepositOption?.name ?? t('Wallet.Deposit To')}
      </Button>
    ) : null
  }

  const handleDeposit = async (walletPaymentProvider: WalletPaymentProvider) => {
    if (selectedDepositOption?.name === CAMPAIGN_ACCOUNT) {
      setHasDismissedFirstDepositPrompt()
      navigate(
        `/dashboard/traders-room/trading-accounts/${selectedDepositOption.id}/deposit-to-campaign`,
        { state: { walletPaymentProvider } }
      )
    } else if (selectedDepositOption) {
      setHasDismissedFirstDepositPrompt()
      navigate(`/dashboard/traders-room/wallets/${selectedDepositOption.id}/deposit`, {
        state: { walletPaymentProvider },
      })
    } else {
      navigate('/dashboard/traders-room/wallets')
    }
  }

  return (
    <Loading isLoading={isTradingAccountsLoading} showLoadingIcon>
      {depositModalOption && (
        <WalletRestrictionsModal
          title={campaignAccount ? t('Wallet.Deposit To') : t('Choose Wallet')}
          render={({ items: wallets }) => {
            const depositOptions = [
              ...(campaignAccount ? [{ name: CAMPAIGN_ACCOUNT, id: campaignAccount.id }] : []),
              ...wallets,
            ]

            return depositOptions.map((depositOption) => {
              if (depositOption.name === CAMPAIGN_ACCOUNT) {
                return (
                  <SelectModalOption
                    value={depositOption.name === depositModalOption.name}
                    label={CAMPAIGN_ACCOUNT}
                    onClick={() => handleSelectOption(depositOption)}
                  />
                )
              } else {
                return (
                  <WalletModalItem
                    item={depositOption as WalletDto}
                    restrictionType={WalletRestrictions.DEPOSIT}
                    selected={depositOption.name === depositModalOption.name}
                    onSelectOption={handleSelectOption}
                    hideHint
                  />
                )
              }
            })
          }}
          onClose={closeWalletModal}
          onConfirm={() => handleWalletSelection(depositModalOption)}
        />
      )}

      <FormTemplate
        title={t('Ready to Start Trading')}
        goBack={handleGoBack}
        contentClassName={styles.content}
        renderIcon={wallets?.length && !isMobile && <WalletButton />}
        subtitle={isMobile && <WalletButton />}
      >
        <div className={styles.info}>
          <TextSmall className={styles.semiTransparent}>
            {t(
              'Step into the world of trading by funding your wallet. We offer a range of options to select the one that suits you best. Make your first deposit today and start navigating the financial markets'
            )}
          </TextSmall>
        </div>

        <Loading isLoading={isWalletDepositMethodsLoading} showLoadingIcon>
          <div className={styles.depositMethodWrapper}>
            {walletPaymentProviders?.map((walletPaymentProvider) => (
              <DepositMethod
                onSetHasDismissedFirstDepositPrompt={setHasDismissedFirstDepositPrompt}
                handleDeposit={handleDeposit}
                walletPaymentProvider={walletPaymentProvider}
                key={walletPaymentProvider.method.id}
              />
            ))}
          </div>
        </Loading>
      </FormTemplate>
      <div className='is-hidden-mobile'>
        <div className={classNames(styles.backgroundIcon, styles.activatedBackgroundIconLeft)}>
          <TickmillBackgroundIcon />
        </div>
        <div className={classNames(styles.backgroundIcon, styles.activatedBackgroundIconRight)}>
          <TickmillBackgroundIcon />
        </div>
      </div>
    </Loading>
  )
}

interface DepositMethodProps {
  walletPaymentProvider: WalletPaymentProvider
  onSetHasDismissedFirstDepositPrompt(isDismissed: boolean): void
  handleDeposit: (walletPaymentProvider: WalletPaymentProvider) => void
}

const DepositMethod: FC<DepositMethodProps> = ({ walletPaymentProvider, handleDeposit }) => {
  const { formatMoney } = useFormatNumber()
  const { t } = useTranslation()
  const [isHovered, setIsHovered] = useState(false)

  const handleClick = () => handleDeposit(walletPaymentProvider)

  const handleHover = () => {
    setIsHovered((prevState) => !prevState)
  }

  return (
    <div
      className={classNames('has-cursor-pointer', styles.depositMethod)}
      onMouseOver={handleHover}
      onMouseOut={handleHover}
      onClick={handleClick}
    >
      <div className='is-flex is-align-items-center mb-2'>
        <div className={classNames(styles.logoWrapper)}>
          <img src={walletPaymentProvider.logo || PSPLogo} alt={walletPaymentProvider.name} />
        </div>

        <TextStrong>{walletPaymentProvider.description}</TextStrong>
      </div>
      <div>
        <TextTiny>
          {t('Processing Time')} {walletPaymentProvider.fundingTime}
        </TextTiny>
        <br />
        <TextTiny>
          {t('Fee')} {formatMoney(walletPaymentProvider.fee, walletPaymentProvider.currency.id)}
        </TextTiny>
        <br />
        <TextTiny>
          {t('Wallet.Minimum deposit')}{' '}
          {formatMoney(walletPaymentProvider.minAmount, walletPaymentProvider.currency.id)}
        </TextTiny>
        <br />
        {walletPaymentProvider.regulator && <TextTiny>{walletPaymentProvider.regulator}</TextTiny>}
      </div>

      <div className='has-text-right mt-2'>
        <ActionIcon
          className='p-0'
          onClick={handleClick}
          renderOnClick={() => <CircleForwardIcon color='text' />}
        >
          {isHovered ? <CircleForwardIcon inverse secondaryColor='text' /> : <CircleForwardIcon />}
        </ActionIcon>
      </div>
    </div>
  )
}
