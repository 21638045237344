import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { SignupFormValues, useSignup } from '../../../../global/context/SignupContext'
import { AccountType } from '../../../../model/AccountType'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { isNumber } from '../../../../utils/validations'
import { useCreateClient } from '../../hooks/useCreateClient'
import { FormValuesLegalDocs } from './PersonalDetailsDocumentsFactory'
import { PersonalDetailsStep1Page } from './PersonalDetailsStep1/PersonalDetailsStep1Page'
import { PersonalDetailsStep2Page } from './PersonalDetailsStep2/PersonalDetailsStep2Page'
import { PersonalDetailsStep3Page } from './PersonalDetailsStep3/PersonalDetailsStep3Page'
import { PersonalDetailsStep4Page } from './PersonalDetailsStep4/PersonalDetailsStep4Page'
import { PersonalDetailsStep5FormValues } from './PersonalDetailsStep5/PersonalDetailsStep5Form'
import { PersonalDetailsStep5Page } from './PersonalDetailsStep5/PersonalDetailsStep5Page'
import { PersonalDetailsStep6Page } from './PersonalDetailsStep6/PersonalDetailsStep6Page'

export const PersonalDetailsOrchestrator: React.FC = () => {
  const { passedStep } = useParams<{ passedStep: string }>()
  const navigate = useNavigate()
  const { appendValues, signupData } = useSignup()
  const apiClient = useApiClient(ClientApiClient)
  const { denormalize, signInAndMoveToFinancialInfo } = useCreateClient()

  const [subStep, setSubStep] = useState(Number(passedStep ?? 1) ?? 1)

  const totalSteps = signupData.totalSteps.personalDetails

  const nextStep = async (values?: Partial<SignupFormValues>) => {
    if (values) {
      await appendValues(values)
    }

    if (values?.isUsCitizen) {
      await appendValues({ ...signupData.values, isUsCitizen: undefined })
      return navigate('/sign-up/personal-details/us-citizen')
    }
    return setSubStep(subStep + 1)
  }

  useEffect(() => {
    if (passedStep) {
      if (!isNumber(passedStep) || Number(passedStep) > totalSteps) {
        setSubStep(1)
      }
    }
  }, [passedStep, totalSteps])

  const goBack = () => setSubStep(subStep - 1)

  const standardValues = (values: FormValuesLegalDocs) =>
    ({
      ...signupData.values,
      agreedLegalDocuments: values?.agreedLegalDocuments || [],
      marketDataClassificationId: values?.readDocumentMarketData?.marketDataClassificationId,
      shouldReceiveNewsletter: signupData.lead?.optIn,
    } as SignupFormValues)

  const expeditValues = (values: PersonalDetailsStep5FormValues) =>
    ({
      ...signupData.values,
      ...values,
      shouldReceiveNewsletter: signupData.lead?.optIn,
    } as SignupFormValues)

  const handleSubmit = async (data: SignupFormValues) => {
    appendValues(data, true)
    if (!signupData.lead) {
      return navigate('/sign-up/personal-details/error')
    }
    if (signupData.lead.type.id === AccountType.Individual) {
      await apiClient.createClient(denormalize(signupData.lead, data))
      await signInAndMoveToFinancialInfo(data)
      localStorage.removeItem('lead')
    } else {
      navigate('/sign-up/company-details/')
    }
  }

  switch (subStep) {
    case 1:
      return (
        <PersonalDetailsStep1Page
          step={subStep}
          totalSteps={totalSteps}
          onSubmit={nextStep}
          goBack={() => navigate('/sign-up', { state: { keepValues: true } })}
        />
      )
    case 2:
      return (
        <PersonalDetailsStep2Page
          step={subStep}
          totalSteps={totalSteps}
          onSubmit={nextStep}
          goBack={goBack}
        />
      )
    case 3:
      return <PersonalDetailsStep3Page step={subStep} totalSteps={totalSteps} goBack={goBack} />
    case 4:
      return (
        <PersonalDetailsStep4Page
          step={subStep}
          totalSteps={totalSteps}
          onSubmit={nextStep}
          goBack={goBack}
        />
      )

    case 5:
      return (
        <PersonalDetailsStep5Page
          step={subStep}
          totalSteps={totalSteps}
          onSubmit={(values) =>
            totalSteps < 6 ? handleSubmit(expeditValues(values)) : nextStep(values)
          }
          goBack={goBack}
        />
      )
    case 6:
      return (
        <PersonalDetailsStep6Page
          totalSteps={totalSteps}
          goBack={goBack}
          step={subStep}
          onStepChange={setSubStep}
          onSubmit={(values) => handleSubmit(standardValues(values))}
        />
      )
  }
}
