import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Loading } from '../global/Loading/Loading'
import { Paging } from '../global/Paging/Paging'
import { BackButton } from '../global/backButton/BackButton'
import { Button } from '../global/button/Button'
import { useProductReadContext } from '../global/context/ProductContext'
import { useSessionLanguage } from '../global/context/SessionSettingsContext'
import { defineMiniTab } from '../global/tabs/Tabs'
import {
  ScrollToIds,
  useScrollAfterLoad,
  useScrollIntoViewOnPagingEntriesChange,
} from '../hooks/useScrollToElementIds'
import { Notification, getNotificationSearchQueryById } from '../model/Notification'
import { NotificationPlatformEnum } from '../model/PlatformTypeEnum'
import { PageHeader } from '../ui/Table/Header/PageHeader'
import { PageQuery, useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { getDomainName } from '../utils/cookie.utils'
import { useWindowResize } from '../utils/domUtils'
import { useNotificationUnread } from '../utils/notifications'
import { useCallbackWithForceRefresh } from '../utils/useCallbackWithForceRefresh'
import { useFetchAppendablePage } from '../utils/useFetch'
import { useScrollToTop } from '../utils/useScrollToTop'
import { NotificationPreview } from './NotificationPreview'
import { NotificationsNotFound } from './NotificationsNotFound'

import styles from './NotificationCenter.module.scss'

type PaginationType = { pageIndex: number; pageSize: number; cardId: string }

export const NotificationCenter: React.FC = () => {
  const isMobile = useWindowResize()
  const { t } = useTranslation()
  const locale = useSessionLanguage()
  const navigate = useNavigate()
  const [notifications, setNotifications] = useState<Notification[]>([])
  const apiClient = useApiClient(ClientApiClient)
  const [selectedTab, setSelectedTab] = useState<string>('')
  useScrollToTop(selectedTab)
  const [removingNotification, setRemovingNotification] = useState<string>()
  const { refreshAllNotifications } = useNotificationUnread()
  const { product: tickmillProduct } = useProductReadContext()
  const [isFirstRender, setIsFirstRender] = useState(true)

  const PAGINATION_KEY = 'pagination'
  const TIMEOUT_DURATION = 2000

  useEffect(() => {
    const paginationString = localStorage.getItem(PAGINATION_KEY)

    if (paginationString) {
      const pagination = JSON.parse(paginationString) as PaginationType
      if (pagination) {
        setPageQuery!({
          ...pageQuery,
          pageIndex: pagination?.pageIndex,
          pageSize: pagination.pageSize,
        })
        setTimeout(() => {
          localStorage.removeItem(PAGINATION_KEY)
        }, TIMEOUT_DURATION)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { callback, forceRefresh } = useCallbackWithForceRefresh(
    (query?: PageQuery) =>
      apiClient
        .getNotifications({
          search: getNotificationSearchQueryById(selectedTab, {
            TickmillProduct: tickmillProduct,
            Platform: NotificationPlatformEnum.WEB,
          }),
          ...query,
          languageId: locale,
          operator: 'AND',
          domain: getDomainName(true),
        })
        .then((response) => {
          setNotifications(response.items)
          setIsFirstRender(false)
          return response
        }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )

  const { meta, setPageQuery, isLoading, pageQuery } = useFetchAppendablePage(callback)

  useEffect(() => {
    if (isFirstRender) {
      return
    }
    setPageQuery!({
      ...pageQuery,
      pageIndex: 1,
      pageSize: 10,
      search: getNotificationSearchQueryById(selectedTab, {
        TickmillProduct: tickmillProduct,
        Platform: NotificationPlatformEnum.WEB,
      }),
    })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab])

  useEffect(() => {
    if (meta && !notifications.length && meta?.pageIndex !== 1) {
      setPageQuery!({ ...pageQuery, pageIndex: meta.pageIndex - 1 })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [meta, notifications])

  const openNotification = (id: string) => {
    localStorage.setItem(
      PAGINATION_KEY,
      JSON.stringify({ pageIndex: meta?.pageIndex, pageSize: meta?.pageSize, cardId: id })
    )
    navigate(`/notifications/${id}`)
  }

  const removeNotification = async (id: string) => {
    setRemovingNotification(id)
    setTimeout(async () => {
      setNotifications((notifications) =>
        notifications.filter((notification) => notification.id !== id)
      )
      await apiClient.deleteNotification(id)
      await forceRefresh()
      await refreshAllNotifications()
    }, 550)
  }

  const readAll = async () => {
    setNotifications((notifications) =>
      notifications.map((notification) => ({
        ...notification,
        isRead: true,
      }))
    )
    await apiClient.readAllNotifications(notifications.map((notification) => notification.id))
    await refreshAllNotifications()
    await forceRefresh()
  }

  const readNotification = async (notification: Notification) => {
    if (!notification.isRead) {
      setNotifications((notifications) =>
        notifications.map((n) => (n.id === notification.id ? { ...n, isRead: true } : n))
      )
      await apiClient.readNotification(notification.id)
    }
  }

  const allowReadAll = useMemo(() => {
    return notifications?.some((notification) => !notification.isRead)
  }, [notifications])

  function* getTabs() {
    const tabs = [
      [t('Notifications.tabs.All'), ''],
      [t('Notifications.tabs.General'), '1'],
      [t('Notifications.tabs.Platform'), '2'],
      [t('Notifications.tabs.Promotional'), '3'],
    ]

    for (const [label, id] of tabs) {
      yield { ...defineMiniTab(label, id) }
    }
  }

  const tabs = [...getTabs()]

  const [isPaginationEntrySelected, setIsPaginationEntrySelected] = useState(false)
  useScrollIntoViewOnPagingEntriesChange(
    ScrollToIds.NotificationCenterHeader,
    isPaginationEntrySelected,
    isLoading,
    setIsPaginationEntrySelected
  )

  const getCardId = () => {
    const pagination = localStorage.getItem(PAGINATION_KEY)
    if (pagination) {
      const { cardId } = JSON.parse(pagination) as PaginationType
      return cardId
    }
    return ScrollToIds.NotificationCenterItemMiddle
  }
  useScrollAfterLoad(getCardId(), isLoading, meta?.pageSize, !!localStorage.getItem(PAGINATION_KEY))

  return (
    <div className={styles.notifications}>
      <div className={classNames(styles.backButton)}>
        <BackButton section={t('Notifications.Notification Centre')} className='is-uppercase' />
      </div>
      <PageHeader
        id={ScrollToIds.NotificationCenterHeader}
        backButton={isMobile ? () => navigate('/') : undefined}
        actions={[
          {
            disabled: !allowReadAll,
            hidden: isMobile,
            onClick: () => readAll(),
            label: t('Notifications.Mark All Read'),
          },
        ]}
        tabsControl={{
          setSelectedTab,
          selectedTab,
          tabs,
        }}
        className={styles.header}
        title={t('Notifications.Notifications')}
      />
      {allowReadAll && (
        <Button
          appearance='plain'
          className={styles.readAll}
          onClick={readAll}
          data-test='notificationcenter-readall'
        >
          {t('Notifications.Mark All Read')}
        </Button>
      )}

      <Loading showLoadingIcon isLoading={isLoading}>
        {notifications.length === 0 ? (
          <NotificationsNotFound />
        ) : (
          notifications?.map((notification, index) => (
            <NotificationPreview
              className={classNames({
                [styles.removing]: removingNotification === notification.id,
              })}
              cardId={notification.id}
              key={notification.id}
              read={() => readNotification(notification)}
              open={() => openNotification(notification.id)}
              remove={() => removeNotification(notification.id)}
              notification={notification}
              showCloseModal={true}
            />
          ))
        )}
      </Loading>

      {meta && (
        <Paging
          scrollToHeaderId={ScrollToIds.NotificationCenterHeader}
          pageData={meta}
          isLoading={isLoading}
          onPageChanged={(pageIndex, pageSize) => {
            setIsPaginationEntrySelected(true)
            setPageQuery!({
              ...pageQuery,
              pageIndex,
              pageSize,
            })
          }}
        />
      )}
    </div>
  )
}
