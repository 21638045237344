import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { ExternalLink } from '../../global/ExternalLink'
import { Button } from '../../global/button/Button'
import { CheckBox } from '../../global/checkBox/CheckBox'
import { SelectField } from '../../global/field/SelectField'
import { TextField } from '../../global/field/TextField'
import { RadioButton } from '../../global/radioButton/RadioButton'
import { OldLegalDocumentDto } from '../../model/LegalDocumentDto'
import {
  FreeTextAnswer,
  QuestionDto,
  TestQuestionWidgetType,
  TestSectionAnswerDto,
  TestSectionQuestionDto,
  TestSectionsDto,
} from '../../model/TestSectionsDto'
import { getOptions } from '../../ui/TestFields/TestFields'
import { TextStrong, TextTiny } from '../../ui/Typography/Typography'
import { getDocFromTest } from '../../utils/ibTests'
import { isOne } from '../../utils/validations'

import styles from './IBQuestionnaire.module.scss'

interface IBQuestionnaireProps {
  onSubmit: (selectedAnswers: string[], freeAnswers: FreeTextAnswer[]) => void
  testData: TestSectionsDto
  legalDocuments: OldLegalDocumentDto[]
  isMultiTier: boolean
}

export const IBQuestionnaire: React.FC<IBQuestionnaireProps> = (props) => {
  const { onSubmit, testData, legalDocuments, isMultiTier } = props

  const [selectedAnswers, setSelectedAnswers] = useState<string[]>([])
  const [freeAnswers, setFreeAnswers] = useState<FreeTextAnswer[]>([])
  const [showErrors, setShowErrors] = useState(false)

  const { t } = useTranslation()
  const errors = useValidate(testData, selectedAnswers, freeAnswers)

  const buildTestQuestion = (question: QuestionDto) => {
    const widgetName = question.widgetName || ''
    const docUrl = legalDocuments.find(({ code }) => code === getDocFromTest(widgetName))?.url
    if (docUrl) {
      return <ExternalLink url={docUrl}>{question.name}</ExternalLink>
    }
    return <span>{question.name}</span>
  }

  const handlePickOption = (answerId: string, questionAnswers: TestSectionAnswerDto[]) => {
    if (selectedAnswers.includes(answerId)) {
      return selectedAnswers
    }
    const answerdIds = questionAnswers.map((answer) => answer.id)
    const cleanedValues = selectedAnswers.filter((value) => !answerdIds.includes(value)) ?? []
    cleanedValues.push(answerId)
    return cleanedValues
  }

  const handleMultiSelect = (answerId: string) => {
    if (selectedAnswers.includes(answerId)) {
      return selectedAnswers.filter((value) => value !== answerId)
    } else {
      return [...selectedAnswers, answerId]
    }
  }

  const handleCheckbox = (testQuestionId: string) => {
    const existingAnswer = freeAnswers.find(({ testQuestionId: id }) => id === testQuestionId)
    if (existingAnswer) {
      if (isOne(existingAnswer.answer)) {
        const updatedAnswers = freeAnswers.filter(({ testQuestionId: id }) => id !== testQuestionId)
        setFreeAnswers([...updatedAnswers, { testQuestionId, answer: 0 }])
      } else {
        const updatedAnswers = freeAnswers.filter(({ testQuestionId: id }) => id !== testQuestionId)
        setFreeAnswers([...updatedAnswers, { testQuestionId, answer: 1 }])
      }
    } else {
      setFreeAnswers([...freeAnswers, { testQuestionId, answer: 1 }])
    }
  }

  const handleFreeText = (testQuestionId: string, answer: string | null | number) => {
    const existingAnswer = freeAnswers.find(({ testQuestionId: id }) => id === testQuestionId)
    if (existingAnswer) {
      const updatedAnswers = freeAnswers.filter(({ testQuestionId: id }) => id !== testQuestionId)
      setFreeAnswers([...updatedAnswers, { testQuestionId, answer }])
    } else {
      setFreeAnswers([...freeAnswers, { testQuestionId, answer }])
    }
  }

  const agreementBoxes: JSX.Element[] = []

  return (
    <div className={styles.ibQuestionnaire}>
      {testData.sections.map(({ questions }) =>
        questions
          .filter(({ widget }) =>
            showConditionalQuestions(questions, selectedAnswers, widget.options)
          )
          .map(({ question, answers }) => {
            switch (question.widgetType) {
              case TestQuestionWidgetType.Radio:
                return (
                  <QuestionBox
                    key={question.id}
                    showErrors={showErrors}
                    error={errors[question.id]}
                    label={question.name}
                  >
                    {answers.map((answer) => (
                      <div className={styles.selectorBox} key={answer.id}>
                        <RadioButton
                          name={'questionnaire'}
                          onClick={() => setSelectedAnswers(handlePickOption(answer.id, answers))}
                          label={answer.name}
                          checked={selectedAnswers.includes(answer.id)}
                        />
                      </div>
                    ))}
                  </QuestionBox>
                )

              case TestQuestionWidgetType.Text:
              case TestQuestionWidgetType.ShortText:
                return (
                  <QuestionBox
                    key={question.id}
                    showErrors={showErrors}
                    error={errors[question.id]}
                    label={question.name}
                  >
                    <TextField
                      maxChatacters={255}
                      className={styles.inputField}
                      type='text'
                      value={
                        freeAnswers.find(({ testQuestionId }) => testQuestionId === question.id)
                          ?.answer
                      }
                      onChange={(value) => handleFreeText(question.id, value)}
                    />
                  </QuestionBox>
                )

              case TestQuestionWidgetType.Select:
                return (
                  <QuestionBox
                    key={question.id}
                    showErrors={showErrors}
                    error={errors[question.id]}
                    label={question.name}
                  >
                    <SelectField
                      options={answers.map((answer) => ({
                        label: answer.name,
                        value: answer.id,
                      }))}
                      className={styles.inputField}
                      onChange={(v) =>
                        setSelectedAnswers(handlePickOption(v.target.value, answers))
                      }
                      value={answers.find((answer) => selectedAnswers.includes(answer.id))?.id}
                      disabled={false}
                    />
                  </QuestionBox>
                )

              case TestQuestionWidgetType.Multiselect:
                return (
                  <QuestionBox
                    key={question.id}
                    showErrors={showErrors}
                    error={errors[question.id]}
                    label={question.name}
                  >
                    {answers.map((answer) => (
                      <div className={styles.selectorBox} key={answer.id}>
                        <CheckBox
                          verticallyCentered
                          onClick={() => setSelectedAnswers(handleMultiSelect(answer.id))}
                          value={selectedAnswers.includes(answer.id)}
                        >
                          <span>{answer.name}</span>
                        </CheckBox>
                      </div>
                    ))}
                  </QuestionBox>
                )

              case TestQuestionWidgetType.Checkbox:
                agreementBoxes.push(
                  <div key={question.id} className={styles.agreementBox}>
                    <CheckBox
                      onChange={() => handleCheckbox(question.id)}
                      value={isOne(
                        freeAnswers.find(({ testQuestionId }) => testQuestionId === question.id)
                          ?.answer
                      )}
                    >
                      {buildTestQuestion(question)}
                    </CheckBox>
                    {showErrors && errors[question.id] && (
                      <TextTiny className={styles.error}>{errors[question.id]}</TextTiny>
                    )}
                  </div>
                )
                return null

              default:
                return null
            }
          })
      )}
      {agreementBoxes.length > 0 && (
        <div className={styles.agreementBoxWrapper}>{agreementBoxes}</div>
      )}
      <div className={styles.submit}>
        <Button
          appearance='primary'
          type='button'
          className={styles.submitButton}
          state={Object.values(errors).some((error) => error.length > 0) ? 'disabled' : 'normal'}
          onClick={() =>
            Object.values(errors).some((error) => error.length > 0)
              ? setShowErrors(true)
              : onSubmit(selectedAnswers, freeAnswers)
          }
          size='L'
        >
          {isMultiTier ? t('Submit') : t('IB.Become an Introducing Broker')}
        </Button>
      </div>
    </div>
  )
}

const showConditionalQuestions = (
  questions: TestSectionQuestionDto[],
  selectedAnswers: string[],
  rule?: string | null
) => {
  const dependsOn = questions.find((question) => question.widget.name === getOptions(rule)?.depend)
  if (!dependsOn) {
    return true
  }

  const dependsOnValueWidgetData = getOptions(dependsOn.widget.options)
  const answersNeededToShow = dependsOn.answers.find((answer) =>
    dependsOnValueWidgetData?.onchange?.some((onchange) => onchange.values?.includes(answer.id))
  )?.id

  return selectedAnswers.some((answer) => answersNeededToShow?.includes(answer))
}

interface QuestionBoxProps {
  children: React.ReactNode
  label?: string
  error?: string
  showErrors?: boolean
}

const QuestionBox: React.FC<QuestionBoxProps> = ({ children, label, error, showErrors }) => {
  return (
    <div className={styles.questionBox}>
      {label && <TextStrong>{label}</TextStrong>}
      <div className={styles.fieldGroup}>{children}</div>
      {error && showErrors && <TextTiny className={styles.error}>{error}</TextTiny>}
    </div>
  )
}

const useValidate = (
  testData: TestSectionsDto,
  selectedAnswers: string[],
  freeAnswers: FreeTextAnswer[]
) => {
  const { t } = useTranslation()
  const [errors, setErrors] = useState<Record<string, string>>({})

  const updateError = (condition: boolean, errorMsg: string, questionId: string) =>
    setErrors((e) => ({ ...e, [questionId]: condition ? errorMsg : '' }))

  useEffect(() => {
    testData.sections.map((section) =>
      section.questions
        .filter(({ isMandatory }) => isMandatory)
        .map(({ question, answers }) => {
          switch (question.widgetType) {
            case TestQuestionWidgetType.Radio:
            case TestQuestionWidgetType.Select:
              updateError(
                !selectedAnswers.some((answer) => answers.map(({ id }) => id).includes(answer)),
                t('Validation.Please select an option'),
                question.id
              )
              break

            case TestQuestionWidgetType.Multiselect:
              updateError(
                !selectedAnswers.some((answer) => answers.map(({ id }) => id).includes(answer)),
                t('Validation.Please select at least one option'),
                question.id
              )
              break

            case TestQuestionWidgetType.Checkbox:
              updateError(
                !isOne(
                  freeAnswers.find(({ testQuestionId }) => testQuestionId === question.id)?.answer
                ),
                t('Validation.This selection is required'),
                question.id
              )
              break

            case TestQuestionWidgetType.Text:
            case TestQuestionWidgetType.ShortText:
              updateError(
                !freeAnswers.find(({ testQuestionId }) => testQuestionId === question.id)?.answer,
                t('Validation.Please fill the value'),
                question.id
              )
              break

            default:
              break
          }
        })
    )
  }, [testData, selectedAnswers, freeAnswers, t])

  return errors
}
