import { useMemo } from 'react'
import { Navigate } from 'react-router-dom'

import { useIBRoutesConditions } from '../IntroducingBroker/IntroducingBrokerRoutes'
import { ProductAppropriatenessTestProfilePage } from '../ProductRegistration/AppropriatenessTest/ProductAppropriatenessTestPage'
import { useProductReadContext } from '../global/context/ProductContext'
import { hasShouldVerifyPhoneNumber } from '../model/AccountDetailedDto'
import {
  isAccountAppropriatenessTestSubmittedStatus,
  isAccountPendingDocumentVerificationStatus,
} from '../model/AccountDetailedStatus'
import { DocumentCategoryLinkMap, DocumentCategoryType } from '../model/DocumentCategories'
import { isTickmillProductTypeETD } from '../model/TickmillProductType'
import { RouteConfig } from '../routing/RenderRoutes'
import { getAccountActionAccessLocks } from '../utils/AccountAccess/accountAccessCheck'
import { getAccountAccessStatuses } from '../utils/AccountAccess/accountAccessStatuses'
import {
  hasProductPendingDocumentVerificationStatus,
  isAccountDocumentAllowedUpload,
} from '../utils/AccountAccess/accountDocumentStatuses'
import {
  allowKYCUpdate,
  isAccountKycUpdateDetailsAllowed,
  isDepositBeforeKYC,
  isDepositPlaced,
  isKYCUpdateDue,
} from '../utils/AccountAccess/accountKYCStatuses'
import {
  hasClearedAppropriatenessTest,
  hasProductActivatedStatus,
  hasProductAppropriatenessTestSubmittedStatus,
  isProductAppropriatenessValid,
} from '../utils/AccountAccess/accountProductStatuses'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { useWalletsReadContext } from '../utils/UserWalletContext'
import { isTickmillUK } from '../utils/companyName.utils'
import { AccountSettingsPage } from './AccountSettings/AccountSettingsPage'
import { SecuritySettingsPage } from './AccountSettings/SecuritySettingsPage'
import { AppropriatenessTestPage } from './AppropriatenessTest/AppropriatenessTestPage'
import { BankAccountsPage } from './BankAccounts/BankAccountsPage'
import { CreateBankAccountPage } from './BankAccounts/CreateBankAccountPage'
import { ClientClassificationPage } from './ClientClassification/ClientClassificationPage'
import { DocumentManagementPage } from './DocumentManagement/DocumentManagementPage'
import { DocumentManagementAdditionalDocumentsPage } from './DocumentManagementUpload/DocumentManagementAdditionalDocumentsPage'
import { DocumentManagementCompanyPage } from './DocumentManagementUpload/DocumentManagementCompanyPage'
import { DocumentManagementIdentificationPage } from './DocumentManagementUpload/DocumentManagementIdentificationPage'
import { DocumentManagementPaymentsPage } from './DocumentManagementUpload/DocumentManagementPaymentsPage'
import { DocumentManagementProofOfAddressPage } from './DocumentManagementUpload/DocumentManagementProofOfAddressPage'
import { DocumentVerificationPage } from './DocumentVerification/DocumentVerificationPage'
import { FirstTimeDepositPage } from './FirstTimeDeposit/FirstTimeDepositPage'
import { IntroducingBrokerPage } from './IntroducingBroker/IntroducingBrokerPage'
import { MarketDataClassificationPage } from './MarketDataClassification/MarketDataClassificationPage'
import { PersonalInfoPage } from './PersonalInfo/PersonalInfoPage'
import { PhoneNumbersPage } from './PhoneNumbers/PhoneNumbersPage'
import { Profile } from './Profile'
import { W8BeneficialEPage } from './W8Beneficial/W8BeneficialEPage'
import { W8BeneficialPage } from './W8Beneficial/W8BeneficialPage'
import { YearlyKycUpdateDetailsPage } from './YearlyKycUpdateDetails/YearlyKycUpdateDetailsPage'

export const useProfileRoutes = (): RouteConfig[] => {
  const visible = useProfileRoutesConditions()
  const { account } = useAccountReadContext()
  const { isAccountTradersRoomActionAccessLocked } = getAccountActionAccessLocks(account)
  const { isIBVisible } = useIBRoutesConditions()

  return useMemo(
    () => [
      {
        path: 'profile',
        outlet: <Profile />,
        condition: visible.isOnlyKYCUpdateVisible,
        children: [
          {
            path: 'kyc-update-details',
            element: <YearlyKycUpdateDetailsPage />,
            condition: allowKYCUpdate(account),
          },
          {
            path: 'logout',
            element: <Navigate to={'/logout'} />,
          },
        ],
      },
      {
        path: 'profile',
        outlet: <Profile />,
        condition: !visible.isOnlyKYCUpdateVisible,
        children: [
          {
            path: 'personal-info',
            element: <PersonalInfoPage />,
          },
          {
            path: 'document-verification',
            element: <DocumentVerificationPage />,
          },
          {
            path: 'document-management',
            element: <DocumentManagementPage />,
            condition: visible.isDocManagementVisible,
            children: [
              {
                path: DocumentCategoryLinkMap[DocumentCategoryType.Personal],
                element: <DocumentManagementIdentificationPage />,
              },
              {
                path: DocumentCategoryLinkMap[DocumentCategoryType.Address],
                element: <DocumentManagementProofOfAddressPage />,
              },
              {
                path: DocumentCategoryLinkMap[DocumentCategoryType.Corporate],
                element: <DocumentManagementCompanyPage />,
              },
              {
                path: DocumentCategoryLinkMap[DocumentCategoryType.Additional],
                element: <DocumentManagementAdditionalDocumentsPage />,
              },
              {
                path: DocumentCategoryLinkMap[DocumentCategoryType.Payment],
                element: <DocumentManagementPaymentsPage />,
              },
            ],
          },
          {
            path: 'account-settings',
            element: <AccountSettingsPage />,
          },
          {
            path: 'security-settings',
            element: <SecuritySettingsPage />,
          },
          {
            path: 'phone-numbers',
            element: <PhoneNumbersPage />,
          },
          {
            path: 'logout',
            element: <Navigate to={'/logout'} />,
          },
          {
            path: 'first-time-deposit',
            element: <FirstTimeDepositPage />,
            condition: visible.isFirstTimeDepositVisible,
          },
          {
            path: 'appropriateness-test',
            element: <ProductAppropriatenessTestProfilePage />,
            condition: visible.isAppropriatenessTestVisible,
            children: [
              {
                path: 'take-test',
                element: <ProductAppropriatenessTestProfilePage />,
                condition: visible.isAppropriatenessTestVisible,
              },
            ],
          },
          {
            path: 'product-appropriateness-test',
            element: <AppropriatenessTestPage />,
            condition: visible.isAppropriatenessTestVisible,
          },
          {
            path: 'client-classification',
            element: <ClientClassificationPage />,
            condition: visible.isClientClassificationVisible,
          },
          {
            path: 'tax-form',
            element: <W8BeneficialPage />,
            condition: visible.isTaxFormVisible,
            locked: isAccountTradersRoomActionAccessLocked,
          },
          {
            path: 'e-tax-form',
            element: <W8BeneficialEPage />,
            condition: visible.isETaxFormVisible,
            locked: isAccountTradersRoomActionAccessLocked,
          },
          {
            path: 'introducing-broker',
            element: <IntroducingBrokerPage />,
            condition: isIBVisible,
          },
          {
            path: 'bank-accounts',
            element: <BankAccountsPage />,
            condition: visible.isBankAccountVisible,
            locked: isAccountTradersRoomActionAccessLocked,
            children: [
              {
                path: 'new',
                element: <CreateBankAccountPage />,
              },
            ],
          },
          {
            path: 'market-data-classification',
            element: <MarketDataClassificationPage />,
            condition: visible.isMarketDataClassificationVisible,
          },
          {
            path: 'kyc-update-details',
            element: <YearlyKycUpdateDetailsPage />,
            condition: allowKYCUpdate(account),
          },
          {
            path: 'kyc-update-details',
            element: <Navigate to='/profile/personal-info' />,
            condition: !allowKYCUpdate(account),
          },
          {
            path: '*',
            element: <Navigate to={'/profile/document-management'} />,
            condition: !visible.isLoading,
          },
        ],
      },
    ],
    [
      account,
      isAccountTradersRoomActionAccessLocked,
      isIBVisible,
      visible.isAppropriatenessTestVisible,
      visible.isBankAccountVisible,
      visible.isClientClassificationVisible,
      visible.isETaxFormVisible,
      visible.isFirstTimeDepositVisible,
      visible.isLoading,
      visible.isMarketDataClassificationVisible,
      visible.isOnlyKYCUpdateVisible,
      visible.isTaxFormVisible,
    ]
  )
}

interface RoutesConditions {
  isClientClassificationVisible: boolean
  isTaxFormVisible: boolean
  isETaxFormVisible: boolean
  isAppropriatenessTestVisible: boolean
  isBankAccountVisible: boolean
  isMarketDataClassificationVisible: boolean
  isFirstTimeDepositVisible: boolean
  isDocManagementVisible: boolean
  isOnlyKYCUpdateVisible: boolean
  isLoading?: boolean
}

export const useProfileRoutesConditions = (): RoutesConditions => {
  const { account } = useAccountReadContext()
  const { product } = useProductReadContext()
  const { isDefaultCFDProductType } = useProductReadContext()

  // TODO: we need to change this, we can't fetch the wallets on every re-render of the component for accounts with no wallets
  const { wallets = [] } = useWalletsReadContext()

  const { isAccountActivatedStatus } = getAccountAccessStatuses(account, product)

  const isClientClassificationVisible = useMemo(() => {
    return !!account?.visibilityConfiguration.hasClassification
  }, [account])

  const isTaxFormVisible = useMemo(() => {
    return !!account?.visibilityConfiguration.hasStocks && isDefaultCFDProductType()
  }, [account?.visibilityConfiguration.hasStocks, isDefaultCFDProductType])

  const isETaxFormVisible = useMemo(() => {
    return !!account?.visibilityConfiguration.hasCorporateStocks && isDefaultCFDProductType()
  }, [account?.visibilityConfiguration.hasCorporateStocks, isDefaultCFDProductType])

  const isAppropriatenessTestVisible = isProductAppropriatenessValid(product, account)

  const isBankAccountVisible = useMemo(() => {
    return isAccountActivatedStatus
  }, [isAccountActivatedStatus])

  const isOnlyKYCUpdateVisible = useMemo(
    () =>
      !isProductAppropriatenessValid(product, account) &&
      !!isAccountKycUpdateDetailsAllowed(account, product, true) &&
      !!isKYCUpdateDue(account),
    [account, product]
  )

  const isMarketDataClassificationVisible = useMemo(() => {
    if (!account?.marketDataClassification) {
      return false
    }
    if (!isTickmillUK(account)) {
      return false
    }
    if (!hasProductActivatedStatus(account, product)) {
      return false
    }
    return isTickmillProductTypeETD(product)
  }, [account, product])

  const isFirstTimeDepositVisible = useMemo(() => {
    if (!isDepositBeforeKYC(account) || !wallets?.length) {
      return false
    }
    const isAppTestSubmitted = isAccountAppropriatenessTestSubmittedStatus(account?.status?.id)
    const isPendingDocumentVerification = isAccountPendingDocumentVerificationStatus(
      account?.status?.id
    )
    const noDepositPlaced = !isDepositPlaced(account)
    if (isAppTestSubmitted && noDepositPlaced) {
      return true
    }
    if (isPendingDocumentVerification && noDepositPlaced) {
      return true
    }
    return false
  }, [account, wallets])

  const isDocManagementVisible = useMemo(() => {
    if (
      hasProductAppropriatenessTestSubmittedStatus(account, product) &&
      hasClearedAppropriatenessTest(account, product)
    ) {
      return true
    }
    if (hasProductPendingDocumentVerificationStatus(account, product)) {
      return true
    }
    if (isAccountActivatedStatus) {
      return true
    }
    return false
  }, [account, isAccountActivatedStatus, product])

  return {
    isClientClassificationVisible,
    isTaxFormVisible,
    isETaxFormVisible,
    isAppropriatenessTestVisible,
    isBankAccountVisible,
    isMarketDataClassificationVisible,
    isFirstTimeDepositVisible,
    isDocManagementVisible,
    isOnlyKYCUpdateVisible,
    isLoading: !account,
  }
}

interface ProfileRoutesRequired {
  appropriatenessTest: boolean
  documentManagement: boolean
  shouldVerifyPhoneNumber: boolean
}

export const useProfileRoutesRequired = (): ProfileRoutesRequired => {
  const { product } = useProductReadContext()
  const { account } = useAccountReadContext()
  const isAccountAppropriatenessTest = isProductAppropriatenessValid(product, account)

  const appropriatenessTest = isAccountAppropriatenessTest

  const documentManagement = useMemo(() => isAccountDocumentAllowedUpload(account), [account])

  const shouldVerifyPhoneNumber = useMemo(() => hasShouldVerifyPhoneNumber(account), [account])

  return {
    appropriatenessTest,
    documentManagement,
    shouldVerifyPhoneNumber,
  }
}
