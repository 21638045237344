import React, { FC, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Loading } from '../global/Loading/Loading'
import { Button } from '../global/button/Button'
import { CancelActionModal } from '../global/modal/CancleActionModal'
import { Modal } from '../global/modal/Modal'
import { useFormatNumber } from '../hooks/useFormatNumber'
import { ScrollToIds, useScrollToElementIds } from '../hooks/useScrollToElementIds'
import { ChevronDownIcon } from '../icons/ChevronDownIcon'
import { ChevronUpIcon } from '../icons/ChevronUpIcon'
import { InfoIcon } from '../icons/InfoIcon'
import { TrashIcon } from '../icons/TrashIcon'
import { WarningIcon } from '../icons/WarningIcon'
import { NameDto } from '../model/NameDto'
import { SubscriptionCartItem } from '../model/SubscriptionCart'
import { SubscriptionRequestState } from '../model/SubscriptionRequestState'
import { SubscriptionRequestTypeEnum } from '../model/SubscriptionRequestTypeEnum'
import {
  SubscriptionGroupType,
  SubscriptionHistoryItem,
  SubscriptionStatus,
  isSubscriptionAllowedToDelete,
} from '../model/SubscriptionsDto'
import {
  SubscriptionRequestType,
  SubscriptionTimeInForce,
  UpdateSubscriptionRequest,
} from '../model/UpdateSubscriptionRequest'
import {
  TextH3,
  TextSmall,
  TextSmallStrong,
  TextStrong,
  TextTiny,
} from '../ui/Typography/Typography'
import { isLastBusinessDay } from '../utils/date.utils'
import { useWindowResize } from '../utils/domUtils'
import { InsufficientFundsModal, UnableToCheckoutModal } from './CheckoutMessageModal'
import { PendingUnsubscriptionModal } from './MarketData/PendingUnsubscriptionModal'
import { UnsubscribeConfirmationModal } from './MarketData/UnsubscribeConfirmationModal'
import { UnsubscribeAttentionConfirmationModal } from './UnsubscribeAttentionModal'

import styles from './Subscriptions.module.scss'

interface SubscriptionItemProps {
  name: string | undefined
  state?: NameDto
  amount: number | undefined
  showCancelModal: boolean
  setShowCancelModal: React.Dispatch<React.SetStateAction<boolean>>
  onClickTrash: () => void
  onConfirmCancel?: () => void
  isPendingUnsubscriptionRequest?: boolean
  onClickPendingSubscriptionInfoModal?(): void
  isPendingImmediateSubscription?: boolean
}

const SubscriptionItem: FC<SubscriptionItemProps> = ({
  name,
  state,
  amount,
  onClickTrash,
  onConfirmCancel,
  setShowCancelModal,
  showCancelModal,
  isPendingUnsubscriptionRequest,
  onClickPendingSubscriptionInfoModal,
  isPendingImmediateSubscription,
}) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const showTrashIcon = useMemo(
    () =>
      !isPendingUnsubscriptionRequest &&
      !isPendingImmediateSubscription &&
      isSubscriptionAllowedToDelete(state),
    [isPendingUnsubscriptionRequest, isPendingImmediateSubscription, state]
  )

  return (
    <div className='is-flex is-justify-content-space-between mb-2'>
      {showCancelModal && (
        <Modal
          size='small'
          closeModal={() => setShowCancelModal(false)}
          render={() => (
            <CancelActionModal
              title={t('Subscriptions.Cancel Order?')}
              cancelLabel={t('Subscriptions.Keep Order')}
              confirmLabel={t('Subscriptions.Yes, cancel')}
              onConfirm={onConfirmCancel}
              onCancel={() => setShowCancelModal(false)}
            >
              <p className='text'>{t('Subscriptions.Do you really like to cancel the order?')}</p>
            </CancelActionModal>
          )}
        />
      )}
      <div className='is-flex'>
        <TextSmall>
          <span className={isPendingUnsubscriptionRequest ? 'mr-1' : ''}>{name}</span>
          {isPendingUnsubscriptionRequest && (
            <span className={styles.cartStatus}>
              <span className='has-text-warning'>
                ({t('Subscriptions.Pending Unsubscription')}){' '}
              </span>
              <span className='pt-2 mt-2' onClick={onClickPendingSubscriptionInfoModal}>
                <InfoIcon />
              </span>
            </span>
          )}
        </TextSmall>
      </div>

      <div className='is-flex is-align-items-center'>
        <TextSmall>{formatMoney(amount)}</TextSmall>
        {showTrashIcon && (
          <span className='ml-2 is-clickable' onClick={onClickTrash}>
            <TrashIcon size={16} />
          </span>
        )}
      </div>
    </div>
  )
}

interface CartProps {
  cartItemsIsLoading: boolean

  handleOnUpdateSubscription(
    data: UpdateSubscriptionRequest,
    selectedActiveSubscriptionCartItem?: SubscriptionHistoryItem | undefined
  ): void
  handleOnClickPay(): void
  onRemoveCartItem(id: string): void
  isExpanded?: boolean
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>
  hasPendingUnsubscriptionRequest?: boolean
  activeSubscriptions?: SubscriptionHistoryItem[]
  noneActiveSubscriptions?: SubscriptionCartItem[]
  pendingUnsubscriptionsData?: SubscriptionHistoryItem[]
  availableToWithdraw?: number
  hasSubscriptions: boolean
  upgradeOrDowngradeSubscriptionId: Set<string>
}

export const Cart: React.FC<CartProps> = ({
  handleOnClickPay,
  handleOnUpdateSubscription,
  onRemoveCartItem,
  activeSubscriptions = [],
  noneActiveSubscriptions = [],
  cartItemsIsLoading,
  hasPendingUnsubscriptionRequest,
  isExpanded,
  setIsExpanded,
  pendingUnsubscriptionsData,
  availableToWithdraw = 0,
  hasSubscriptions,
  upgradeOrDowngradeSubscriptionId,
}) => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const { formatMoney } = useFormatNumber()
  const { scrollIntoView } = useScrollToElementIds()

  const [pendingUnsubscriptionInfoModal, setPendingUnsubscriptionInfoModal] = useState(false)

  const [showUnableToCheckoutModal, setShowUnableToCheckoutModal] = useState(false)

  const [insufficientFundsModal, setInsufficientFundsModal] = useState(false)

  const [insufficientBillableNowFundsModal, setInsufficientBillableNowFundsModal] = useState(false)

  const [selectedActiveSubscriptionCartItem, setSelectedActiveSubscriptionCartItem] = useState<
    SubscriptionHistoryItem | undefined
  >()
  const [selectedNoneActiveSubscriptionCartItem, setSelectedNoneActiveSubscriptionCartItem] =
    useState<SubscriptionCartItem | undefined>()
  const [unsubscribeConfirmationModal, setUnsubscribeConfirmationModal] = useState(false)
  const [unsubscribeAttentionConfirmationModal, setUnsubscribeAttentionConfirmationModal] =
    useState(false)
  const [showCancelModal, setShowCancelModal] = useState(false)

  const handleOnUnsubscribe = async () => {
    setUnsubscribeConfirmationModal(false)
    setUnsubscribeAttentionConfirmationModal(false)

    handleOnUpdateSubscription(
      {
        subscriptions: [
          {
            subscriptionDetailId: selectedActiveSubscriptionCartItem?.subscriptionDetailId,
            subscriptionRequestTypeId: SubscriptionRequestType.Cancel,
          },
        ],
        subscriptionTimeInForceId: SubscriptionTimeInForce.BeginningOfNextMonth,
      },
      selectedActiveSubscriptionCartItem
    )
  }
  const handleOnCancelPendingSubscription = async () => {
    setShowCancelModal(false)
    handleOnUpdateSubscription({
      subscriptions: [
        {
          subscriptionDetailId: selectedActiveSubscriptionCartItem?.subscriptionDetailId,
          subscriptionRequestTypeId: SubscriptionRequestType.Cancel,
        },
      ],
      subscriptionTimeInForceId: SubscriptionTimeInForce.Immediate,
    })
  }

  const isPendingUnsubscriptionRequest = (subscription: SubscriptionHistoryItem) => {
    return pendingUnsubscriptionsData?.some(
      (item) =>
        item?.subscriptionDetailId === subscription?.subscriptionDetailId &&
        item?.state?.id === subscription?.state?.id &&
        item?.type?.id === subscription?.type?.id
    )
  }

  const isPendingImmediateSubscription = (subscription: SubscriptionHistoryItem) => {
    return (
      subscription.subscriptionTimeInForce.id === SubscriptionTimeInForce.Immediate &&
      subscription.state.id === SubscriptionStatus.PENDING
    )
  }

  const filterByNonBillableSubscription = (
    data: SubscriptionHistoryItem[] = []
  ): SubscriptionHistoryItem[] =>
    data?.filter(
      (item: SubscriptionHistoryItem) =>
        !(
          item.state.name === SubscriptionRequestState.Active &&
          upgradeOrDowngradeSubscriptionId.has(item.subscription.id)
        ) &&
        !(
          item.state.name === SubscriptionRequestState.Pending &&
          item.type.id === SubscriptionRequestType.Cancel
        )
    )

  const getUpgradeDifference = (cartItem: SubscriptionCartItem): number => {
    const levelToUpgrade = activeSubscriptions.find(
      (activeItem) => activeItem.subscription.id === cartItem.subscription.id
    )
    return levelToUpgrade?.amount
      ? cartItem.subscriptionDetail.amount - levelToUpgrade.amount
      : cartItem.subscriptionDetail.amount
  }

  const sumOfNoneActiveSubscriptions = (data?: SubscriptionCartItem[]): number => {
    return (
      data?.reduce((acc: number, noneActiveSubscription: SubscriptionCartItem) => {
        if (
          noneActiveSubscription.subscriptionRequestType.name ===
          SubscriptionRequestTypeEnum.UpdateExistingSubscription
        ) {
          return acc + getUpgradeDifference(noneActiveSubscription)
        }

        return acc + (noneActiveSubscription.subscriptionDetail?.amount || 0)
      }, 0) || 0
    )
  }

  const sumOfActiveSubscriptions = (data?: SubscriptionHistoryItem[]) =>
    filterByNonBillableSubscription(data).reduce(
      (acc: number, activeSubscription: SubscriptionHistoryItem) =>
        acc + (activeSubscription?.amount || 0),
      0
    ) || 0

  const billableNextMonth: number = sumOfActiveSubscriptions(activeSubscriptions)

  const totalToPayNow: number = sumOfNoneActiveSubscriptions(noneActiveSubscriptions)

  const total = totalToPayNow + billableNextMonth

  const hasNotEnoughFunds = availableToWithdraw < totalToPayNow

  const hasNotEnoughFundsForCardBillable = availableToWithdraw < billableNextMonth

  const isCheckoutDisabled = useMemo(() => {
    return !hasSubscriptions || !noneActiveSubscriptions.length || hasNotEnoughFunds
  }, [hasSubscriptions, noneActiveSubscriptions, hasNotEnoughFunds])

  const isPaymentAllowed = useMemo(() => {
    return !!noneActiveSubscriptions.length
  }, [noneActiveSubscriptions])

  const handleOnClosePendingUnsubscriptionModal = () => setPendingUnsubscriptionInfoModal(false)

  const handleOnClickPendingUnsubscriptionModal = (
    activeSubscriptionCartItem: SubscriptionHistoryItem
  ) => {
    setSelectedActiveSubscriptionCartItem(activeSubscriptionCartItem)
    setPendingUnsubscriptionInfoModal(true)
  }
  const handleOnClickTrash = (activeSubscription: SubscriptionHistoryItem) => {
    setSelectedActiveSubscriptionCartItem(activeSubscription)
    if (activeSubscription.state.name === SubscriptionRequestState.Pending) {
      setShowCancelModal(true)
    } else if (isLastBusinessDay()) {
      setUnsubscribeAttentionConfirmationModal(true)
    } else {
      setUnsubscribeConfirmationModal(true)
    }
  }
  return (
    <Loading showLoadingIcon isLoading={cartItemsIsLoading}>
      {showUnableToCheckoutModal && (
        <UnableToCheckoutModal onCancel={() => setShowUnableToCheckoutModal(false)} />
      )}

      {insufficientFundsModal && (
        <InsufficientFundsModal
          navigationUrl='/dashboard/traders-room/wallets/deposit'
          onCancel={() => {
            setInsufficientFundsModal(false)
          }}
        />
      )}
      {insufficientBillableNowFundsModal && (
        <InsufficientFundsModal
          navigationUrl='/dashboard/traders-room/wallets/transfer'
          onCancel={() => {
            setInsufficientBillableNowFundsModal(false)
          }}
        />
      )}

      {pendingUnsubscriptionInfoModal && (
        <PendingUnsubscriptionModal
          handleOnClosePendingUnsubscriptionModal={handleOnClosePendingUnsubscriptionModal}
          dueDate={selectedActiveSubscriptionCartItem?.dueDate}
        />
      )}
      {unsubscribeConfirmationModal && (
        <UnsubscribeConfirmationModal
          dueDate={selectedActiveSubscriptionCartItem?.dueDate}
          onClickUnsubscribe={handleOnUnsubscribe}
          setUnsubscribeConfirmationModal={() => setUnsubscribeConfirmationModal(false)}
        />
      )}
      {unsubscribeAttentionConfirmationModal && (
        <UnsubscribeAttentionConfirmationModal
          onConfirm={handleOnUnsubscribe}
          onCancel={() => setUnsubscribeAttentionConfirmationModal(false)}
        />
      )}

      <div>
        <div className='is-flex is-justify-content-space-between'>
          <div className='is-flex'>
            {hasNotEnoughFunds && isMobile && (
              <Button
                onClick={() => setInsufficientFundsModal(true)}
                appearance='link'
                size='M'
                className='mr-2'
              >
                <WarningIcon color='warning' />
              </Button>
            )}
            {hasNotEnoughFundsForCardBillable && isMobile && (
              <Button
                onClick={() => setInsufficientBillableNowFundsModal(true)}
                appearance='link'
                size='M'
                className='mr-2'
              >
                <WarningIcon color='error' />
              </Button>
            )}
            <TextH3>{t('Subscriptions.Cart')}</TextH3>
          </div>
          <div className='is-flex'>
            {hasNotEnoughFunds && !isMobile && (
              <Button
                onClick={() => setInsufficientFundsModal(true)}
                appearance='link'
                size='M'
                className='mr-2'
              >
                <WarningIcon color='warning' />
              </Button>
            )}

            {hasNotEnoughFundsForCardBillable && !isMobile && (
              <Button
                onClick={() => setInsufficientBillableNowFundsModal(true)}
                appearance='link'
                size='M'
                className='mr-2'
              >
                <WarningIcon color='error' />
              </Button>
            )}

            {!isExpanded && isMobile && <TextH3>{formatMoney(total)}</TextH3>}

            {isMobile && (
              <span
                className='ml-2'
                onClick={() => {
                  if (!isExpanded) {
                    scrollIntoView([ScrollToIds.SubscriptionsSidebarWrapper])
                  }
                  setIsExpanded(!isExpanded)
                }}
              >
                {isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
              </span>
            )}
          </div>
        </div>
        <div className={classNames(styles.expandedContent, { [styles.expanded]: isExpanded })}>
          {hasPendingUnsubscriptionRequest && (
            <>
              <div className='mt-4 text has-text-warning'>
                <TextStrong className='mr-2'>{t('Subscriptions.ATTENTION')}!</TextStrong>
                <span>{t('Subscriptions.You have pending requests outstanding')}</span>
              </div>
              <div className='mt-2 text text-secondary'>
                {t('Subscriptions.When your request has been accepted')}
              </div>
            </>
          )}
          {hasNotEnoughFundsForCardBillable && (
            <>
              <div className='mt-4 text has-text-danger'>
                <TextStrong className='mr-2'>{t('Subscriptions.ATTENTION')}!</TextStrong>
                <span>{t('Subscriptions.You might have insufficient funds')}</span>
              </div>
              <div className='mt-2 text text-secondary'>
                {t('Subscriptions.After depositing funds to your wallet')}
              </div>
              <Button
                onClick={() => navigate('/dashboard/traders-room/wallets/transfer')}
                appearance='primary'
                size='M'
                className='is-fullwidth my-4'
              >
                {t('Subscriptions.Fund Tickmill Trading Account')}
              </Button>
            </>
          )}
          <div className='is-flex is-justify-content-space-between my-4'>
            <TextTiny className='text-secondary'>{t('Market Data.Product')}</TextTiny>
            <TextTiny className='text-secondary'>{t('Market Data.Price')}</TextTiny>
          </div>
          {activeSubscriptions?.map((activeSubscription) => (
            <SubscriptionItem
              name={`${activeSubscription?.subscription?.name} ${
                activeSubscription?.subscriptionGroupType?.name ===
                  SubscriptionGroupType.MARKET_DATA && activeSubscription?.name
                  ? `(${activeSubscription?.name})`
                  : ''
              }`}
              state={activeSubscription.state}
              amount={activeSubscription.amount}
              showCancelModal={showCancelModal}
              setShowCancelModal={setShowCancelModal}
              isPendingUnsubscriptionRequest={isPendingUnsubscriptionRequest(activeSubscription)}
              isPendingImmediateSubscription={isPendingImmediateSubscription(activeSubscription)}
              onClickTrash={() => {
                handleOnClickTrash(activeSubscription)
              }}
              onClickPendingSubscriptionInfoModal={() =>
                handleOnClickPendingUnsubscriptionModal(activeSubscription)
              }
              key={activeSubscription.id}
              onConfirmCancel={handleOnCancelPendingSubscription}
            />
          ))}
          {noneActiveSubscriptions?.map((noneActiveSubscription) => (
            <SubscriptionItem
              name={`${noneActiveSubscription?.subscription?.name} ${
                noneActiveSubscription?.subscription?.subscriptionGroupType?.name ===
                  SubscriptionGroupType.MARKET_DATA &&
                noneActiveSubscription?.subscriptionDetail?.name
                  ? `(${noneActiveSubscription.subscriptionDetail?.name})`
                  : ''
              }`}
              amount={noneActiveSubscription.subscriptionDetail.amount}
              showCancelModal={showCancelModal}
              setShowCancelModal={setShowCancelModal}
              onConfirmCancel={() => {
                if (selectedNoneActiveSubscriptionCartItem?.id) {
                  setShowCancelModal(false)
                  onRemoveCartItem(selectedNoneActiveSubscriptionCartItem?.id)
                }
              }}
              onClickTrash={() => {
                setShowCancelModal(true)
                setSelectedNoneActiveSubscriptionCartItem(noneActiveSubscription)
              }}
              key={noneActiveSubscription.id}
            />
          ))}
          <div className='is-flex is-justify-content-space-between'>
            <div>
              <TextSmallStrong>{t('Market Data.Pay Now')}</TextSmallStrong>
            </div>
            <TextSmallStrong>{formatMoney(totalToPayNow)}</TextSmallStrong>
          </div>
          <div className='is-flex is-justify-content-space-between my-3'>
            <div>
              <TextSmallStrong>{t('Market Data.Monthly recurring payment')}</TextSmallStrong>
            </div>
            <TextSmallStrong>{formatMoney(billableNextMonth)}</TextSmallStrong>
          </div>
          <div className='is-flex is-justify-content-space-between my-3'>
            <div>
              <TextSmallStrong>{t('Market Data.Total')}</TextSmallStrong>
            </div>
            <TextSmallStrong>{formatMoney(total)}</TextSmallStrong>
          </div>
          {isPaymentAllowed && (
            <div className='is-flex is-flex-direction-column is-justify-content-center'>
              <Button
                onClick={handleOnClickPay}
                appearance='primary'
                disabled={isCheckoutDisabled}
                size='M'
                className='is-fullwidth my-4'
              >{`${t('Market Data.Pay Now')} ${formatMoney(totalToPayNow)} `}</Button>

              {isCheckoutDisabled && (
                <Button
                  onClick={() => setShowUnableToCheckoutModal(true)}
                  appearance='link'
                  size='M'
                  className='is-fullwidth'
                >
                  {t('Subscriptions.Why I can’t checkout?')}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </Loading>
  )
}
