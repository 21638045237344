import React, { useMemo, useState } from 'react'
import { Trans, useTranslation } from 'react-i18next'
import { Link, NavigateOptions, useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { useSnackbar } from '../../global/context/SnackbarContext'
import IconButton from '../../global/iconButton/IconButton'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { Table } from '../../global/table/Table'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { ScrollToIds } from '../../hooks/useScrollToElementIds'
import { useTradingAccountStatus } from '../../hooks/useTradingAccountStatus'
import { InfoIcon } from '../../icons/InfoIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { AccountDetailedDto } from '../../model/AccountDetailedDto'
import { AccountGroupType } from '../../model/AccountGroupType'
import { CampaignStatusEnum } from '../../model/CampaignStatusEnum'
import { PlatformTypeEnum } from '../../model/PlatformTypeEnum'
import { TotalWalletBalanceDto } from '../../model/TotalWalletBalanceDto'
import { TradingAccount } from '../../model/TradingAccount'
import { WalletDto, WalletTypeEnum } from '../../model/WalletDto'
import { AlertIconWrapper } from '../../ui/AlertIconWrapper/AlertIconWrapper'
import { InfoCard } from '../../ui/Popups/InfoCard/InfoCard'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextH3, TextSmall } from '../../ui/Typography/Typography'
import { useAccountActionAccessCheck } from '../../utils/AccountAccess/accountAccessCheck'
import { Operator, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useWindowResize } from '../../utils/domUtils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../utils/useFetch'
import { isZero } from '../../utils/validations'
import {
  WalletRestrictions,
  isDepositEnabledFromAction,
  isRestricted,
  isWalletSuspended,
} from '../../utils/wallet.utils'
import { WalletCard } from './WalletCard'

import styles from './WalletsTable.module.scss'

interface Props {
  wallets: WalletDto[]
  handleWithdrawalNavigation: (withdrawalOptions: {
    walletId: string
    navigateOptions?: NavigateOptions
  }) => Promise<void>
  isWalletCreationRestricted: boolean
  walletWithdrawLoadingId: string
  account: AccountDetailedDto | undefined
  onSetModalVisible(v: boolean): void
  onSetAddWalletModal(v: boolean): void
  onSetWalletNumberModal({ visible, data }: { visible: boolean; data?: WalletDto }): void
  onSetAvailableWalletsModalVisible(v: boolean): void
  onSetSelectedWalletId: (walletId: string) => void
  getReservedData(walletId: string): void
}

interface WalletTableProps {
  shouldShowReservedBonus: boolean
  wallets: WalletDto[]
  onSetModalVisible: (v: boolean) => void
  onSetSelectedWalletId: (selectedWalletId: string) => void
  onDeposit: (wallet: WalletDto) => void
  getReservedData(walletId: string): void
  hasNegativeBalance: boolean
  // tradingAccountStatusLoading: boolean
  handleWithdrawalNavigation: (withdrawalOptions: {
    walletId: string
    navigateOptions?: NavigateOptions
  }) => void
  onNonRestrictedTransfer?: (walletId: string) => void
  walletWithdrawLoadingId: string
}

type depositType = 'wallet' | 'campaign account'

const WalletTable: React.FC<WalletTableProps> = ({
  wallets,
  onSetModalVisible,
  onSetSelectedWalletId,
  shouldShowReservedBonus,
  onDeposit: handleDeposit,
  getReservedData,
  hasNegativeBalance,
  // tradingAccountStatusLoading,
  onNonRestrictedTransfer,
  handleWithdrawalNavigation,
  walletWithdrawLoadingId,
}) => {
  const { t } = useTranslation()

  const { formatMoney } = useFormatNumber()
  const { checkAccountTradersRoomAccess, checkAccountOpenAccountAccess } =
    useAccountActionAccessCheck()
  const { addSnackbar } = useSnackbar()

  return (
    <Table>
      <thead>
        <tr>
          <th>{t('Wallet.Wallet')}</th>
          <th>{t('Wallet.Wallet Number')}</th>
          <th>{t('Wallet.Available Balance')}</th>
          <th>{t('Wallet.Reserved')}</th>
          {shouldShowReservedBonus && <th>{t('Wallet.Reserved funds for Bonus')}</th>}
          <th align='center'>{t('Wallet.Transaction History')}</th>
          <th />
          <th />
          <th align='right'>{t('Actions')}</th>
        </tr>
      </thead>
      <tbody>
        {wallets.map((wallet) => {
          const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
          const isDepositRestricted = isRestricted(WalletRestrictions.DEPOSIT, wallet.restrictions)
          const isCanDepositEnabled = isDepositEnabledFromAction(wallet.depositAction)
          const isTransferFromRestricted = isRestricted(
            WalletRestrictions.TRANSFER_FROM,
            wallet.restrictions
          )
          const isWithdrawRestricted = isRestricted(
            WalletRestrictions.WITHDRAW,
            wallet.restrictions
          )

          const showSnackbarError = () =>
            addSnackbar.error({ message: t('This action is not available') })

          const handleDepositClick = () => {
            isDepositRestricted || !isCanDepositEnabled
              ? showSnackbarError()
              : handleDeposit(wallet)
          }

          const handleTransferClick = () => {
            checkAccountOpenAccountAccess(() => {
              isTransferFromRestricted ? showSnackbarError() : onNonRestrictedTransfer?.(wallet.id)
            })
          }

          const handleWithdrawClick = () => {
            checkAccountTradersRoomAccess(() => {
              if (!isWithdrawRestricted && !hasNegativeBalance) {
                onSetSelectedWalletId(wallet.id)
                handleWithdrawalNavigation({ walletId: wallet.id })
              } else if (isWithdrawRestricted) {
                showSnackbarError()
              }
            })
          }

          return (
            <tr key={wallet.id}>
              <td>
                <span className='is-flex is-align-items-center'>
                  {hasAllRestrictions && (
                    <span
                      className='is-flex mr-2 has-cursor-pointer'
                      onClick={() => hasAllRestrictions && onSetModalVisible(true)}
                    >
                      <WarningIcon color={'error'} size={20} />
                    </span>
                  )}
                  {wallet.currency.id} {t('Wallet.Wallet')}
                </span>
              </td>
              <td>{wallet.name}</td>
              <td>
                <strong>{formatMoney(wallet.balance, wallet.currency.id)}</strong>
              </td>
              <td>{formatMoney(wallet.reserved, wallet.currency.id)}</td>
              {shouldShowReservedBonus && (
                <td>
                  {isZero(wallet.bonusReserved) ? (
                    formatMoney(wallet.bonusReserved, wallet.currency.id)
                  ) : (
                    <Button
                      disabled={isZero(wallet.bonusReserved)}
                      className={styles.clickable}
                      appearance='link'
                      onClick={() => getReservedData(wallet.id)}
                    >
                      {formatMoney(wallet.bonusReserved, wallet.currency.id)}
                    </Button>
                  )}
                </td>
              )}
              <td align='center'>
                <Link
                  className='is-link is-right'
                  to='/dashboard/traders-room/transaction-history'
                  state={{ walletId: wallet.id }}
                >
                  {t('View')}
                </Link>
              </td>
              <td>
                <AlertIconWrapper
                  spaced
                  isActive={!hasAllRestrictions && isDepositRestricted && isCanDepositEnabled}
                >
                  <Button
                    state={isDepositRestricted || !isCanDepositEnabled ? 'disabled' : 'normal'}
                    size='XS'
                    appearance='primary'
                    onClick={handleDepositClick}
                  >
                    {t('Deposit')}
                  </Button>
                </AlertIconWrapper>
              </td>
              <td>
                <AlertIconWrapper spaced isActive={!hasAllRestrictions && isTransferFromRestricted}>
                  <Button
                    size='XS'
                    appearance='secondary'
                    state={isTransferFromRestricted ? 'disabled' : 'normal'}
                    onClick={handleTransferClick}
                  >
                    {t('Transfer')}
                  </Button>
                </AlertIconWrapper>
              </td>
              <td>
                <AlertIconWrapper spaced isActive={!hasAllRestrictions && isWithdrawRestricted}>
                  <InfoCard
                    text={t(
                      'Wallet.Withdrawal is currently not available because your trading account'
                    )}
                    direction='left'
                    lockDirection
                    disabled={!hasNegativeBalance}
                  >
                    <Button
                      size='XS'
                      onClick={handleWithdrawClick}
                      appearance='secondary'
                      state={isWithdrawRestricted || hasNegativeBalance ? 'disabled' : 'normal'}
                      loading={walletWithdrawLoadingId === wallet.id}
                    >
                      {t('Withdraw')}
                    </Button>
                  </InfoCard>
                </AlertIconWrapper>
              </td>
            </tr>
          )
        })}
      </tbody>
    </Table>
  )
}

export const WalletsTable: React.FC<Props> = ({
  wallets,
  handleWithdrawalNavigation,
  onSetModalVisible,
  onSetAvailableWalletsModalVisible,
  onSetWalletNumberModal,
  onSetAddWalletModal,
  onSetSelectedWalletId,
  getReservedData,
  isWalletCreationRestricted,
  walletWithdrawLoadingId,
  account,
}) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()

  const shouldShowReservedBonus = useMemo(
    () => !!wallets.find((x) => x.bonusReserved > 0),
    [wallets]
  )

  const [depositOptionModal, setDepositOptionModal] = useState(false)
  const [selectedWallet, setSelectedWallet] = useState<WalletDto | undefined>()
  const [campaignAccount, setCampaignAccount] = useState<TradingAccount | undefined>()
  const [selectedDepositType, setSelectedDepositType] = useState<depositType | undefined>()
  const [isTotalBalanceModalOpen, setTotalBalanceModalOpen] = useState(false)
  const { hasActiveTradingAccounts, hasNegativeBalance } = useTradingAccountStatus()

  const navigate = useNavigate()
  const apiClient = useApiClient(ClientApiClient)
  const isMobile = useWindowResize()
  const { checkAccountTradersRoomAccess } = useAccountActionAccessCheck()

  const closeDepositOptionModal = () => setDepositOptionModal(false)
  const openDepositOptionModal = () => setDepositOptionModal(true)

  const { callback } =
    useCallbackWithForceRefresh(async (): Promise<TotalWalletBalanceDto | null> => {
      const currencyId = account?.displayCurrency?.id || 'USD'
      return await apiClient.getTotalBalanceByCurrency(currencyId, WalletTypeEnum.CFD)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

  const { data: totalBalance = null } = useFetchOne(callback)

  const handleDepositChange = (depositTo: depositType) => {
    setSelectedDepositType(depositTo)
    closeDepositOptionModal()
    if (depositTo === 'wallet' && selectedWallet) {
      navigateToWalletDeposit(selectedWallet)
    } else {
      if (campaignAccount) {
        navigate(
          `/dashboard/traders-room/trading-accounts/${campaignAccount.id}/deposit-to-campaign`
        )
      }
    }
  }
  const handleDeposit = async (wallet: WalletDto) => {
    setSelectedWallet(wallet)

    // TODO find solution that will not require query all TA's to check if campaign exists.
    const tradingAccounts = await apiClient.getTradingAccounts({
      search: {
        Campaign: {
          value: true,
          operator: Operator.IS_NOT_EMPTY,
        },
        AccountGroupType: {
          value: AccountGroupType.Live,
          operator: Operator.EQUAL,
        },
        Platform: {
          value: PlatformTypeEnum.CQGCAST,
          operator: Operator.NOT_EQUAL,
        },
        Status: {
          value: CampaignStatusEnum.Enabled,
          operator: Operator.EQUAL,
        },
        Wallet: {
          value: wallet.id,
          operator: Operator.EQUAL,
        },
      },
    })

    if (tradingAccounts.items.length) {
      setCampaignAccount(tradingAccounts.items.find((x) => !!x.campaigns.length))
      openDepositOptionModal()
    } else {
      navigateToWalletDeposit(wallet)
    }
  }

  const navigateToWalletDeposit = (wallet: WalletDto) =>
    navigate(`/dashboard/traders-room/wallets/${wallet.id}/deposit`)

  const handleNonRestrictedTransfer = (walletId: string) => {
    const hasOnlyOneWallet = wallets?.length === 1

    if (!hasActiveTradingAccounts && hasOnlyOneWallet) {
      return navigate(`/dashboard/traders-room/trading-accounts/new`)
    }

    if (walletId) {
      return navigate(`/dashboard/traders-room/wallets/${walletId}/transfer`)
    }
  }

  return (
    <>
      {isTotalBalanceModalOpen && (
        <Modal
          closeModal={() => setTotalBalanceModalOpen(false)}
          render={() => (
            <InformationModal
              title={t('Wallet.Total Wallets Balance')}
              onCancel={() => setTotalBalanceModalOpen(false)}
              onCancelText={t('Got It')}
            >
              <Trans
                i18nKey='Wallet.Your current balance is the total of your {{ value }} balance(s)'
                components={{
                  1: <Link title={t('Currency you selected')} to={`/profile/account-settings`} />,
                }}
                values={{ value: 'wallet' }}
              />
            </InformationModal>
          )}
        />
      )}
      {depositOptionModal && (
        <Modal
          closeModal={closeDepositOptionModal}
          render={() => (
            <InformationModal
              spacing='compact'
              title={t('Wallet.Deposit To')}
              onCancel={closeDepositOptionModal}
              onCancelText={t('Cancel')}
            >
              <ul>
                {['campaign account', 'wallet'].map((x, idx) => (
                  <li key={idx} className={isZero(idx) ? styles.hasBorderBottom : ''}>
                    <label className='radio pt-5 pb-5 pl-6'>
                      <input
                        value={x}
                        type='radio'
                        name='depositTo'
                        className='mr-1'
                        onChange={() => handleDepositChange(x as depositType)}
                        checked={selectedDepositType === x}
                      />
                      {isZero(idx)
                        ? t('Campaign Account')
                        : selectedWallet?.currency.id + ' ' + t('Wallet.Wallet')}
                    </label>
                  </li>
                ))}
              </ul>
            </InformationModal>
          )}
        />
      )}
      <PageHeader
        id={ScrollToIds.WalletsHeader}
        actions={[
          {
            disabled: isWalletCreationRestricted,
            onClick: () =>
              checkAccountTradersRoomAccess(() => {
                onSetAddWalletModal(true)
              }),
            label: `+ ${t('Wallet.Add Wallet')}`,
            infoHandler: () => onSetAvailableWalletsModalVisible(true),
          },
        ]}
        renderTitle={() => (
          <div className={classNames('is-flex is-align-items-center')}>
            <TextSmall className={styles.title}>{t('Wallet.Wallets')} </TextSmall>
          </div>
        )}
        renderSubtitle={() => (
          <div className='is-flex is-align-items-center'>
            <TextH3>
              {totalBalance?.currency?.id && (
                <div className='is-flex is-align-items-center'>
                  <span className='mr-2'>
                    ≈{formatMoney(totalBalance.balance, totalBalance.currency.id)}
                  </span>
                  <IconButton onClick={() => setTotalBalanceModalOpen(true)}>
                    <InfoIcon size={14} />
                  </IconButton>
                </div>
              )}
            </TextH3>
          </div>
        )}
      />
      {isMobile && (
        <div>
          {wallets.map((wallet) => (
            <WalletCard
              getReservedData={getReservedData}
              wallet={wallet}
              onSetWalletNumberModal={onSetWalletNumberModal}
              shouldShowReservedBonus={shouldShowReservedBonus}
              // onSetNegativeBalanceModal={onSetNegativeBalanceModal}
              onDeposit={handleDeposit}
              key={wallet.id}
              // tradingAccountStatusLoading={tradingAccountStatusLoading}
              hasNegativeBalance={hasNegativeBalance}
              onNonRestrictedTransfer={handleNonRestrictedTransfer}
              onWithdrawal={() => {
                onSetSelectedWalletId(wallet.id)
                handleWithdrawalNavigation({ walletId: wallet.id })
              }}
              onSetModalVisible={onSetModalVisible}
              isLoading={walletWithdrawLoadingId === wallet.id}
            />
          ))}
        </div>
      )}
      <WalletTable
        getReservedData={getReservedData}
        shouldShowReservedBonus={shouldShowReservedBonus}
        wallets={wallets}
        onSetModalVisible={onSetModalVisible}
        onSetSelectedWalletId={onSetSelectedWalletId}
        onDeposit={handleDeposit}
        // tradingAccountStatusLoading={tradingAccountStatusLoading}
        hasNegativeBalance={hasNegativeBalance}
        onNonRestrictedTransfer={handleNonRestrictedTransfer}
        handleWithdrawalNavigation={handleWithdrawalNavigation}
        walletWithdrawLoadingId={walletWithdrawLoadingId}
      />
    </>
  )
}
