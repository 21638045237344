import { useCallback, useMemo } from 'react'
import { NavigateFunction, matchPath, useLocation, useNavigate } from 'react-router-dom'

import { useProfileRoutesConditions } from '../Profile/ProfileRoutes'
import { useTraderRoomRoutesConditions } from '../Traders-Room/TradersRoomRoutes'
import { useProductReadContext } from '../global/context/ProductContext'
import {
  accountHasIntroducingBroker,
  showClosedAccountPage,
  showInactiveAccountPage,
} from '../model/AccountDetailedDto'
import {
  TickmillProductType,
  isTickmillProductTypeCFD,
  isTickmillProductTypeETD,
} from '../model/TickmillProductType'
import { isAccountProofOfIdentificationVerified } from './AccountAccess/accountCompanyStatuses'
import {
  hasConsentedToLegalDocuments,
  isAccountDocumentManagement1MonthExpired,
  isAccountDocumentManagementExpired,
} from './AccountAccess/accountDocumentStatuses'
import {
  isDepositBeforeKYC,
  isKYCUpdateDue,
  requireDepositBeforeKYC,
} from './AccountAccess/accountKYCStatuses'
import {
  hasClearedAppropriatenessTest,
  hasProductActivatedStatus,
  hasProductClosedStatus,
  hasProductDormantStatus,
  hasProductEmailVerifiedStatus,
  hasSubmittedAppropriatenessTest,
} from './AccountAccess/accountProductStatuses'
import { useAccountReadContext } from './AccountContextContext'
import { isTickmillPartner } from './companyName.utils'
import { useLandingPage } from './useLandingPage'

export const noAuthPaths = [
  '/login',
  '/logout',
  '/support',
  '/forgot-password',
  '/sign-up',
  '/marketing-form',
]

export const outDatedBrowserRoutes = [
  '/dashboard/traders-room/wallets',
  '/dashboard/traders-room/trading-accounts',
  '/dashboard/traders-room/transaction-history',
  '/dashboard/traders-room/balances',
  '/dashboard/traders-room/transaction-history',
  '/dashboard/subscriptions/market-data',
  '/dashboard/subscriptions/trading-platforms',
  '/dashboard/subscriptions/history',
  '/dashboard/statements/daily',
]

export const isSignUpPath = (path: string): boolean => {
  const signUpPaths = [
    '/sign-up',
    '/logout',
    '/login',
    '/support',
    '/forgot-password',
    '/users/reset-password',
  ]
  return signUpPaths.filter((filteredPath) => path.includes(filteredPath)).length > 0
}

export const isSignUpFirstStepsPath = (path: string): boolean => {
  const signUpPaths = ['sign-up']
  return signUpPaths.filter((filteredPath) => path.includes(filteredPath)).length > 0
}

export const isRegisterPath = (path: string): boolean => {
  const registerPaths = ['/users/register2', '/users/register3', '/users/register4']
  return registerPaths.filter((filteredPath) => path.includes(filteredPath)).length > 0
}

export const isVerifyPhoneEmailPath = (path: string): boolean => {
  const verifyPhoneEmailPaths = [
    '/sign-up/personal-details/verify-phone/',
    '/sign-up/personal-details/verify-email/',
  ]
  return verifyPhoneEmailPaths.filter((filteredPath) => path.includes(filteredPath)).length > 0
}

export const isNoTabsPath = (path: string, extraTabs?: string[]): boolean => {
  const noTabsPaths = [
    '/deposit',
    '/withdrawal',
    '/transfer',
    '/conversion',
    '/trading-accounts/new',
    '/trading-accounts/new/demo',
    '/change-password',
    '/demo/add-balance',
    '/dashboard/first-deposit',
    '/sign-up/documents-info',
    '/sign-up/first-time-deposit',
    '/sign-up/confirm',
    '/dashboard/product-registration/appropriateness-test',
    '/dashboard/product-registration/legal-documents',
    '/dashboard/product-registration/kyc-personal-details',
    '/2fa/confirm',
  ]
  const mergedNoTabsPaths = noTabsPaths.concat(extraTabs || [])
  return mergedNoTabsPaths.some((filteredPath) => path.includes(filteredPath))
}

export const getPath = (
  path: string
): {
  main: string
  sub: string
  tab?: string
} => {
  const paths = path.split('/')
  return {
    main: paths[1],
    sub: paths[2],
    tab: paths[3],
  }
}

export enum CommonPaths {
  ClosedAccount = '/dashboard/closed-account',
  InactiveAccount = '/dashboard/inactive-account',
  FirstTimeDepositDashboard = '/dashboard/first-deposit',
  FirstTimeDeposit = '/profile/first-time-deposit',
  FirstTimeDepositLoading = '/first-time-deposit/loading',
  DocumentManagement = '/profile/document-management',
  IntroducingBroker = '/dashboard/introducing-broker/wallets',
  IntroducingBrokerDashboard = '/dashboard/introducing-broker/dashboard',
  PaymentAgents = '/dashboard/payment-agent/wallets',
  TradersRoomWallets = '/dashboard/traders-room/wallets',
  TradersRoomBalances = '/dashboard/traders-room/balances',
  Dashboard = '/dashboard',
  ProductRegistrationLegalDocs = '/dashboard/product-registration/legal-documents',
  KycUpdateDetails = '/profile/kyc-update-details',
}

enum BasePaths {
  AppTest = '/profile/appropriateness-test',
  TakeAppTest = '/dashboard/take-app-test',
}

export const useBasePath = (isLogoPath = false): string => {
  const { account } = useAccountReadContext()
  const { isTradersRoomVisible } = useTraderRoomRoutesConditions()
  const visible = useProfileRoutesConditions()

  const { getLandingPage } = useLandingPage()
  const { product } = useProductReadContext()

  return useMemo(() => {
    if (!account) {
      return CommonPaths.Dashboard
    }
    if (isDepositBeforeKYC(account) && isLogoPath && isTradersRoomVisible) {
      return CommonPaths.TradersRoomWallets
    }
    if (!hasConsentedToLegalDocuments(account, product)) {
      return CommonPaths.ProductRegistrationLegalDocs
    } else if (
      hasProductEmailVerifiedStatus(account, product) ||
      !hasClearedAppropriatenessTest(account, product)
    ) {
      if (
        hasSubmittedAppropriatenessTest(account, product) ||
        hasProductDormantStatus(account, product) ||
        hasProductClosedStatus(account, product)
      ) {
        return BasePaths.AppTest
      } else {
        return BasePaths.TakeAppTest
      }
    } else if (showClosedAccountPage(account, product)) {
      return CommonPaths.ClosedAccount
    } else if (showInactiveAccountPage(account, product)) {
      return CommonPaths.InactiveAccount
    } else if (requireDepositBeforeKYC(account) && visible.isFirstTimeDepositVisible) {
      return CommonPaths.FirstTimeDeposit
    } else if (isKYCUpdateDue(account)) {
      return CommonPaths.KycUpdateDetails
    } else if (
      !hasProductActivatedStatus(account, product) &&
      !(isDepositBeforeKYC(account) && isAccountProofOfIdentificationVerified(account))
    ) {
      return CommonPaths.DocumentManagement
    } else if (isAccountDocumentManagement1MonthExpired(account)) {
      return CommonPaths.DocumentManagement
    } else if (isAccountDocumentManagementExpired(account)) {
      return CommonPaths.DocumentManagement
    } else if (isTradersRoomVisible) {
      return getLandingPage()
    } else if (isTickmillPartner(account)) {
      return CommonPaths.IntroducingBrokerDashboard
    } else if (accountHasIntroducingBroker(account)) {
      return CommonPaths.IntroducingBroker
    }
    return CommonPaths.Dashboard
  }, [account, getLandingPage, isLogoPath, isTradersRoomVisible, product])
}

export const useProductSwitchPath = (): {
  navigateToNextPath: (product: TickmillProductType) => void
} => {
  const { account } = useAccountReadContext()
  const { isTradersRoomVisible } = useTraderRoomRoutesConditions()

  const navigate = useNavigate()
  const { getLandingPage } = useLandingPage()

  const getPath = useCallback(
    (product: TickmillProductType): string => {
      if (!account) {
        return CommonPaths.Dashboard
      } else if (!hasConsentedToLegalDocuments(account, product)) {
        return CommonPaths.ProductRegistrationLegalDocs
      } else if (hasConsentedToLegalDocuments(account, product)) {
        if (isTickmillProductTypeETD(product)) {
          return CommonPaths.TradersRoomBalances
        } else if (isTickmillProductTypeCFD(product)) {
          return getLandingPage(product)
        }
        return CommonPaths.Dashboard
      } else if (showClosedAccountPage(account, product)) {
        return CommonPaths.ClosedAccount
      } else if (showInactiveAccountPage(account, product)) {
        return CommonPaths.InactiveAccount
      } else if (!hasProductActivatedStatus(account, product)) {
        return CommonPaths.DocumentManagement
      } else if (isAccountDocumentManagement1MonthExpired(account)) {
        return CommonPaths.DocumentManagement
      } else if (isAccountDocumentManagementExpired(account)) {
        return CommonPaths.DocumentManagement
      } else if (isTradersRoomVisible) {
        return getLandingPage(product)
      } else if (accountHasIntroducingBroker(account)) {
        return CommonPaths.IntroducingBroker
      } else if (isTickmillPartner(account)) {
        return CommonPaths.IntroducingBroker
      }

      return CommonPaths.Dashboard
    },
    [account, getLandingPage, isTradersRoomVisible]
  )

  return {
    navigateToNextPath: (product) => navigate(getPath(product)),
  }
}

export const useActionPage = (): boolean => {
  const location = useLocation()
  const { isDefaultCFDProductType } = useProductReadContext()
  const paths = useMemo(
    () =>
      isDefaultCFDProductType()
        ? [
            'dashboard/traders-room/wallets/:id/deposit',
            'dashboard/traders-room/wallets/deposit',
            'dashboard/traders-room/wallets/:id/transfer',
            'dashboard/traders-room/wallets/transfer',
            'dashboard/traders-room/wallets/:id/withdrawal',
            'dashboard/traders-room/wallets/withdrawal',
            'dashboard/traders-room/trading-accounts/:id/demo/add-balance',
            'dashboard/traders-room/trading-accounts/new',
            'dashboard/traders-room/trading-accounts/new/demo',
            'profile/bank-accounts/new',
          ]
        : [
            'dashboard/traders-room/wallets/:id/deposit',
            'dashboard/traders-room/wallets/deposit',
            'dashboard/traders-room/wallets/:id/transfer',
            'dashboard/traders-room/balances/:id/transfer',
            'dashboard/traders-room/wallets/transfer',
            'dashboard/traders-room/wallets/:id/withdrawal',
            'dashboard/traders-room/wallets/withdrawal',
            'dashboard/traders-room/transactions/conversion',
            'profile/bank-accounts/new',
          ],
    [isDefaultCFDProductType]
  )
  return useMemo(
    () => paths.some((path) => matchPath({ path }, location.pathname)),
    [location, paths]
  )
}

export const navigateBasedOnProductType = (
  isProductTypeCFD: boolean,
  navigate: NavigateFunction,
  isPartnerAccount?: boolean
): void => {
  const walletsPath = '/dashboard/traders-room/wallets'
  const balancesPath = '/dashboard/traders-room/balances'
  const partnersPath = '/dashboard/introducing-broker/wallets'

  const selectedPath = isProductTypeCFD
    ? isPartnerAccount
      ? partnersPath
      : walletsPath
    : balancesPath

  navigate(selectedPath)
}
