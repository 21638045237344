import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useMatch, useOutletContext } from 'react-router-dom'

import { Button } from '../../global/button/Button'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { useHomePageLink } from '../../hooks/useHomePageUrl'
import { ScrollToIds, useScrollToElementIds } from '../../hooks/useScrollToElementIds'
import { ChartBarIcon } from '../../icons/ChartBarIcon'
import { ChartLineUpIcon } from '../../icons/ChartLineUpIcon'
import { ChatsCircleIcon } from '../../icons/ChatsCircleIcon'
import { DevicesIcon } from '../../icons/DevicesIcon'
import { DonutChartIcon } from '../../icons/DonutChartIcon'
import { SquaresFourIcon } from '../../icons/SquaresFourIcon'
import { Step1Icon } from '../../icons/Step1Icon'
import { Step2Icon } from '../../icons/Step2Icon'
import { Step3Icon } from '../../icons/Step3Icon'
import { Step4Icon } from '../../icons/Step4Icon'
import { PlatformInfoDto } from '../../model/PlatformInfoDto'
import { formatPlatformName, getPlatformTypes } from '../../model/PlatformTypeEnum'
import { Text, TextH2, TextH3, TextH4 } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import tradingviewChart from '../images/TradingView/tradingview-chart.webp'
import tradingviewRightDesktop from '../images/TradingView/tradingview-right-desktop.webp'
import tradingviewRightMobile from '../images/TradingView/tradingview-right-mobile.webp'
import { PlatformDownloadButton } from './PlatformIcon'
import { usePlatformTradingViewDownloadLinksData } from './platformsTradingViewData'

import styles from './PlatformsTradingViewPage.module.scss'

interface TradingViewProps {
  platformInfo: PlatformInfoDto[]
}

export const PlatformsTradingViewPage: React.FC = () => {
  const { platformInfo = [] } = useOutletContext<TradingViewProps>()

  const isMobile = useWindowResize()
  const { scrollIntoView } = useScrollToElementIds()
  const locale = useSessionLanguage()

  const match = useMatch(`/${locale}/dashboard/tools/platforms/:platformType`)
  const platformType = match?.params?.platformType || ''

  useEffect(() => {
    const platformNames = getPlatformTypes().map((x) => x.shortName)
    if (platformNames.some((name) => platformType.includes(formatPlatformName(name)))) {
      scrollIntoView([ScrollToIds.ToolsPlatformsHeader])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformType])

  return isMobile ? (
    <TradingViewMobile platformInfo={platformInfo} />
  ) : (
    <TradingViewDesktop platformInfo={platformInfo} />
  )
}

const TradingViewMobile: React.FC<TradingViewProps> = (props) => {
  const { platformInfo } = props

  const { t } = useTranslation()
  const homePageUrl = useHomePageLink()

  return (
    <>
      <div className={styles.downloadSection}>
        <img src={tradingviewRightMobile} className={styles.hasMarginBottom} alt='' />
        <TextH2 isParagraph className={styles.hasMarginBottom}>
          {t('ToolsPlatforms.Unleash your trading skills with TradingView')}
        </TextH2>
        <Text className={styles.textSecondary} isParagraph>
          {t('ToolsPlatforms.TradingViewInfo.1')} {t('ToolsPlatforms.TradingViewInfo.2')}{' '}
          {t('ToolsPlatforms.TradingViewInfo.3')}
        </Text>
        <div className={styles.linksSectionAbout}>
          <div className={styles.linksSection}>
            <DownloadLinks platformInfo={platformInfo} />
          </div>
        </div>
      </div>

      <div className='p-4'>
        <div className={styles.analysisHeader}>
          <DonutChartIcon />
          <TextH4>{t('ToolsPlatforms.Advanced visual analysis')}</TextH4>
        </div>
        <Text className={styles.textSecondary}>
          {t(
            'ToolsPlatforms.Enhance an informed trading approach with over 110+ intelligent drawing tools'
          )}
        </Text>
      </div>

      <div className='p-4'>
        <div className={styles.analysisHeader}>
          <SquaresFourIcon />
          <TextH4>{t('ToolsPlatforms.Widest choice of indicators')}</TextH4>
        </div>
        <Text className={styles.textSecondary}>
          {t('ToolsPlatforms.The technical analysis brings limitless opportunities')}
        </Text>
      </div>

      <div className='p-4'>
        <div className={styles.analysisHeader}>
          <ChartBarIcon />
          <TextH4>{t('ToolsPlatforms.Unparalleled charting interface')}</TextH4>
        </div>
        <Text className={styles.textSecondary}>
          {t('ToolsPlatforms.Regardless of where you are in your trading path')}
        </Text>
      </div>

      <div className={styles.mobileasd3}>
        <img src={tradingviewChart} alt='' className={styles.tradingChart} />
        <TextH2 isParagraph className={styles.hasMarginTop}>
          {t('ToolsPlatforms.Connect TradingView with your Tickmill Account')}
        </TextH2>
      </div>

      <div className={styles.stepsSection}>
        <div className={styles.step}>
          <Step1Icon />
          <Text className={styles.textSecondary}>
            {t(
              'ToolsPlatforms.Create a new TradingView trading account or use an existing one, and open a free user profile on TradingView'
            )}
          </Text>
        </div>
        <div className={styles.step}>
          <Step2Icon />
          <Text className={styles.textSecondary}>
            {t(
              'ToolsPlatforms.Find the Tickmill broker profile on TradingView and click the Trade button'
            )}
          </Text>
        </div>
        <div className={styles.step}>
          <Step3Icon />
          <Text className={styles.textSecondary}>
            {t(
              'ToolsPlatforms.Enter your TradingView account credentials and connect to the platform'
            )}
          </Text>
        </div>
        <div className={styles.step}>
          <Step4Icon />
          <Text className={styles.textSecondary}>
            {t(
              'ToolsPlatforms.You are now all set to trade simultaneously on all TradingViews platforms'
            )}
          </Text>
        </div>
      </div>

      <TextH2>{t('ToolsPlatforms.TradingView platform features')}</TextH2>

      <div>
        <div className={styles.featureHeader}>
          <DevicesIcon />
          <TextH3>{t('ToolsPlatforms.Multi-platform experience')}</TextH3>
        </div>
        <Text className={styles.textSecondary}>
          {t('ToolsPlatforms.Take advantage of TradingViews powerful browser')}
        </Text>
      </div>

      <div>
        <div className={styles.featureHeader}>
          <ChartLineUpIcon />
          <TextH3>{t('ToolsPlatforms.Charts that move markets')}</TextH3>
        </div>
        <Text className={styles.textSecondary}>
          {t('ToolsPlatforms.Whether youd like to simply look up the latest stock price')}
        </Text>
      </div>

      <div>
        <div className={styles.featureHeader}>
          <ChatsCircleIcon />
          <TextH3>{t('ToolsPlatforms.TradingView community')}</TextH3>
        </div>
        <Text className={styles.textSecondary}>
          {t('ToolsPlatforms.Get inspiration for your next trade. Dont reinvent the wheel')}
        </Text>
      </div>

      <div className={styles.buttonSection}>
        <Link className={styles.learnMoreLink} to={`${homePageUrl}/trading-platforms/tradingview`}>
          <Button appearance='primary' size='M' type='button' fullWidth className={styles.button}>
            {t('Learn More')}
          </Button>
        </Link>
      </div>
    </>
  )
}

const TradingViewDesktop: React.FC<TradingViewProps> = (props) => {
  const { platformInfo } = props

  const { t } = useTranslation()

  const homePageUrl = useHomePageLink()

  return (
    <div>
      <div className={styles.container}>
        <div className={styles.downloadSection}>
          <div className={styles.textSection}>
            <TextH2 isParagraph>
              {t('ToolsPlatforms.Unleash your trading skills with TradingView')}
            </TextH2>
            <Text className={styles.textSecondary}>{t('ToolsPlatforms.TradingViewInfo.1')}</Text>
            <br />
            <Text className={styles.textSecondary}>{t('ToolsPlatforms.TradingViewInfo.2')}</Text>
            <br />
            <Text className={styles.textSecondary}>{t('ToolsPlatforms.TradingViewInfo.3')}</Text>
            <div className={styles.linksSection}>
              <DownloadLinks platformInfo={platformInfo} />
            </div>
          </div>
          <div>
            <img src={tradingviewRightDesktop} alt='' className={styles.rightSection} />
          </div>
        </div>
      </div>

      <div className={styles.analysisSection}>
        <div className={styles.item}>
          <div className={styles.analysisHeader}>
            <DonutChartIcon />
            <TextH4>{t('ToolsPlatforms.Advanced visual analysis')}</TextH4>
          </div>
          <Text className={styles.textSecondary}>
            {t(
              'ToolsPlatforms.Enhance an informed trading approach with over 110+ intelligent drawing tools'
            )}
          </Text>
        </div>
        <div className={styles.item}>
          <div className={styles.analysisHeader}>
            <SquaresFourIcon />
            <TextH4>{t('ToolsPlatforms.Widest choice of indicators')}</TextH4>
          </div>
          <Text className={styles.textSecondary}>
            {t('ToolsPlatforms.The technical analysis brings limitless opportunities')}
          </Text>
        </div>
        <div className={styles.item}>
          <div className={styles.analysisHeader}>
            <ChartBarIcon />
            <TextH4>{t('ToolsPlatforms.Unparalleled charting interface')}</TextH4>
          </div>
          <Text className={styles.textSecondary}>
            {t('ToolsPlatforms.Regardless of where you are in your trading path')}
          </Text>
        </div>
      </div>

      <div className={styles.connectSection}>
        <div className={styles.chartContainer}>
          <img src={tradingviewChart} alt='' className={styles.tradingChart} />
        </div>
        <div className={styles.stepsContainer}>
          <div className={styles.stepsHeader}>
            <TextH2>{t('ToolsPlatforms.Connect TradingView with your Tickmill Account')}</TextH2>
          </div>
          <div className={styles.step}>
            <Step1Icon />
            <Text className={styles.textSecondary}>
              {t(
                'ToolsPlatforms.Create a new TradingView trading account or use an existing one, and open a free user profile on TradingView'
              )}
            </Text>
          </div>
          <div className={styles.step}>
            <Step2Icon />
            <Text className={styles.textSecondary}>
              {t(
                'ToolsPlatforms.Find the Tickmill broker profile on TradingView and click the Trade button'
              )}
            </Text>
          </div>
          <div className={styles.step}>
            <Step3Icon />
            <Text className={styles.textSecondary}>
              {t(
                'ToolsPlatforms.Enter your TradingView account credentials and connect to the platform'
              )}
            </Text>
          </div>
          <div className={styles.step}>
            <Step4Icon />
            <Text className={styles.textSecondary}>
              {t(
                'ToolsPlatforms.You are now all set to trade simultaneously on all TradingViews platforms'
              )}
            </Text>
          </div>
        </div>
      </div>

      <div className={styles.title}>
        <TextH2>{t('ToolsPlatforms.TradingView platform features')}</TextH2>
      </div>

      <div className={styles.featuresSection}>
        <div className={styles.item}>
          <div className={styles.featureHeader}>
            <DevicesIcon />
            <TextH3>{t('ToolsPlatforms.Multi-platform experience')}</TextH3>
          </div>
          <Text className={styles.textSecondary}>
            {t('ToolsPlatforms.Take advantage of TradingViews powerful browser')}
          </Text>
        </div>
        <div className={styles.item}>
          <div className={styles.featureHeader}>
            <ChartLineUpIcon />
            <TextH3>{t('ToolsPlatforms.Charts that move markets')}</TextH3>
          </div>
          <Text className={styles.textSecondary}>
            {t('ToolsPlatforms.Whether youd like to simply look up the latest stock price')}
          </Text>
        </div>
        <div className={styles.item}>
          <div className={styles.featureHeader}>
            <ChatsCircleIcon />
            <TextH3>{t('ToolsPlatforms.TradingView community')}</TextH3>
          </div>
          <Text className={styles.textSecondary}>
            {t('ToolsPlatforms.Get inspiration for your next trade. Dont reinvent the wheel')}
          </Text>
        </div>
      </div>

      <div className={styles.buttonSection}>
        <Link target='_blank' to={`${homePageUrl}/trading-platforms/tradingview`}>
          <Button appearance='primary' size='L' type='button' className={styles.button}>
            {t('Learn More')}
          </Button>
        </Link>
      </div>
    </div>
  )
}

const DownloadLinks: React.FC<TradingViewProps> = (props) => {
  const { platformDownloadLinksData } = usePlatformTradingViewDownloadLinksData(props.platformInfo)

  return (
    <>
      {platformDownloadLinksData.map((x) => (
        <PlatformDownloadButton className={styles.hasMarginRight} to={x.link}>
          {x.icon}
        </PlatformDownloadButton>
      ))}
    </>
  )
}
