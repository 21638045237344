import React, { useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'

import { useEntitySettings } from '../../global/context/EntityContext'
import { useProductReadContext } from '../../global/context/ProductContext'
import { useSignup } from '../../global/context/SignupContext'
import { hasProductEmailVerifiedStatus } from '../../utils/AccountAccess/accountProductStatuses'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { AccountTypeStep } from './Pages/AccountType/AccountType'
import { CompanyDetailsOrchestrator } from './Pages/CompanyDetails/CompanyDetailsOrchestrator'
import { ConfirmPage } from './Pages/ConfirmPage/ConfirmPage'
import { DocumentsInfoPage } from './Pages/DocumentsInfo/DocumentsInfoPage'
import { FinancialInfoPage } from './Pages/FinancialInfo/FinancialInfoPage'
import { PersonalDetailsOrchestrator } from './Pages/PersonalDetails/PersonalDetailsOrchestrator'
import { PersonalDetailsStepUSPage } from './Pages/PersonalDetails/PersonalDetailsStep1/PersonalDetailsStepUSPage'
import { LeadCreationPage } from './Pages/PersonalDetails/PersonalDetailsStep4/LeadCreationPage'
import { ResendEmailPage } from './Pages/PersonalDetails/PersonalDetailsStep4/ResendEmailPage'
import { VerifyEmailPage } from './Pages/PersonalDetails/PersonalDetailsStep4/VerifyEmailPage'
import { VerifyPhoneNumberPage } from './Pages/PersonalDetails/VerifyPhoneNumber/VerifyPhoneNumberPage'

export const SignupPage: React.FC = () => {
  const { product } = useProductReadContext()
  const { account } = useAccountReadContext()
  const { signupData } = useSignup()
  const [entity, setEntity] = useEntitySettings()

  useEffect(() => {
    if (signupData.lead) {
      if (signupData.lead.tickmillCompany.id) {
        if (entity.entity !== signupData.lead.tickmillCompany.id) {
          setEntity({ entity: signupData.lead.tickmillCompany.id })
        }
      }
    }
  }, [entity.entity, setEntity, signupData.lead])

  return (
    <Routes>
      <Route index element={<AccountTypeStep />} />
      {!!account &&
        !hasProductEmailVerifiedStatus(account, product) &&
        ['financial-documents-info/*', 'documents-info/*'].map((path) => (
          <Route key={path} path={path} element={<Navigate to='/dashboard/traders-room' />} />
        ))}

      <Route path='personal-details'>
        <Route path='verify-phone/:leadId' element={<VerifyPhoneNumberPage />} />
        <Route path='us-citizen' element={<PersonalDetailsStepUSPage />} />
        <Route path='verify-email'>
          <Route path='resend' element={<ResendEmailPage />} />
          <Route path='confirm' element={<LeadCreationPage />} />
          <Route index element={<VerifyEmailPage />} />
        </Route>
        <Route path=':passedStep?' element={<PersonalDetailsOrchestrator />} />
      </Route>
      <Route path='company-details/:step?' element={<CompanyDetailsOrchestrator />} />
      <Route path='financial-info/:clientId' element={<FinancialInfoPage />} />
      <Route path='documents-info/:clientId' element={<DocumentsInfoPage />} />
      <Route path='confirm' element={<ConfirmPage />} />
      <Route path='*' element={<Navigate to='/sign-up' />} />
    </Routes>
  )
}
