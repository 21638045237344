import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateFunction, NavigateOptions, useLocation, useNavigate } from 'react-router-dom'
import Cookies from 'js-cookie'

import { ResetPasswordCompleteFormValues } from '../ForgotPassword/ResetPasswordPage'
import { LoginFormValues } from '../Login/LoginForm'
import { useSignInAuth } from '../Login/useSignInAuth'
import { ChangePasswordFormValues } from '../Profile/AccountSettings/ChangePasswordForm'
import { handleBaseTwoFactorErrors } from '../Profile/AccountSettings/handleBaseTwoFactorErrors'
import { useSnackbar } from '../global/context/SnackbarContext'
import { Modal } from '../global/modal/Modal'
import { useLogout } from '../hooks/useLogout'
import { InitWalletToPspWithdrawTransactionCommand } from '../model/InitWalletToPspWithdrawTransactionCommand'
import { MasterTransaction } from '../model/MasterTransaction'
import { NameDto } from '../model/NameDto'
import { PaymentProviderType } from '../model/PaymentProviderType'
import {
  isTransactionStatusSuccessful,
  isTransactionStatusUnSuccessful,
} from '../model/TransactionStatus'
import {
  TwoFAProviderEnum,
  TwoFAProviderItem,
  TwoFactorVerificationPayload,
} from '../model/TwoFactorAuthentication'
import { AuthUser, LoginResponse, TwoFactor } from '../model/User'
import { errorOptions } from '../routing/RouterToaster'
import { useAccountReadContext } from '../utils/AccountContextContext'
import { ResponseError, useApiClient } from '../utils/ApiClient'
import { usePathHistoryContext } from '../utils/PathHistoryContext'
import { ClientApiClient } from '../utils/clientApi'
import { TickmillCompaniesEnum, isTickmillPartner } from '../utils/companyName.utils'
import { loginToAuth } from '../utils/loginToAuth'
import { TokenStorage } from '../utils/tokenStorage'
import { wait } from '../utils/wait'
import { RecoveryCodeModal } from './RecoveryCodeModal'
import { TrustModal } from './TrustModal'
import { Resend2FAError } from './TwoFactorAuthPage'

interface UseTwoFactorAuthReturn {
  twoFactor: TwoFactor | undefined
  resendCode: () => Promise<void>
  handleVerifyCode: (code: string) => Promise<void>
  error: string | undefined
  status: 'success' | 'error' | undefined
  setRecoveryCodeModal: (val: boolean) => void
  disableResend: boolean
  renderTrustModal: () => JSX.Element
  renderRecoveryModal: () => JSX.Element
  initialCountdown: number
  resendError: Resend2FAError
  handleTimer: () => {
    startResetTimer: () => (() => void) | undefined
    startInitialTimer: () => () => void
  }
  stateChecker: () => {
    effect: () => void
    dependants: (TwoFactorAuthLocationState | NavigateFunction)[]
  }
}

export enum TwoFactorErrors {
  InvalidToken = 'two_factor_authentication_invalid_token',
  TooManyAttempts = 'two_factor_auth_too_many_resend_code_attempts',
  TooManyInputs = 'two_factor_auth_too_many_insert_code_attempts',
  TooManySends = 'two_factor_auth_too_many_insert_resend_code_attempts',
  TokenExpired = 'two_factor_authentication_expired_token',
}

export interface TwoFactorAuthLocationState {
  twoFactorAuthData?: LoginResponse & {
    twoFactorAuthEvent: TwoFactorAuthEvents
    password: string
    newPassword?: string
    actionTypeId?: number
    urlToken?: string
    twoFactor: TwoFactor[]
    withdrawalData?: {
      withdrawalPayload: Omit<
        InitWalletToPspWithdrawTransactionCommand,
        'caller' | 'clientIpAddress'
      >
      withdrawalProviderCategoryId: PaymentProviderType
    }
    previousActive2FAProviders?: TwoFAProviderItem[]
    isWithdrawFromIBOrPartners?: boolean
  }
  email?: string
  newPassword?: string
}

interface TwoFactorArguments {
  code: string
  token: string
  provider: NameDto
  typeId?: number
  actionTypeId?: number
  credentials?: { email: string; password: string }
  newPassword?: string
  urlToken?: string
  withdrawalData?: {
    withdrawalPayload: Omit<InitWalletToPspWithdrawTransactionCommand, 'caller' | 'clientIpAddress'>
    withdrawalProviderCategoryId: PaymentProviderType
  }
}

type WithTwoFactor<T> = T & { twoFactor: TwoFactorVerificationPayload }
type TwoFactorHandler = (args: TwoFactorArguments) => void

type LoginParams = WithTwoFactor<LoginFormValues>
type ChangePasswordParams = WithTwoFactor<ChangePasswordFormValues>
type ResetPasswordParams = WithTwoFactor<ResetPasswordCompleteFormValues>
type TwoFAProviderEnableParams = WithTwoFactor<{ typeId: number }>
type TwoFAProviderDisableParams = WithTwoFactor<{ typeId: number }>

export enum TwoFactorAuthEvents {
  LogIn = 'login',
  EnableProvider = 'enableProvider',
  DisableProvider = 'disableProvider',
  EnableAuthAction = 'enableAuthAction',
  DisableAuthAction = 'disableAuthAction',
  ChangePassword = 'changePassword',
  ResetPassword = 'resetPassword',
  Withdrawal = 'withdrawal',
}

const usersAuthPrevalidated = true

export const useTwoFactorAuth = (): UseTwoFactorAuthReturn => {
  const location = useLocation()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { addSnackbar } = useSnackbar()
  const { account } = useAccountReadContext()
  const { logout } = useLogout()
  const { handleSignInAuth } = useSignInAuth()
  const { pathHistory } = usePathHistoryContext()
  const apiClient = useApiClient(ClientApiClient)
  const locationState = location.state as TwoFactorAuthLocationState

  const [error, setError] = useState<string>()
  const [trustModal, setTrustModal] = useState(false)
  const [authData, setAuthData] = useState<AuthUser>()

  const [credentials, setCredentials] = useState<{ email: string; password: string }>()
  const [twoFactor, setTwoFactor] = useState<TwoFactor>()
  const [disableResend, setDisableResend] = useState(false)
  const [initialCountdown, setInitialCountdown] = useState(30)
  const [recoveryCodeModal, setRecoveryCodeModal] = useState(false)
  const [status, setStatus] = useState<'success' | 'error' | undefined>()
  const [showAgain, setShowAgain] = useState(Cookies.get('showTrustAgain') !== 'false')
  const defaultResendErrorState = { hideResend: false, errorTimer: 0, tooManyInputs: false }
  const [resendError, setResendError] = useState<Resend2FAError>(defaultResendErrorState)
  const [codeResendCount, setCodeResendCount] = useState(0)

  const handleSuccessfulLoginPathChange = (auth: AuthUser) => {
    if (auth?.twoFactor) {
      if (auth.tickmillCompany.id === TickmillCompaniesEnum.TICKMILL_PA) {
        navigate('/dashboard/introducing-broker/dashboard')
      } else {
        navigate('/dashboard')
      }
    }
  }

  const stateChecker = () => {
    const effect = () => {
      const twoFactorAuthData = locationState?.twoFactorAuthData

      if (twoFactorAuthData?.email && twoFactorAuthData?.password) {
        setCredentials({ email: twoFactorAuthData.email, password: twoFactorAuthData.password })
      }
      if (twoFactorAuthData?.twoFactor?.length) {
        setTwoFactor(twoFactorAuthData.twoFactor[0])
      }
    }

    const dependants = [locationState, navigate]

    return { effect, dependants }
  }

  const handleTimer = () => {
    const startResetTimer = () => {
      if (resendError.hideResend) {
        let secondsElapsed = 0
        const totalSeconds = 30 * 60
        const timer = setTimeout(() => {
          setResendError((prevState) => ({ ...prevState, hideResend: false }))
        }, totalSeconds * 1000)

        const interval = setInterval(() => {
          secondsElapsed += 1
          const remainingSeconds = totalSeconds - secondsElapsed
          setResendError((prevState) => ({ ...prevState, errorTimer: remainingSeconds }))
        }, 1000)

        return () => {
          clearTimeout(timer)
          clearInterval(interval)
        }
      }
    }

    const startInitialTimer = () => {
      const interval = setInterval(() => {
        setInitialCountdown((prevSeconds) => {
          if (prevSeconds <= 1) {
            clearInterval(interval)
            return 0
          }
          return prevSeconds - 1
        })
      }, 1000)

      return () => clearInterval(interval)
    }

    return { startResetTimer, startInitialTimer }
  }

  const resendCode = async () => {
    if (!twoFactor) {
      return
    }
    setDisableResend(true)
    const { typeId, authMedium } = twoFactor
    const { password: currentPassword } = credentials || {}
    const { newPassword } = locationState?.twoFactorAuthData || {}

    const authEvent = locationState.twoFactorAuthData?.twoFactorAuthEvent
    const isEnable2FAProviderAuthEvent = TwoFactorAuthEvents.EnableProvider === authEvent && typeId
    const isChangePasswordAuthEvent =
      TwoFactorAuthEvents.ChangePassword === authEvent &&
      currentPassword &&
      typeof newPassword === 'string'

    try {
      if (isEnable2FAProviderAuthEvent) {
        await apiClient.enable2FAProviders({
          typeId: typeId,
          authMedium,
        })
      } else {
        const typeId = twoFactor.provider?.id ?? TwoFAProviderEnum.Email
        const { token } = twoFactor

        let twoFactorResponse

        if (isChangePasswordAuthEvent) {
          twoFactorResponse = await apiClient
            .updatePassword({
              currentPassword,
              newPassword: newPassword,
            })
            .then((response) => response?.twoFactor ?? [])
        } else {
          twoFactorResponse = await apiClient.resend2FACode({ typeId, token })
        }

        if (resendError.tooManyInputs) {
          setResendError({ errorTimer: 0, hideResend: false, tooManyInputs: false })
        }

        if (Array.isArray(twoFactorResponse) && twoFactorResponse.length) {
          setTwoFactor(twoFactorResponse[0])

          setCodeResendCount((prevCount) => prevCount + 1)
          addSnackbar.success({
            message: t('Sign up.Your new code has been sent'),
          })
          setInitialCountdown(30)
        } else {
          addSnackbar.error({ message: t('Sign up.Something went wrong') })
          if (codeResendCount === 4) {
            navigate('/profile/security-settings')
          }
        }
      }
    } catch (error: unknown) {
      handleBaseTwoFactorErrors(error, navigate)
    } finally {
      setDisableResend(false)
    }
  }

  const handleSuccessfulCodeVerificationExit = (
    navigateTo: string | (() => void),
    navigateOptions?: NavigateOptions
  ) => {
    setStatus('success')
    addSnackbar.success({ message: t('Correct code entered') })
    wait(1000).then(() => {
      if (typeof navigateTo === 'string') {
        navigate(navigateTo, navigateOptions ?? {})
      } else {
        navigateTo()
      }
    })
  }

  const handle2FALogin = async (loginParams: LoginParams) => {
    const response = await apiClient.login(loginParams)
    const authData = loginToAuth(response)
    if (!authData) {
      return
    }

    setAuthData(authData)
    setStatus('success')
    addSnackbar.success({ message: t('Correct code entered') })
    await wait()
    if (showAgain) {
      setTrustModal(true)
    } else {
      handleSignInAuth(authData, usersAuthPrevalidated)
      handleSuccessfulLoginPathChange(authData)
    }
  }

  const handle2FAPasswordChange = async (passwordChangeParams: ChangePasswordParams) => {
    await apiClient.updatePassword(passwordChangeParams)
    setStatus('success')
    addSnackbar.success({ message: t('Correct code entered') })
    await wait()

    logout()
    navigate('/login')
  }

  const handle2FAPasswordReset = async (
    passwordResetParams: Omit<ResetPasswordParams, 'confirmPassword'>
  ) => {
    await apiClient.resetPasswordComplete(passwordResetParams)

    handleSuccessfulCodeVerificationExit('/login')
  }

  const handleAuthActionToggle = async (
    isAuthActionEnabled: boolean,
    authActionToggleParams: {
      actionTypeId: number
      twoFactor: Required<TwoFactorVerificationPayload>
    }
  ) => {
    isAuthActionEnabled
      ? await apiClient.enable2FAProvidersActionType(authActionToggleParams)
      : await apiClient.disable2FAProvidersActionType(authActionToggleParams)

    handleSuccessfulCodeVerificationExit('/profile/security-settings')
  }

  const handle2FAProviderEnable = async (providerEnableParams: TwoFAProviderEnableParams) => {
    await apiClient.enable2FAProviders(providerEnableParams)

    handleSuccessfulCodeVerificationExit('/profile/security-settings', {
      state: {
        isProviderEnabled: true,
        previousActive2FAProviders: locationState?.twoFactorAuthData?.previousActive2FAProviders,
      },
    })
  }

  const handle2FAProviderDisable = async (providerDisableParams: TwoFAProviderDisableParams) => {
    await apiClient.disable2FAProviders(providerDisableParams)

    handleSuccessfulCodeVerificationExit('/profile/security-settings')
  }

  const handleWithdrawalTransactionResult = (
    data: MasterTransaction,
    walletId: string,
    providerCategoryId: PaymentProviderType
  ) => {
    const isPA = pathHistory[0].includes('/dashboard/payment-agent/')
    const isIBOrPartner =
      (isTickmillPartner(account) || account?.clientIntroducingBroker?.id) &&
      locationState?.twoFactorAuthData?.isWithdrawFromIBOrPartners

    const withdrawalWalletType = isPA
      ? 'payment-agent'
      : isIBOrPartner
      ? 'introducing-broker'
      : 'traders-room'
    const navigatePath = `/dashboard/${withdrawalWalletType}/wallets/${walletId}/withdrawal/transaction/succeed`

    if (isTransactionStatusSuccessful(data.state.id)) {
      handleSuccessfulCodeVerificationExit(() =>
        navigate(navigatePath, {
          state: {
            data,
            providerCategoryId,
          },
        })
      )
    }

    if (isTransactionStatusUnSuccessful(data.state.id)) {
      handleSuccessfulCodeVerificationExit(() =>
        navigate(`/dashboard/traders-room/wallets/${walletId}/withdrawal/transaction/failed`, {
          state: {
            data,
            providerCategoryId,
          },
        })
      )
    }
  }

  const handle2FAWithdrawal = async (
    withdrawalPayload: Omit<
      InitWalletToPspWithdrawTransactionCommand,
      'caller' | 'clientIpAddress'
    >,
    withdrawalProviderCategoryId: PaymentProviderType
  ) => {
    const response = await apiClient.transactionWithdraw(withdrawalPayload)

    handleWithdrawalTransactionResult(
      response,
      withdrawalPayload.fromWalletId,
      withdrawalProviderCategoryId
    )
  }

  const handleErrors = (error: ResponseError, navigate: NavigateFunction) => {
    const { data: errorData } = error.response.response || {}
    const passwordErrorText =
      errorData?.code === errorOptions.recently_used_password
        ? t('errors.Provided password was recently used already')
        : errorData?.code === errorOptions.invalid_new_password
        ? t('errors.New password cannot be the same as the old password')
        : errorData?.code === errorOptions.invalid_credentials
        ? t('errors.Invalid credentials')
        : ''

    handleBaseTwoFactorErrors(error, navigate)

    if (errorData?.code === TwoFactorErrors.TooManyInputs) {
      setResendError({ errorTimer: 0, hideResend: false, tooManyInputs: true })
    } else if (passwordErrorText) {
      setError(passwordErrorText)
      navigate('/profile/security-settings', { state: { isPasswordTabSelected: true } })
    } else {
      setError(t('Validation.Wrong code Verification failed'))
    }
    throw error
  }

  const twoFactorEventHandlers: Record<TwoFactorAuthEvents, TwoFactorHandler> = {
    [TwoFactorAuthEvents.LogIn]: async ({ token, code, provider, credentials }) => {
      if (!credentials) {
        return
      }

      await handle2FALogin({
        email: credentials.email,
        password: credentials.password,
        twoFactor: { typeId: provider.id, code, token },
      })
    },
    [TwoFactorAuthEvents.ChangePassword]: async ({
      token,
      code,
      provider,
      credentials,
      newPassword,
    }) => {
      if (!credentials || !newPassword) {
        return
      }

      await handle2FAPasswordChange({
        currentPassword: credentials.password,
        newPassword,
        twoFactor: { typeId: provider.id, code, token },
      })
    },
    [TwoFactorAuthEvents.ResetPassword]: async ({
      token,
      code,
      provider,
      credentials,
      urlToken,
    }) => {
      if (!credentials || !urlToken) {
        return
      }

      await handle2FAPasswordReset({
        password: credentials.password,
        email: credentials.email,
        token: urlToken,
        twoFactor: { typeId: provider.id, code, token },
      })
    },
    [TwoFactorAuthEvents.EnableProvider]: async ({ token, code, typeId }) => {
      if (!typeId) {
        return
      }

      await handle2FAProviderEnable({
        typeId,
        twoFactor: { token, code, typeId },
      })
    },
    [TwoFactorAuthEvents.DisableProvider]: async ({ token, code, provider }) => {
      await handle2FAProviderDisable({
        typeId: provider.id,
        twoFactor: { token, code, typeId: provider.id },
      })
    },
    [TwoFactorAuthEvents.EnableAuthAction]: async ({ token, code, provider, actionTypeId }) => {
      if (!actionTypeId) {
        return
      }

      await handleAuthActionToggle(true, {
        actionTypeId,
        twoFactor: { code, token, typeId: provider.id },
      })
    },
    [TwoFactorAuthEvents.DisableAuthAction]: async ({ token, code, provider, actionTypeId }) => {
      if (!actionTypeId) {
        return
      }

      await handleAuthActionToggle(false, {
        actionTypeId,
        twoFactor: { code, token, typeId: provider.id },
      })
    },
    [TwoFactorAuthEvents.Withdrawal]: async ({ token, code, provider, withdrawalData }) => {
      if (!withdrawalData) {
        return
      }

      const withdrawalPayload = {
        ...withdrawalData.withdrawalPayload,
        twoFactor: { code, token, typeId: provider.id },
      }
      await handle2FAWithdrawal(withdrawalPayload, withdrawalData.withdrawalProviderCategoryId)
    },
  }

  const handleVerifyCode = async (code: string) => {
    if (!twoFactor) {
      return
    }

    try {
      const { token, provider, typeId } = twoFactor
      const { twoFactorAuthEvent, actionTypeId, urlToken, newPassword, withdrawalData } =
        locationState?.twoFactorAuthData ?? {}

      const args: TwoFactorArguments = {
        code,
        token,
        provider,
        typeId,
        actionTypeId,
        credentials,
        newPassword,
        urlToken,
        withdrawalData,
      }

      if (twoFactorAuthEvent && twoFactorAuthEvent in twoFactorEventHandlers) {
        await twoFactorEventHandlers[twoFactorAuthEvent](args)
      }

      return
    } catch (e: unknown) {
      handleErrors(e as ResponseError, navigate)
    }
  }

  const handleRecoveryCode = async (recoveryCode: string) => {
    const email =
      locationState?.twoFactorAuthData?.email ||
      locationState?.email ||
      localStorage.getItem('2fa.email')
    if (!email) {
      return
    }
    setRecoveryCodeModal(false)

    try {
      const response = await apiClient.verifyRecoveryCode({ email, recoveryCode })
      localStorage.removeItem('2fa.email')
      const authData = loginToAuth(response)
      if (!authData) {
        return
      }

      setAuthData(authData)
      handleSignInAuth(authData, usersAuthPrevalidated)
      handleSuccessfulLoginPathChange(authData)
    } catch (error: unknown) {
      return
    }
  }

  const handleTrustDevice = async () => {
    if (authData) {
      handleSignInAuth(authData, usersAuthPrevalidated)

      const { token } = twoFactor || {}
      const { sessionId } = authData || {}
      if (token && sessionId) {
        const { trustUserDeviceToken } = await apiClient.trustDevice2FA({ token, sessionId })
        TokenStorage.storeToken('trustDevice', trustUserDeviceToken)
      }

      handleSuccessfulLoginPathChange(authData)
    }
  }

  const handleShowAgain = (val: boolean) => {
    Cookies.set('showTrustAgain', val.toString(), { expires: 30 })
    setShowAgain(val)
  }

  const renderTrustModal = () =>
    trustModal ? (
      <Modal
        closeModal={() => {
          setTrustModal(false)
          if (authData) {
            handleSignInAuth(authData, usersAuthPrevalidated)
          }
        }}
        render={({ closeModal }) => (
          <TrustModal
            dontShowAgain={!showAgain}
            setShowAgain={(e) => handleShowAgain(!e.target.checked)}
            onConfirm={() => handleTrustDevice()}
            onCancel={closeModal}
          />
        )}
      />
    ) : (
      <></>
    )

  const renderRecoveryModal = () =>
    recoveryCodeModal ? (
      <Modal
        closeModal={() => setRecoveryCodeModal(false)}
        render={() => <RecoveryCodeModal onConfirm={handleRecoveryCode} />}
      />
    ) : (
      <></>
    )

  return {
    twoFactor,
    renderRecoveryModal,
    resendCode,
    handleVerifyCode,
    error,
    status,
    setRecoveryCodeModal,
    disableResend,
    renderTrustModal,
    initialCountdown,
    resendError,
    handleTimer,
    stateChecker,
  }
}
