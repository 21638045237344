import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Form, FormikErrors, FormikProps, useFormikContext, withFormik } from 'formik'
import { t } from 'i18next'

import { WalletDepositPaymentProviderModal } from '../../../../global/WalletDepositPaymentProviderModal/WalletDepositPaymentProviderModal'
import { createFormField } from '../../../../global/formField/FormField'
import { CancelActionModal } from '../../../../global/modal/CancleActionModal'
import { Modal } from '../../../../global/modal/Modal'
import { FormTemplate } from '../../../../global/templates/FormTemplate'
import { FormatMoney, useFormatNumber } from '../../../../hooks/useFormatNumber'
import { BlueInfoIcon } from '../../../../icons/BlueInfoIcon'
import { DropArrowDownIcon } from '../../../../icons/DropArrowDownIcon'
import { isAccountActivatedStatus } from '../../../../model/AccountDetailedStatus'
import { PaymentProvider } from '../../../../model/PaymentProviderDto'
import {
  isPaymentProviderSCOrbitalCrypto,
  isPaymentProviderSCZotapayCrypto,
  isPaymentProviderSCZotapayCryptoMetablocks,
} from '../../../../model/PaymentProviderId'
import {
  PaymentProviderType,
  isPaymentProviderBankType,
  isPaymentProviderCardProviderType,
  isPaymentProviderNoneType,
  isPaymentProviderPSPType,
  isPaymentProviderPaymentAgentType,
  showPSPFields,
} from '../../../../model/PaymentProviderType'
import { TransactionDto } from '../../../../model/TransactionDto'
import { WalletDto, WalletTypeEnum } from '../../../../model/WalletDto'
import { WalletPaymentProvider } from '../../../../model/WalletPaymentProvider'
import { Note } from '../../../../ui/Note/Note'
import { useAccountReadContext } from '../../../../utils/AccountContextContext'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { TickmillCompaniesEnum } from '../../../../utils/companyName.utils'
import { useIsLangSelected } from '../../../../utils/language.utils'
import { isZero } from '../../../../utils/validations'
import { WalletDepositModal } from '../WalletDepositModal'
import { WalletDepositTermsConditions } from '../WalletDepositTermsConditions'
import { usePaymentProviderFactory } from '../usePaymentProviderFactory'
import { WalletDepositPSPFields } from './WalletDepositPSPFields'
import { WalletDepositPaymentAgentFields } from './WalletDepositPaymentAgentFields'
import { WalletDepositProviderNoneFields } from './WalletDepositProviderNoneFields'
import {
  GetDepositCurrencyLimits,
  GetDepositCurrencyLimitsValue,
  useGetDepositCurrencyLimits,
} from './hooks/useGetDepositCurrencyLimits'

export interface WalletDepositFormValues {
  wallet: Wallet
  paymentProvider: PaymentProvider
  walletType?: WalletTypeEnum
  amount: number | undefined
  comment: string
  // bonusCampaignId?: string
  // bonusAmount?: number
  // availableBonusCampaigns?: AvailableBonusCampaign[]
  terms: {
    responsibility: boolean
    depositing: boolean
    paymentOperations: boolean
    powerOfAttorney?: boolean
    cupResponsibility?: boolean
  }
  fields: { [key: string]: string }

  conversionRate: number
}

export interface Wallet {
  id: string | undefined
  name: string | undefined
  currency: { id: string; name: string }
  walletType: { id: number; name: string }
  balance: number | undefined
  depositAction: WalletDto['depositAction']
}

const FormField = createFormField<WalletDepositFormValues>()

const WalletDepositFormUI: React.FC<FormikProps<WalletDepositFormValues> & OuterProps> = (
  props
) => {
  const {
    title,
    wallet,
    setValues,
    onCancel,
    paymentProvider,
    setFieldValue,
    onSetConversionRate,
  } = props
  const [isOpenTermsCondition, setIsOpenTermsCondition] = useState(false)
  const [walletPaymentProvider, setWalletPaymentProvider] = useState<
    WalletPaymentProvider | undefined
  >()

  const { values, resetForm } = useFormikContext<WalletDepositFormValues>()
  const { wallet: walletFromValues, paymentProvider: paymentProviderFromValues } = values
  const selectedWallet = walletFromValues || wallet
  const selectedPaymentProvider = paymentProviderFromValues || paymentProvider

  const { t } = useTranslation()

  const [isPageExitConfirmation, setPageExitConfirmation] = useState(false)

  useIsLangSelected(() => {
    resetForm()
    setValues({
      ...walletDepositInitialState(),
      wallet: values.wallet,
      walletType: values.walletType,
    })
  })

  const { createPaymentProvider } = usePaymentProviderFactory()

  useEffect(() => {
    ;(async () => {
      if (walletPaymentProvider && wallet) {
        const paymentProvider = await createPaymentProvider(walletPaymentProvider, wallet)
        setValues({
          ...values,
          paymentProvider,
        })
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const apiClient = useApiClient(ClientApiClient)

  const fetchRate = async () => {
    const currencyLimits = paymentProviderFromValues?.parameters?.currenciesLimits?.find(
      (limit) =>
        limit.walletType?.id === walletFromValues?.walletType?.id &&
        limit.id === paymentProviderFromValues.currency?.id
    )
    const { rate } = await apiClient.getExchangeRate({
      BaseCcy: walletFromValues?.currency.id,
      QuoteCcy: paymentProviderFromValues?.currency.id,
      BaseWalletTypeId: walletFromValues?.walletType?.id,
      QuoteWalletTypeId: currencyLimits?.walletType?.id,
      direction: 'sell',
    })

    return rate
  }

  const updateConversionRate = async () => {
    const rate = await fetchRate()
    setFieldValue('conversionRate', rate)
    onSetConversionRate(rate)
  }

  useEffect(() => {
    if (
      walletFromValues?.currency?.id &&
      paymentProviderFromValues?.currency.id &&
      walletFromValues?.currency?.id !== paymentProviderFromValues?.currency.id
    ) {
      updateConversionRate()
    }
  }, [walletFromValues?.currency?.id, paymentProviderFromValues?.currency.id])

  const handlePageExitConfirmation = () => {
    onCancel()
  }

  const handleExitPageConfirmationModalOpen = () => {
    setPageExitConfirmation(true)
  }

  const handleExitPageConfirmationModalClose = () => {
    setPageExitConfirmation(false)
  }

  const getTitle = () => {
    return title ? `${title} ${t('Wallet.Deposit')}` : t('Wallet.Deposit')
  }
  const onClickTermsConditions = () => setIsOpenTermsCondition(true)

  const handleSetWalletPaymentProvider = (
    walletPaymentProvider: WalletPaymentProvider | undefined
  ) => setWalletPaymentProvider(walletPaymentProvider)

  if (isOpenTermsCondition && selectedWallet && selectedPaymentProvider) {
    return (
      <WalletDepositTermsConditions
        wallet={selectedWallet}
        paymentProvider={selectedPaymentProvider}
        onCancel={() => setIsOpenTermsCondition(false)}
      />
    )
  }

  return (
    <FormTemplate title={getTitle()} goBack={handleExitPageConfirmationModalOpen}>
      {isPageExitConfirmation && (
        <Modal
          closeModal={handleExitPageConfirmationModalClose}
          render={() => (
            <CancelActionModal
              onConfirm={handlePageExitConfirmation}
              onCancel={handleExitPageConfirmationModalClose}
            />
          )}
        />
      )}
      <Form>
        <FormFieldsFactory
          onCancel={handleExitPageConfirmationModalOpen}
          onClickTermsConditions={onClickTermsConditions}
          onSetWalletPaymentProvider={handleSetWalletPaymentProvider}
        />
      </Form>
    </FormTemplate>
  )
}

interface FormFieldsFactoryProps {
  onCancel(): void

  transactions?: TransactionDto[]
  onClickTermsConditions(): void

  onSetWalletPaymentProvider(walletPaymentProvider: WalletPaymentProvider | undefined): void
}

const FormFieldsFactory: React.FC<FormFieldsFactoryProps> = (props) => {
  const { onCancel, onClickTermsConditions, onSetWalletPaymentProvider } = props
  const { values, setValues, resetForm } = useFormikContext<WalletDepositFormValues>()
  const { formatMoney } = useFormatNumber()
  const { account } = useAccountReadContext()

  const { paymentProvider } = values

  const { depositCurrencyLimits } = useGetDepositCurrencyLimits({
    wallet: values.wallet,
    paymentProvider: values.paymentProvider,
    conversionRate: values.conversionRate,
  })

  const convertedAmount = values.conversionRate * (values?.amount ?? 0)

  const depositAmount =
    values.amount && values.amount > 0
      ? values.paymentProvider?.currency?.id === values.wallet?.currency?.id
        ? values.amount
        : convertedAmount
      : undefined

  // const { availableBonusCampaigns, isBonusLoading } = useGetAvailableBonusCampaigns({
  //   wallet: values.wallet,
  //   paymentProvider: values.paymentProvider,
  //   depositAmount,
  // })

  const [isWalletModalOpen, setWalletModalOpen] = useState(false)
  const [isPaymentProviderModalOpen, setPaymentProviderModalOpen] = useState(false)

  const providerCategoryId = values.paymentProvider.providerCategory.id

  const handleExitPageConfirmationModalClose = () => {
    onCancel()
  }

  const handleWallet = (wallet: Wallet) => {
    resetForm()
    setValues({
      ...walletDepositInitialState(),
      wallet,
      walletType: values.walletType,
    })
    setWalletModalOpen(false)
    onSetWalletPaymentProvider(undefined)
  }

  const handleWalletOpen = () => {
    setWalletModalOpen(true)
  }

  const handleWalletClose = () => {
    setWalletModalOpen(false)
  }

  const handlePaymentProvider = (
    paymentProvider: PaymentProvider,
    walletPaymentProvider: WalletPaymentProvider
  ) => {
    resetForm()
    setValues({
      ...walletDepositInitialState(),
      wallet: values.wallet,
      walletType: values.walletType,
      paymentProvider,
    })
    setPaymentProviderModalOpen(false)
    onSetWalletPaymentProvider(walletPaymentProvider)
  }

  const handlePaymentProviderOpen = () => {
    setPaymentProviderModalOpen(true)
  }

  const handlePaymentProviderClose = () => {
    setPaymentProviderModalOpen(false)
  }

  const { t } = useTranslation()

  const isCryptoNoteInfoVisible =
    isPaymentProviderSCOrbitalCrypto(paymentProvider?.id) ||
    isPaymentProviderSCZotapayCrypto(paymentProvider?.id) ||
    isPaymentProviderSCZotapayCryptoMetablocks(paymentProvider?.id)

  // useEffect(() => {
  //   if (availableBonusCampaigns) {
  //     setValues((prevValues) => ({
  //       ...prevValues,
  //       availableBonusCampaigns,
  //     }))
  //   }
  // }, [availableBonusCampaigns])

  return (
    <React.Fragment>
      {isWalletModalOpen && (
        <WalletDepositModal onSelectOption={handleWallet} onClose={handleWalletClose} />
      )}
      {isPaymentProviderModalOpen && (
        <WalletDepositPaymentProviderModal
          onSelectOption={handlePaymentProvider}
          onClose={handlePaymentProviderClose}
          wallet={values.wallet}
        />
      )}
      <FormField
        name='wallet.name'
        label={t('Wallet.Deposit To Wallet')}
        placeholder={t('Wallet.Deposit To Wallet')}
        value={
          values.wallet?.id &&
          t('Wallet.Wallet Currency Name', {
            walletCurrencyId: values.wallet.currency.id,
            walletName: values.wallet.name,
          })
        }
        rightIcon={<DropArrowDownIcon />}
        hint={
          values.wallet?.id &&
          `${t('Wallet.Balance')} ${formatMoney(values.wallet.balance, values.wallet.currency.id)}`
        }
        required
        readOnly
        onClick={handleWalletOpen}
      />
      <FormField
        name='paymentProvider.name'
        value={values.paymentProvider.currency?.description}
        label={t('Wallet.Payment Method')}
        placeholder={t('Wallet.Payment Method')}
        rightIcon={<DropArrowDownIcon />}
        disabled={!values.wallet?.id}
        required
        readOnly
        onClick={handlePaymentProviderOpen}
        key={values.paymentProvider?.id ? 'paymentProvider' : 'no-paymentProvider'}
      />
      {isCryptoNoteInfoVisible && (
        <div className='mt-4 mb-2'>
          <Note
            renderLeft={<BlueInfoIcon />}
            text={t('Wallet.Payments are accepted only in the listed cryptocurrencies')}
          />
        </div>
      )}
      {isCryptoNoteInfoVisible && (
        <div className='mb-5'>
          <Note
            renderLeft={<BlueInfoIcon />}
            text={t('Wallet.It is mandatory to complete your payment within the timeframe')}
          />
        </div>
      )}
      {paymentProvider.id && showPSPFields(providerCategoryId) && (
        <WalletDepositPSPFields
          minAmount={depositCurrencyLimits?.minAmount}
          showAmountPresets={isAccountActivatedStatus(account)}
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
      {paymentProvider.id && isPaymentProviderPaymentAgentType(providerCategoryId) && (
        <WalletDepositPaymentAgentFields
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
      {paymentProvider.id && isPaymentProviderNoneType(providerCategoryId) && (
        <WalletDepositProviderNoneFields
          onCancel={handleExitPageConfirmationModalClose}
          onClickTermsConditions={onClickTermsConditions}
        />
      )}
    </React.Fragment>
  )
}

interface OuterProps {
  wallet?: WalletDto
  walletType: WalletTypeEnum
  entity: TickmillCompaniesEnum

  onSubmit(values: WalletDepositFormValues): void
  formatMoney: FormatMoney
  getDepositCurrencyLimits: GetDepositCurrencyLimits
  onCancel(): void

  title?: string
  isETDFirstTimeDeposit?: boolean

  paymentProvider?: PaymentProvider

  onSetConversionRate(rate: number): void
}

interface StateProps {
  wallet?: WalletDto
  walletType: WalletTypeEnum
  paymentProvider?: PaymentProvider
}

export const walletDepositInitialState = (props?: StateProps): WalletDepositFormValues => {
  const { wallet, walletType, paymentProvider } = props || {}
  return {
    wallet: {
      id: wallet?.id || undefined,
      name: wallet?.name || undefined,
      currency: {
        id: wallet?.currency?.id || '',
        name: wallet?.currency?.name || '',
      },
      walletType: {
        id: wallet?.walletType?.id || 0,
        name: wallet?.walletType?.name || '',
      },
      depositAction: {
        availableToDeposit: wallet?.depositAction?.availableToDeposit || 0,
        canDeposit: wallet?.depositAction?.canDeposit || false,
      },
      balance: wallet?.balance || 0,
    },
    paymentProvider: paymentProvider || {
      id: undefined,
      name: undefined,
      description: '',
      providerCategory: { id: PaymentProviderType.None },
      currency: { id: '', name: '', minAmount: 0 },
      parameters: {
        currencies: [],
        currenciesLimits: [],
        messages: [{ title: [], list: [], top: [], bottom: [] }],
        termsConditions: [],
        fields: [],
        form: [],
      },
      method_name: '',
    },
    walletType: walletType,
    fields: {},
    amount: undefined,
    comment: '',
    terms: {
      responsibility: false,
      depositing: false,
      paymentOperations: false,
    },
    conversionRate: 1,
  }
}

export const validateWalletDepositForm = async (
  values: WalletDepositFormValues,
  {
    formatMoney,
    getDepositCurrencyLimits,
    isETDFirstTimeDeposit,
  }: {
    formatMoney: FormatMoney
    getDepositCurrencyLimits: GetDepositCurrencyLimits
    isETDFirstTimeDeposit?: boolean
  }
): Promise<FormikErrors<WalletDepositFormValues>> => {
  const errors: FormikErrors<WalletDepositFormValues> = {}
  const isPaymentAgentProviderType =
    values.paymentProvider.providerCategory.id === PaymentProviderType.PaymentAgent

  if (!values.wallet) {
    errors.wallet = {
      name: t('Validation.Required'),
    }
  }

  if (!values?.paymentProvider?.name) {
    errors.paymentProvider = {}
    errors.paymentProvider.name = t('Validation.Required')
  }

  const providerCategoryId = values.paymentProvider?.providerCategory?.id

  if (
    isPaymentProviderPSPType(providerCategoryId) ||
    isPaymentProviderCardProviderType(providerCategoryId) ||
    isPaymentProviderBankType(providerCategoryId)
  ) {
    const currencyLimits = await getDepositCurrencyLimits(values.wallet, values.paymentProvider)

    const { minAmount, maxAmount } = getErrorAmount(currencyLimits)

    if (isMinAmountError(values, minAmount)) {
      errors.amount = `${t('Wallet.Minimum deposit')} ${formatMoney(
        minAmount,
        values.paymentProvider.currency.id
      )}`
    }

    if (isMaxAmountError(values, maxAmount)) {
      errors.amount = `${t('Wallet.Maximum deposit')} ${formatMoney(
        maxAmount,
        values.paymentProvider.currency.id
      )}`
    }

    if (isValidAmount(values) && !isETDFirstTimeDeposit) {
      if (values?.paymentProvider?.currency?.id && values?.paymentProvider?.currency?.minAmount) {
        errors.amount = t('Validation.Minimum amount is {{amount}} {{currency}}', {
          currency: values?.paymentProvider?.currency?.id,
          amount: values?.paymentProvider?.currency?.minAmount,
        })
      } else {
        errors.amount = t('Validation.USD 1000 or equivalent')
      }
    }

    if (isZero(minAmount) || isZero(minAmount)) {
      errors.amount = t('Validation.Required')
    }

    if (!values.terms.paymentOperations) {
      errors.terms = {}
      errors.terms.paymentOperations = t('Validation.Required')
    }
  }

  // if (values.amount && values.availableBonusCampaigns?.length) {
  //   const selectedBonusCampaign = values.availableBonusCampaigns.find(
  //     (campaign) => campaign.bonusCampaign.id === values.bonusCampaignId
  //   )
  //
  //   if (selectedBonusCampaign) {
  //     if (values.amount < selectedBonusCampaign.minDepositAmount) {
  //       errors.bonusAmount = t('Wallet.Minimum deposit amount for this bonus is', {
  //         minAmount: formatMoney(selectedBonusCampaign.minDepositAmount, values.wallet.currency.id),
  //       })
  //     }
  //   }
  // }

  if (isPaymentProviderPaymentAgentType(providerCategoryId)) {
    if (!values.terms.paymentOperations) {
      errors.terms = {}
      errors.terms.paymentOperations = t('Validation.Required')
    }
  }
  if (!values.terms.responsibility && !isPaymentProviderPaymentAgentType(providerCategoryId)) {
    errors.terms = {}
    errors.terms.responsibility = t('Validation.Required')
  }

  if (isPaymentAgentProviderType) {
    if (!values.terms.powerOfAttorney) {
      errors.terms = {}
      errors.terms.powerOfAttorney = t('Validation.Required')
    }
  }

  if (values.comment.length > 100) {
    errors.comment = t('Validation.The character limit is 100 characters')
  }

  if (
    values.paymentProvider.id &&
    isChinaUnionPayPSP(values.paymentProvider.id) &&
    !values.terms.cupResponsibility
  ) {
    errors.terms = errors.terms || {}
    errors.terms.cupResponsibility = t('Validation.Required')
  }

  return errors
}

function dataToSubmit(values: WalletDepositFormValues): WalletDepositFormValues {
  const hiddenFields = values.paymentProvider?.parameters.form.reduce<Record<string, any>>(
    (acc, field) => {
      if (field.type === 'hidden') {
        acc[field.field as string] = field.value
      }
      return acc
    },
    {}
  )

  return {
    ...values,
    fields: {
      ...values.fields,
      ...hiddenFields,
    },
  }
}

export const WalletDepositForm = withFormik<OuterProps, WalletDepositFormValues>({
  mapPropsToValues: ({ wallet, walletType, paymentProvider }) => {
    return {
      ...walletDepositInitialState({ wallet, walletType, paymentProvider }),
      // availableBonusCampaigns: [],
    }
  },
  handleSubmit: (values, { props, setSubmitting }) => {
    try {
      setSubmitting(true)
      props.onSubmit(dataToSubmit(values))
      setSubmitting(false)
    } finally {
      setSubmitting(false)
    }
  },
  validate: validateWalletDepositForm,
  enableReinitialize: true,
})(WalletDepositFormUI)

const getErrorAmount = (
  currencyLimit?: GetDepositCurrencyLimitsValue
): GetDepositCurrencyLimitsValue => {
  const minAmount = currencyLimit?.minAmount || 0
  const maxAmount = currencyLimit?.maxAmount || 0

  return { minAmount, maxAmount }
}

const isMinAmountError = (values: WalletDepositFormValues, minAmount: number): boolean => {
  const amount = values?.amount || 0

  return amount < minAmount || isZero(amount)
}

const isMaxAmountError = (values: WalletDepositFormValues, maxAmount: number): boolean => {
  const amount = values?.amount || 0

  return amount > maxAmount
}

const isValidAmount = (values: WalletDepositFormValues): boolean => {
  const minAmount = values?.paymentProvider?.currency?.minAmount || 0
  const amount = values?.amount || 0

  return amount < minAmount
}

export const isChinaUnionPayPSP = (providerId: string): boolean =>
  [
    {
      id: '9599bfdb-825e-11eb-a2ad-06e70b382a0c',
      name: 'China UnionPay - Channel Z (SC Zotapay CNY (CUP))',
    },
    {
      id: '9017bcc6-939e-11ea-9c68-000c29df7a17',
      name: 'China UnionPay - Channel T (SC Transact365 CNY)',
    },
    {
      id: 'abfb42e9-9d79-11ed-8b0c-0a51dcb142a4',
      name: 'China Online Bank Transfer (SC Xpay CNY)',
    },
  ]
    .map((psp) => psp.id)
    .includes(providerId)

// export const getDepositBankDetails = (bankDetail: FormItemDto[]): FormItemDto[] => {
//   const bankDataFields = [
//     'beneficiary',
//     'beneficiary_address',
//     'bank',
//     'bank_address',
//     'swift',
//     'account_number',
//     'iban',
//   ]
//
//   return bankDataFields
//     .map((x) => bankDetail.find((detail) => detail.field === x))
//     .filter((detail): detail is FormItemDto => detail !== undefined)
// }
