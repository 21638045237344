import UAParser from 'ua-parser-js'

import {
  overrideForZHToCNLanguage,
  useSessionLanguage,
} from '../global/context/SessionSettingsContext'

type SupportedLocale = 'en' | 'pt' | 'ko' | 'ms' | 'vi'
type LocaleToLanguageMap = Record<SupportedLocale, string>

const localeToPlatformLanguageIdMap: LocaleToLanguageMap = {
  en: 'www',
  pt: 'br',
  ko: 'kr',
  ms: 'my',
  vi: 'vm',
}

export const useTradingViewTradeUrl = () => {
  const locale = useSessionLanguage()
  const languageId = overrideForZHToCNLanguage(locale) as SupportedLocale

  const osName = new UAParser().getResult().os.name?.toLowerCase()
  const normalizedLanguageId = localeToPlatformLanguageIdMap[languageId] ?? languageId

  if (osName === 'ios') {
    return 'https://apps.apple.com/us/app/tradingview-track-all-markets/id1205990992 '
  } else if (osName === 'android') {
    return 'https://play.google.com/store/apps/details?id=com.tradingview.tradingviewapp&hl=en-US'
  }
  return `https://${normalizedLanguageId}.tradingview.com/chart/?trade-now=TICKMILL`
}
