import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link, useLocation, useNavigate } from 'react-router-dom'

import { Loading } from '../../global/Loading/Loading'
import { StepCircle } from '../../global/StepCircle/StepCircle'
import { useAccountInfo } from '../../global/context/AccountInfoContext/AccountInfoContext.Provider'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { InformationModal as InformationModalView } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { FormTemplate } from '../../global/templates/FormTemplate'
import { ToastContext, errorToast } from '../../global/toast/Toast'
import { BackIcon } from '../../icons/BackIcon'
import { AccountGroupType } from '../../model/AccountGroupType'
import { CampaignStatusEnum } from '../../model/CampaignStatusEnum'
import {
  CreateCampaignTradingAccount,
  CreateTradingAccount,
} from '../../model/CreateTradingAccount'
import { NameDto } from '../../model/NameDto'
import { PlatformTypeEnum, isTMTPlatformType } from '../../model/PlatformTypeEnum'
import { TradingAccount } from '../../model/TradingAccount'
import { WalletCurrency } from '../../model/WalletDto'
import { Text, TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { getClientPrimaryEmail } from '../../utils/AccountAccess/accountEmailStatuses'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { Operator, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'
import { useScrollToTop } from '../../utils/useScrollToTop'
import {
  CampaignExtendedNameDto,
  CreateTradingAccountCampaignForm,
} from './CreateTradingAccountCampaignForm'

import styles from './CreateTradingAccountCampaignPage.module.scss'

interface ModalProps {
  platformId?: PlatformTypeEnum
  visible?: boolean
  isCampaign?: boolean
}

interface CampaignExtendedName {
  id: string
  name: string
  platformTypes: NameDto[]
  campaignDescription?: string
}

export const CreateTradingAccountCampaignPage: React.FC = () => {
  useScrollToTop()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const { refreshLimits } = useAccountInfo()

  const apiClient = useApiClient(ClientApiClient)
  const { account } = useAccountReadContext()
  const locale = useSessionLanguage()
  const setToast = useContext(ToastContext)

  const [successInfoModal, setSuccessInfoModal] = useState<ModalProps>()
  const [isAccountCreated, setIsAccountCreated] = useState(false)
  const [isCurrenciesLoading, setIsCurrenciesLoading] = useState(false)
  const [currencies, setCurrencies] = useState<WalletCurrency[]>([])
  const [selectedPlatform, setSelectedPlatform] = useState<NameDto>()

  const urlParams = new URLSearchParams(location.search)
  const hasCampaignQuery = !!urlParams.get('campaign') || false

  const handleSubmit = async (values: CreateTradingAccount | CreateCampaignTradingAccount) => {
    try {
      if (values.campaignId) {
        await apiClient.addCampaignTradingAccount(values as CreateCampaignTradingAccount)
      } else {
        await apiClient.addTradingAccount(values as CreateTradingAccount)
      }

      if (isTMTPlatformType(values.platformTypeId)) {
        setIsAccountCreated(true)
      } else {
        setSuccessInfoModal({
          platformId: values.platformTypeId,
          isCampaign: !!values.campaignId,
          visible: true,
        })
      }
    } catch (error: unknown) {
      navigate('/dashboard/traders-room/trading-accounts')
      if ((error as any).response.response.data.message) {
        return setToast(errorToast((error as any).response.response.data.message))
      } else {
        return setToast(errorToast(t('errors.Something went wrong! Please try again later')))
      }
    } finally {
      await refreshLimits()
    }
  }

  const handleChangePlatform = useCallback(
    async (platformTypeId: number, introducingBrokerId?: string) => {
      setIsCurrenciesLoading(true)
      try {
        if (introducingBrokerId) {
          const response = await apiClient.getIntroducingBrokerCurrenciesByPlatformId(
            introducingBrokerId,
            platformTypeId
          )
          setCurrencies(response)
        } else {
          const response = await apiClient.getCurrencies(platformTypeId)
          setCurrencies(response)
        }
      } finally {
        setIsCurrenciesLoading(false)
      }
    },
    [apiClient]
  )

  const callback = useCallback(
    () => apiClient.getClientCampaigns(locale),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )
  const { data: campaigns = [], isLoading } = useFetchOne(callback)

  const [campaignAccount, setCampaignAccount] = useState<TradingAccount | undefined>()
  const [isTradingAccountLoading, setIsTradingAccountLoading] = useState(false)

  useEffect(() => {
    const fetchTradingAccounts = async () => {
      setIsTradingAccountLoading(true)
      try {
        const tradingAccounts = await apiClient.getTradingAccounts({
          search: {
            Campaign: {
              value: true,
              operator: Operator.IS_NOT_EMPTY,
            },
            AccountGroupType: {
              value: AccountGroupType.Live,
              operator: Operator.EQUAL,
            },
            Platform: {
              value: PlatformTypeEnum.CQGCAST,
              operator: Operator.NOT_EQUAL,
            },
            Status: {
              value: CampaignStatusEnum.Enabled,
              operator: Operator.EQUAL,
            },
          },
        })
        if (tradingAccounts.items.length) {
          setCampaignAccount(tradingAccounts.items.find((x) => !!x.campaigns.length))
        }
      } finally {
        setIsTradingAccountLoading(false)
      }
    }
    fetchTradingAccounts()
  }, [successInfoModal?.visible])

  const transformData = useMemo(() => {
    return campaigns.reduce<CampaignExtendedNameDto[] | CampaignExtendedName[]>(
      (previousValue: CampaignExtendedNameDto[] | CampaignExtendedName[], currentValue) => {
        return [
          ...previousValue,
          {
            id: currentValue.id,
            name: currentValue.name,
            isCampaign: true,
            termsAndConditions: currentValue.termsAndConditionsUrl || '',
            type: currentValue.type,
            platformTypes: currentValue.platformTypes,
            calculationType: currentValue.calculationType,
            campaignDescription: currentValue.campaignDescription,
          },
        ]
      },
      [
        {
          id: '0',
          name: t('Trading Account.Regular Trading Account'),
          platformTypes: [],
        },
      ]
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaigns, locale])

  if (!campaigns.length && hasCampaignQuery && !isLoading) {
    navigate('/dashboard/traders-room/trading-accounts')
    return null
  }

  if (isAccountCreated) {
    return <TickmillTraderSuccessPage />
  }

  return (
    <Loading isLoading={isLoading}>
      {successInfoModal?.visible && (
        <Modal
          closeModal={() => {
            setSuccessInfoModal(undefined)
            navigate('/dashboard/traders-room/trading-accounts')
          }}
          render={({ closeModal }) => (
            <InformationModal
              data={successInfoModal}
              onCancel={closeModal}
              isCampaign={successInfoModal?.isCampaign}
              campaignAccount={campaignAccount}
              isTradingAccountLoading={isTradingAccountLoading}
            />
          )}
        />
      )}
      <CreateTradingAccountCampaignForm
        data={transformData}
        currencies={currencies}
        account={account}
        hasCampaignQuery={hasCampaignQuery}
        onChangePlatform={handleChangePlatform}
        isCurrenciesLoading={isCurrenciesLoading}
        onSubmit={handleSubmit}
        setSelectedPlatform={setSelectedPlatform}
        selectedPlatform={selectedPlatform}
      />
    </Loading>
  )
}

const TickmillTraderSuccessPage = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  return (
    <FormTemplate
      title={t('Trading Account.Tickmill Trader Account Created')}
      goBackIcon={<BackIcon />}
      goBack={() => navigate('/dashboard/traders-room/trading-accounts')}
      wrapped
      titleClassName={styles.title}
      subtitle={t(
        'Trading Account.You will receive a confirmation email with your username and password tickmillTrader'
      )}
    >
      <div className={styles.contentWrapper}>
        <Text className='text-align-center'>
          {t('Trading Account.Please, follow the instructions below')}
        </Text>
        <div className={styles.contentText}>
          <div>
            <StepCircle isActive={false} section={1} isRed={true} isSmall={true} />
          </div>
          <TextSmall>
            <Link className={styles.link} to={'/dashboard/tools/platforms/tickmilltrader'}>
              {t('Trading Account.Download')}
            </Link>{' '}
            {t('and')} <TextSmallStrong>{t('Trading Account.install')}</TextSmallStrong>{' '}
            {t('Trading Account.the Tickmill Trader Mobile App')}
          </TextSmall>
        </div>
        <div className={styles.contentText}>
          <div>
            <StepCircle isActive={false} section={2} isRed={true} isSmall={true} />
          </div>
          <TextSmall>
            {t('Trading Account.Access the app with your login username and password')}
          </TextSmall>
        </div>
        <div className={styles.contentText}>
          <div>
            <StepCircle isActive={false} section={3} isRed={true} isSmall={true} />
          </div>
          <TextSmall>
            {t(
              'Trading Account.Click on “menu” on the bottom of the screen to switch between Live and Demo Accounts'
            )}
          </TextSmall>
        </div>
        <div className={styles.contentText}>
          <div>
            <StepCircle isActive={false} section={4} isRed={true} isSmall={true} />
          </div>
          <TextSmall>
            {t(
              'Trading Account.Trade without any risks on your Demo, or fund your account and start trading'
            )}
          </TextSmall>
        </div>
      </div>
    </FormTemplate>
  )
}

interface InformationModalProps {
  data: ModalProps
  isCampaign?: boolean
  isTradingAccountLoading: boolean
  campaignAccount?: TradingAccount
  onCancel(): void
}

const InformationModal: React.FC<InformationModalProps> = (props) => {
  const {
    data: successInfoModal,
    isCampaign,
    campaignAccount,
    isTradingAccountLoading,
    onCancel,
  } = props

  const { t } = useTranslation()
  const navigate = useNavigate()
  const { account } = useAccountReadContext()

  return (
    <InformationModalView
      onCancel={() => {
        onCancel()
        navigate('/dashboard/traders-room/trading-accounts')
      }}
      onClick={async () => {
        if (campaignAccount) {
          navigate(
            `/dashboard/traders-room/trading-accounts/${campaignAccount.id}/deposit-to-campaign`
          )
        }
      }}
      title={
        isTMTPlatformType(successInfoModal?.platformId)
          ? t('Tickmill Trader Account Created!')
          : isCampaign
          ? t('Trading Account.New Campaign account successfully created!')
          : t('Trading Account.New trading account has been successfully created.')
      }
      onCancelText={t('Got It!')}
      isLoading={isTradingAccountLoading}
      {...(isCampaign && { onClickText: t('Wallet.Deposit Now') })}
    >
      {isTMTPlatformType(successInfoModal?.platformId)}
      <Text>
        {isTMTPlatformType(successInfoModal?.platformId)
          ? t('You will receive a confirmation email with your username and password')
          : isCampaign
          ? t('Trading Account.Your campaign account has been created!')
          : t('Trading Account.A new trading account has been created', {
              email: account && getClientPrimaryEmail(account),
            })}
      </Text>
    </InformationModalView>
  )
}
