import React, { useLayoutEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { NavigateOptions, useLocation } from 'react-router-dom'
import classNames from 'classnames'

import { TwoFactorAuthWithdrawalWarningModal } from '../../TwoFactorAuth/TwoFactorAuthWithdrawalWarningModal'
import { Loading } from '../../global/Loading/Loading'
import { CopyButton } from '../../global/button/CopyButton'
import { useSessionEntity } from '../../global/context/EntityContext'
import { SelectableModal } from '../../global/field/SelectableModal'
import { InfoModal } from '../../global/modal/InfoModal'
import { InformationModal } from '../../global/modal/InformationModal'
import { Modal } from '../../global/modal/Modal'
import { RestrictionActionModal } from '../../global/modal/RestrictionActionModal'
import { WalletNumberModal } from '../../global/modal/WalletNumberModal'
import { ScrollToIds } from '../../hooks/useScrollToElementIds'
import { useWithdrawalPageNavigation } from '../../hooks/useWithdrawalPageNavigation'
import { AlertIcon } from '../../icons/AlertIcon'
import { BrokerSummaryDto } from '../../model/BrokerSummaryDto'
import { IntroducingBrokerReferralDetailsDto } from '../../model/IntroducingBrokerDetailsDto'
import { NameDto } from '../../model/NameDto'
import { WalletCurrency, WalletDto, WalletStateEnum, WalletTypeEnum } from '../../model/WalletDto'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { Text, TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { useAccountReadContext } from '../../utils/AccountContextContext'
import { useApiClient } from '../../utils/ApiClient'
import { useIntroducingBroker } from '../../utils/IntroducingBrokerContext'
import { ClientApiClient } from '../../utils/clientApi'
import { TickmillCompaniesEnum, isTickmillPartner } from '../../utils/companyName.utils'
import { useWindowResize } from '../../utils/domUtils'
import { useCallbackWithForceRefresh } from '../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../utils/useFetch'
import { useIBReferralCodes } from '../../utils/useIBReferralCodes'
import { scrollToTop, useScrollToTop } from '../../utils/useScrollToTop'
import { isZero } from '../../utils/validations'
import { IBWalletCard } from './IBWalletCard'
import { IBWalletsTable } from './IBWalletsTable'

import styles from './IBWalletsPage.module.scss'

export interface IntroducingBrokerMappedDto {
  currency: WalletCurrency
  id: string
  name: string
  balance: number
  incomingReserved: number
  reserved: number
  allTimeReward: number
  restrictions: NameDto[]
  tradingAccounts: number
}

interface ModalProps {
  visible: boolean
  data?: IntroducingBrokerMappedDto
}

interface WalletsFetchProps {
  availableWallets: WalletCurrency[]
  wallets: IntroducingBrokerMappedDto[]
  brokerSummary: BrokerSummaryDto | undefined
}

const useWalletsFetch = (
  callback: (data: WalletsFetchProps) => void
): WalletsFetchProps & { isLoading: boolean; forceRefresh(): void } => {
  const apiClient = useApiClient(ClientApiClient)

  const { callback: callbackWallets, forceRefresh } = useCallbackWithForceRefresh(async () => {
    const wallets = await getWallets()
    const availableWallets = await apiClient.getAvailableWallets(WalletTypeEnum.IB)
    const brokerSummary = await apiClient.getIntroducingBrokerSummary()
    const mappedWallets = await getIntroducingBrokerTransactionsSummary(wallets, brokerSummary)
    const result = { availableWallets, wallets: mappedWallets, brokerSummary }
    callback(result)
    return result
  }, [])

  const { data, isLoading } = useFetchOne<WalletsFetchProps>(callbackWallets)

  const getWallets = async () => {
    return await apiClient.getWallets({
      walletTypeId: 10,
    })
  }

  const getIntroducingBrokerTransactionsSummary = async (
    wallets: WalletDto[],
    brokerSummary: BrokerSummaryDto
  ): Promise<IntroducingBrokerMappedDto[]> => {
    return await Promise.all(
      wallets.map(async (w) => {
        const walletReward = await apiClient.getIntroducingBrokerTransactionsSummary(w.id)
        // incomingReserved is only for USD wallet, we're not pay commission in any other currency
        return {
          ...w,
          incomingReserved:
            w.currency.id === 'USD' ? brokerSummary.amount + walletReward.pendingReward : 0,
          allTimeReward: walletReward.allTimeReward,
          tradingAccounts: brokerSummary.amount,
        }
      })
    )
  }

  return {
    availableWallets: data?.availableWallets || [],
    wallets: data?.wallets || [],
    brokerSummary: data?.brokerSummary || undefined,
    isLoading,
    forceRefresh,
  }
}

const useTitle = () => {
  const { t } = useTranslation()
  const isMobile = useWindowResize()

  const { account } = useAccountReadContext()
  const isPartner = isTickmillPartner(account)

  const renderInformationTitle = () => {
    if (isPartner) {
      return t('IB.Partner wallet')
    }
    return t('IB.IB wallet')
  }

  const renderHeaderTitle = () => {
    if (isPartner) {
      return t('IB.Partner Wallets')
    }
    if (isMobile) {
      return t('IB.IB Wallet mobile')
    } else {
      return t('IB.IB Wallet')
    }
  }

  return { headerTitle: renderHeaderTitle(), informationTitle: renderInformationTitle() }
}

export const IBWalletsPage: React.FC = () => {
  useScrollToTop()
  const apiClient = useApiClient(ClientApiClient)
  const location = useLocation()
  const {
    handleWithdrawalNavigation,
    twoFactorWarningModalState,
    onDismissModal,
    getWithdrawalPath,
    walletWithdrawLoadingId,
  } = useWithdrawalPageNavigation()

  const [availableWalletCurrencies, setAvailableWalletCurrencies] = useState<WalletCurrency[]>([])
  const [isRestrictionModalOpen, setRestrictionModalOpen] = useState<boolean>(false)
  const [isAddWalletModalOpen, setAddWalletModalOpen] = useState<boolean>(false)
  const [isAvailableWalletsModalOpen, setAvailableWalletsModalOpen] = useState<boolean>(false)
  const [isIBProgramModalOpen, setIBProgramModalOpen] = useState<boolean>(false)
  const [walletNumberModalData, setWalletNumberModalData] = useState<ModalProps>({
    visible: false,
    data: undefined,
  })

  const walletsData = useWalletsFetch((data) => {
    setAvailableWalletCurrencies(data.availableWallets)
  })
  const { wallets, isLoading, forceRefresh } = walletsData

  useLayoutEffect(() => {
    if (location.state?.wallets) {
      scrollToTop()
    }
  }, [location, isLoading])

  const handleOpenWallet = async (walletCurrency: WalletCurrency) => {
    await apiClient.addWallet({
      walletTypeId: WalletTypeEnum.IB,
      walletStateId: WalletStateEnum.Active,
      currencyId: walletCurrency.id,
    })
    setAddWalletModalOpen(false)
    forceRefresh()
  }

  return (
    <React.Fragment>
      {twoFactorWarningModalState.visible && (
        <TwoFactorAuthWithdrawalWarningModal
          closeModal={onDismissModal}
          walletId={twoFactorWarningModalState.walletId}
          getWithdrawalPath={getWithdrawalPath}
        />
      )}
      {walletNumberModalData.visible && (
        <Modal
          closeModal={() =>
            setWalletNumberModalData({
              visible: false,
              data: undefined,
            })
          }
          render={({ closeModal }) => (
            <WalletNumberModal wallet={walletNumberModalData.data!} onConfirm={closeModal} />
          )}
        />
      )}
      {isRestrictionModalOpen && (
        <Modal
          closeModal={() => setRestrictionModalOpen(false)}
          render={({ closeModal }) => <RestrictionActionModal onConfirm={closeModal} />}
        />
      )}
      {isAddWalletModalOpen && (
        <Modal
          closeModal={() => setAddWalletModalOpen(false)}
          render={({ closeModal }) => (
            <AddWalletModal
              availableWalletCurrencies={availableWalletCurrencies}
              onWalletOpen={handleOpenWallet}
              onClose={closeModal}
            />
          )}
        />
      )}
      {isAvailableWalletsModalOpen && (
        <Modal
          closeModal={() => setAvailableWalletsModalOpen(false)}
          render={({ closeModal }) => <AvailableWalletsModal onClose={closeModal} />}
        />
      )}
      {isIBProgramModalOpen && (
        <Modal
          closeModal={() => setIBProgramModalOpen(false)}
          render={({ closeModal }) => <ProgramModal onIBProgramModalClose={closeModal} />}
        />
      )}
      <Header
        availableWalletCurrencies={availableWalletCurrencies}
        onAvailableWalletsModalOpen={() => setAvailableWalletsModalOpen(true)}
        onAddWalletModalOpen={() => setAddWalletModalOpen(true)}
        onIBProgramModalOpen={() => setIBProgramModalOpen(true)}
      />
      <IBWalletRecords
        wallets={wallets}
        isLoading={isLoading}
        setWalletNumberModalData={setWalletNumberModalData}
        handleWithdrawalNavigation={handleWithdrawalNavigation}
        walletWithdrawLoadingId={walletWithdrawLoadingId}
      />
    </React.Fragment>
  )
}

interface HeaderProps {
  onIBProgramModalOpen(): void
  onAddWalletModalOpen(): void
  onAvailableWalletsModalOpen(): void
  availableWalletCurrencies: WalletCurrency[]
}

const Header = (props: HeaderProps) => {
  const {
    availableWalletCurrencies,
    onAddWalletModalOpen,
    onAvailableWalletsModalOpen,
    onIBProgramModalOpen,
  } = props

  const { t } = useTranslation()
  const { account } = useAccountReadContext()
  const { referralCodes } = useIBReferralCodes()
  const introducingBroker = useIntroducingBroker()
  const entity = useSessionEntity()
  const isMobile = useWindowResize()
  const isPartner = isTickmillPartner(account)

  const { headerTitle } = useTitle()

  const disableAddWallet = isZero(availableWalletCurrencies.length)

  return (
    <PageHeader
      id={ScrollToIds.IBWalletsHeader}
      title={headerTitle}
      titleInfoToggle={onIBProgramModalOpen}
      calculations={[
        {
          title: t('Wallet.Total Clients'),
          value: introducingBroker?.tradingAccountsCount,
          link: '/dashboard/introducing-broker/clients',
          linkState: { IBClients: true },
        },
      ]}
      actions={
        entity === TickmillCompaniesEnum.TICKMILL_SC || entity === TickmillCompaniesEnum.TICKMILL_AS
          ? undefined
          : [
              {
                disabled: disableAddWallet,
                onClick: onAddWalletModalOpen,
                label: `+ ${t('Wallet.Add Wallet')}`,
                infoHandler: !isMobile ? onAvailableWalletsModalOpen : undefined,
              },
            ]
      }
      rightRender={<ReferralCodesFactory referralCodes={referralCodes} />}
      renderInfo={
        isMobile &&
        disableAddWallet &&
        entity !== TickmillCompaniesEnum.TICKMILL_SC &&
        entity !== TickmillCompaniesEnum.TICKMILL_AS
          ? () => (
              <>
                <AlertIcon color='warning' />
                <span>{t('Wallet.Maximum wallet limit reached')}</span>
              </>
            )
          : undefined
      }
      wrapOnMobile={isPartner}
    />
  )
}

interface ReferralCodesFactoryProps {
  referralCodes: IntroducingBrokerReferralDetailsDto[]
}

const ReferralCodesFactory: React.FC<ReferralCodesFactoryProps> = (props) => {
  const { referralCodes = [] } = props
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const { account } = useAccountReadContext()
  const isPartner = isTickmillPartner(account)

  if (isPartner) {
    return (
      <div className={classNames(styles.referralCodeWrapper, styles.partners)}>
        <div className={styles.referralCodePartners}>
          <TextSmall className={styles.referralLabel}>{t('IB.Your Referral Codes')}</TextSmall>
          <div className={styles.referralCodesBox}>
            {referralCodes
              .sort((a, b) => a.mainIbScheme.id - b.mainIbScheme.id)
              .map((code) => code.code)
              .map((referralCode, idx) => (
                <>
                  <TextSmallStrong className={styles.referralValue}>
                    <span>
                      {idx === 0 && (
                        <TextSmall className={classNames(styles.referralLabel, styles.mobile)}>
                          {t('IB.Your referral codes')}
                        </TextSmall>
                      )}
                      <h4>{referralCode}</h4>
                    </span>
                    {isMobile && (
                      <div>
                        <CopyButton value={referralCode} />
                      </div>
                    )}
                  </TextSmallStrong>
                  {idx < referralCodes.length - 1 ? (
                    <span className={styles.splitter}>/</span>
                  ) : null}
                </>
              ))}
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className={styles.referralCodeWrapper}>
      <div className={styles.referralCode}>
        <TextSmall className={styles.referralLabel}>{t('IB.Your Referral Codes')}</TextSmall>
        <div className={styles.referralCodesBox}>
          {referralCodes
            .map((code) => code.code)
            .map((referralCode) => (
              <TextSmallStrong className={styles.referralValue}>{referralCode}</TextSmallStrong>
            ))}
        </div>
      </div>
    </div>
  )
}

interface IBWalletRecordsProps {
  wallets: IntroducingBrokerMappedDto[]
  setWalletNumberModalData(value: ModalProps): void
  isLoading: boolean
  walletWithdrawLoadingId: string
  handleWithdrawalNavigation: (withdrawalOptions?: {
    walletId?: string
    navigateOptions?: NavigateOptions
  }) => Promise<void>
}

const IBWalletRecords: React.FC<IBWalletRecordsProps> = (props) => {
  const {
    wallets,
    setWalletNumberModalData,
    handleWithdrawalNavigation,
    isLoading,
    walletWithdrawLoadingId,
  } = props

  const isMobile = useWindowResize()

  if (isMobile) {
    return (
      <>
        {wallets.map((wallet) => (
          <IBWalletCard
            isLoading={walletWithdrawLoadingId === wallet.id}
            wallet={wallet}
            setWalletNumberModal={setWalletNumberModalData}
            handleWithdrawalNavigation={handleWithdrawalNavigation}
          />
        ))}
      </>
    )
  }

  return (
    <Loading showLoadingIcon isLoading={isLoading}>
      <IBWalletsTable
        walletWithdrawLoadingId={walletWithdrawLoadingId}
        data={wallets}
        handleWithdrawalNavigation={handleWithdrawalNavigation}
      />
    </Loading>
  )
}

interface ProgramModalProps {
  onIBProgramModalClose(): void
}

const ProgramModal: React.FC<ProgramModalProps> = (props) => {
  const { onIBProgramModalClose } = props

  const { informationTitle } = useTitle()

  const { t } = useTranslation()

  return (
    <InformationModal
      title={informationTitle}
      onCancel={onIBProgramModalClose}
      onCancelText={t('Got It')}
    >
      <Text isParagraph>
        <React.Fragment>
          {t('IB.In this section you can view the below')}
          <p className='mb-2 mt-4'>
            <span className='has-text-weight-bold'>{t('IB.Available balance')}</span>{' '}
            <span>{t('IB.Available balance description')}</span>
          </p>
          <p className='mb-1'>
            <span className='has-text-weight-bold'>{t('IB.Incoming reserved')}</span>{' '}
            <span>{t('IB.Incoming reserved description')}</span>
          </p>
          <p className='mb-1'>
            <span className='has-text-weight-bold'>{t('IB.Outgoing reserved')}</span>{' '}
            <span>{t('IB.Outgoing reserved description')}</span>
          </p>
          <p className='mb-1'>
            <span className='has-text-weight-bold'>{t('IB.All time reward')}</span>{' '}
            <span>{t('IB.All time reward description')}</span>
          </p>
          <p className='mt-4'>
            <span>{t('IB.Furthermore, in this section')}</span>
          </p>
        </React.Fragment>
      </Text>
    </InformationModal>
  )
}

interface AvailableWalletsModalProps {
  onClose(): void
}

const AvailableWalletsModal: React.FC<AvailableWalletsModalProps> = (props) => {
  const { onClose } = props
  const { t } = useTranslation()

  return (
    <InfoModal
      onCancel={onClose}
      title={t('IB.Maximum limit of Wallets reached!')}
      renderBody={() => (
        <section className='modal-card-body'>
          <Text
            isParagraph
            dangerouslySetInnerHTML={{
              __html: t(
                'IB.You have reached the maximum limit of Wallets! Please contact our Support team for more information.'
              ),
            }}
          />
        </section>
      )}
      onConfirm={onClose}
    />
  )
}

interface AddWalletModalProps {
  onWalletOpen: any
  availableWalletCurrencies: WalletCurrency[]
  onClose(): void
}

const AddWalletModal: React.FC<AddWalletModalProps> = (props) => {
  const { availableWalletCurrencies, onWalletOpen, onClose } = props

  const { t } = useTranslation()

  return (
    <SelectableModal
      onCancel={onClose}
      title={t('Add New Wallet')}
      renderOptions={() => (
        <React.Fragment>
          <div className='control'>
            <label className={classNames('column is-full-desktop radio is-center')}>
              <span className='is-flex'>
                {t('A new wallet will be created for the specified currency.')}
              </span>
            </label>
            {availableWalletCurrencies.map((walletCurr) => {
              return (
                <label
                  key={walletCurr.id}
                  className={classNames(styles.text, 'radio column is-full-desktop radio')}
                >
                  <input
                    onClick={() => onWalletOpen(walletCurr)}
                    type='radio'
                    value={walletCurr.id}
                    name='option'
                  />{' '}
                  {walletCurr.id}
                </label>
              )
            })}
          </div>
        </React.Fragment>
      )}
    />
  )
}
