import React, { useContext, useMemo } from 'react'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import { overrideForZHToCNLanguage } from '../global/context/SessionSettingsContext'
import { AllLocales, Locale } from '../global/locale/Locale'
import { MaybeAuthSession } from './AuthContext'

export type ErrorHandler = (error: ResponseError) => void

export class ResponseError {
  private readonly timer: any

  constructor(
    public response: AxiosError<{
      code: string
      message?: string
      properties?: Record<string, { Id: string; Name: string }>
    }>,
    errorHandler?: ErrorHandler
  ) {
    this.timer = setTimeout(() => {
      if (errorHandler) {
        errorHandler(this)
      }

      console.error(this.response)
    }, 100)
  }

  preventDefault(): void {
    clearTimeout(this.timer)
  }
}

function getUrlLocale(): Locale {
  const urlLocale = window.location.pathname.substring(1, 3) as Locale
  if (AllLocales.includes(urlLocale)) {
    return urlLocale
  } else {
    return 'en'
  }
}

function assignAccessTokenForDevelopment(
  headers: Record<string, string>,
  restoredSession: string
): Record<string, string> {
  const auth: MaybeAuthSession = JSON.parse(restoredSession)
  if (auth) {
    headers = { ...headers, Authorization: `Bearer ${auth.accessToken}` }
  }
  return headers
}

export class Tel3Client {
  private axiosInstance: AxiosInstance
  errorHandler?: ErrorHandler
  locale?: Locale

  constructor(locale?: Locale) {
    this.axiosInstance = axios.create({
      baseURL: '/api/terl',
      timeout: 60000,
      headers: this.getHeaders(locale || getUrlLocale()),
    })

    this.axiosInstance.interceptors.response.use(
      (response) => response,
      (error: AxiosError) => {
        if (error.response) {
          throw new ResponseError(error, this.errorHandler)
        }
        throw error
      }
    )
  }

  private getHeaders(locale: Locale): Record<string, string> {
    let headers: Record<string, string> = {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'x-lang': overrideForZHToCNLanguage(locale),
    }

    const restoredSession: string | null = sessionStorage.getItem('auth')
    if (process.env.NODE_ENV === 'development' && restoredSession) {
      headers = assignAccessTokenForDevelopment(headers, restoredSession)
    }

    return headers
  }

  async rawFetch<T = any>(url: string, config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.request<T>({ url, ...config })
  }

  async fetchAndParse<T>(url: string, config: AxiosRequestConfig): Promise<T> {
    const response = await this.rawFetch<T>(url, config)
    return response.data
  }

  async get<T>(url: string): Promise<T> {
    return this.fetchAndParse<T>(url, { method: 'GET' })
  }
}

export const Tel3ClientContext = React.createContext<Tel3Client>(new Tel3Client())
