import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Loading } from '../../global/Loading/Loading'
import { Tabs, defineTab } from '../../global/tabs/Tabs'
import { ScrollToIds } from '../../hooks/useScrollToElementIds'
import { PageHeader } from '../../ui/Table/Header/PageHeader'
import { TextH3 } from '../../ui/Typography/Typography'
import { useWindowResize } from '../../utils/domUtils'
import { useIBReferralCodes } from '../../utils/useIBReferralCodes'
import { useScrollToTop } from '../../utils/useScrollToTop'
import { IBSignUpPage } from '../SignUp/IBSignUpPage'
import { MultiTierCommision } from './MultiTierParts/MultiTierCommision'
import { MultiTierNetwork } from './MultiTierParts/MultiTierNetwork'
import { MultiTierSubAccounts } from './MultiTierParts/MultiTierSubAccounts'

import styles from './MultiTierPage.module.scss'

export const MultiTierPage = (): JSX.Element => {
  useScrollToTop()
  const { t } = useTranslation()
  const isMobile = useWindowResize()
  const [tab, setTab] = useState('commissionPlans')
  const { isLoading, mtCode, refreshReferralCodes } = useIBReferralCodes()
  const ibSignupFormSubmitted = localStorage.getItem('ibSignupFormSubmitted') === 'true'

  function* getTabs() {
    yield {
      ...defineTab('commissionPlans', t('IB.MultiTier.IB Commission Plans')),
    }
    yield {
      ...defineTab('subIB', t('IB.MultiTier.Sub-IB Accounts')),
    }
    yield {
      ...defineTab('network', t('IB.MultiTier.Multi-tier Network')),
    }
  }

  const tabs = [...getTabs()]

  if (isLoading) {
    return <Loading showLoadingIcon isLoading={isLoading} />
  }

  if (!mtCode) {
    return (
      <>
        <PageHeader title={t('IB.Introducing Broker Program')} id={ScrollToIds.IBSignupHeader} />
        <IBSignUpPage
          reloadCodes={refreshReferralCodes}
          ibSignupFormSubmitted={ibSignupFormSubmitted}
        />
      </>
    )
  }

  if (isMobile) {
    return (
      <div>
        <div className={styles.tabsWrapper}>
          <Tabs tabs={tabs} currentTab={tab} onTabChanged={setTab} />
        </div>
        <div className={styles.balances}>
          {tab === 'commissionPlans' && <MultiTierCommision />}
          {tab === 'subIB' && <MultiTierSubAccounts />}
          {tab === 'network' && <MultiTierNetwork />}
        </div>
      </div>
    )
  }

  return (
    <>
      <TextH3 className={styles.title}>{t('IB.MultiTier.Multi-Tier Dashboard')}</TextH3>
      <div className={styles.multiTierTables}>
        <div className={styles.column}>
          <MultiTierCommision />
          <MultiTierNetwork />
        </div>
        <div className={styles.column}>
          <MultiTierSubAccounts />
        </div>
      </div>
    </>
  )
}
