import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { TwoFactorAuthEvents, TwoFactorErrors } from '../TwoFactorAuth/useTwoFactorAuth'
import { useEntitySettings } from '../global/context/EntityContext'
import { ToastContext, errorToast } from '../global/toast/Toast'
import { ResponseError, useApiClient } from '../utils/ApiClient'
import { ClientApiClient } from '../utils/clientApi'
import { getTickmillCompanyByHostname, getTickmillSupportEmail } from '../utils/companyName.utils'
import { useScrollToTop } from '../utils/useScrollToTop'
import { LoginForm, LoginFormValues } from './LoginForm'
import { useSignInAuth } from './useSignInAuth'

import styles from './LoginPage.module.scss'

export interface LoginPageProps {
  skipLogout?: boolean
}

export const LoginPage: React.FunctionComponent<LoginPageProps> & LoginPageProps = (props) => {
  useScrollToTop()

  const navigate = useNavigate()
  const apiClient = useApiClient(ClientApiClient)
  const setToast = useContext(ToastContext)
  const { t } = useTranslation()
  const [entity, setEntity] = useEntitySettings()
  const actualEntity = entity.entity
  useEffect(() => {
    // setEntity to default when visit Login Page.
    if (!props.skipLogout) {
      setEntity({ entity: getTickmillCompanyByHostname() })
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  const { handleSignInAuth } = useSignInAuth()

  const onLogin = async (values: LoginFormValues) => {
    try {
      if (props.skipLogout) {
        return undefined
      }

      const loginResponse = await apiClient.login(values)
      const hasTwoFactor = (loginResponse?.twoFactor?.length ?? 0) > 0

      if (hasTwoFactor) {
        localStorage.setItem('2fa.email', loginResponse.email)
        navigate('/login/2fa', {
          state: {
            twoFactorAuthData: {
              ...loginResponse,
              password: values.password,
              twoFactorAuthEvent: TwoFactorAuthEvents.LogIn,
            },
          },
        })
        return
      } else {
        handleSignInAuth(loginResponse)
      }
    } catch (error: unknown) {
      if (error instanceof ResponseError) {
        const { response } = error.response
        if (response?.data.code === 'invalid_user_status_vulnerable_client') {
          setToast(
            errorToast({
              type: 'danger',
              dangerouslySetInnerHTML: {
                __html: t(
                  'Sign up.From the information you have provided, we do not think this product is appropriate for you at this time. If you would like to discuss further, please contact {{email}}.',
                  {
                    email: getTickmillSupportEmail(getTickmillCompanyByHostname(true)),
                  }
                ),
              },
            })
          )
        } else if (
          response?.data.code === TwoFactorErrors.TooManyAttempts ||
          response?.data.code === TwoFactorErrors.TooManySends
        ) {
          navigate('/login/2fa/expired', {
            state: {
              twoFactorAuthData: {
                twoFactorAuthEvent: TwoFactorAuthEvents.LogIn,
                email: values.email,
              },
            },
          })
          const errorTimer = error.response.response?.data.properties?.cooldownInMinutes || '30'
          setToast(
            errorToast({
              type: 'danger',
              dangerouslySetInnerHTML: {
                __html: t('errors.Too many resend attempts', {
                  minutes: errorTimer,
                }),
              },
            })
          )
        } else if (response?.data.code === 'invalid_credentials') {
          setToast(
            errorToast({
              type: 'danger',
              title: t('errors.Invalid credentials'),
            })
          )
        } else {
          return
        }
      }
      console.error(error)
      throw error
    }
  }

  return (
    <div className={styles.topWrapper}>
      <div className={styles.wrapper}>
        <div className={styles.inner}>
          <h2>{t('Log in')}</h2>
          <LoginForm entity={actualEntity} onSubmit={onLogin} />
        </div>
      </div>
    </div>
  )
}
