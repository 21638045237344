import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { handleBaseTwoFactorErrors } from '../../../../Profile/AccountSettings/handleBaseTwoFactorErrors'
import { TwoFactorAuthEvents } from '../../../../TwoFactorAuth/useTwoFactorAuth'
import { Loading } from '../../../../global/Loading/Loading'
import { useSessionLanguage } from '../../../../global/context/SessionSettingsContext'
import { useFormatNumber } from '../../../../hooks/useFormatNumber'
import {
  CreateWalletToPspWithdrawTransaction,
  denormalizeCreateWalletToPspWithdrawTransaction,
} from '../../../../model/CreateWalletToPspWithdrawTransaction'
import { InitWalletToPspWithdrawTransactionCommand } from '../../../../model/InitWalletToPspWithdrawTransactionCommand'
import { MasterTransaction } from '../../../../model/MasterTransaction'
import { PaymentProviderType } from '../../../../model/PaymentProviderType'
import { TwoFAProviderEnum } from '../../../../model/TwoFactorAuthentication'
import { WalletDto, WalletTypeEnum } from '../../../../model/WalletDto'
import { useApiClient } from '../../../../utils/ApiClient'
import { ClientApiClient } from '../../../../utils/clientApi'
import { sleep } from '../../../../utils/transaction.utils'
import { useCallbackWithForceRefresh } from '../../../../utils/useCallbackWithForceRefresh'
import { useFetchOne } from '../../../../utils/useFetch'
import { useMutation } from '../../../../utils/useMutation'
import { useScrollToTop } from '../../../../utils/useScrollToTop'
import { WalletRestrictions, isRestricted } from '../../../../utils/wallet.utils'
import {
  WalletWithdrawForm,
  WalletWithdrawFormValues,
} from '../WalletWithdrawForm/WalletWithdrawForm'

interface WalletWithdrawFetchProps {
  onCancel(): void
}

interface UseWalletWithdrawFetch {
  data: WalletDto | undefined
  isLoading: boolean
}

export const useWalletWithdrawFetch = (props: WalletWithdrawFetchProps): UseWalletWithdrawFetch => {
  const { walletId } = useParams<{ walletId?: string }>()

  const apiClient = useApiClient(ClientApiClient)

  const { callback } = useCallbackWithForceRefresh(async () => {
    if (walletId) {
      return apiClient.getWalletById(walletId)
    }
  }, [walletId])

  const { data, isLoading } = useFetchOne(callback)

  useEffect(() => {
    if (isRestricted(WalletRestrictions.WITHDRAW, data?.restrictions)) {
      props.onCancel()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.restrictions])

  return { data, isLoading }
}

interface WalletWithdrawMutateProps {
  onSubmit(data: MasterTransaction, values: WalletWithdrawFormValues): void
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useWalletWithdrawMutate = (props: WalletWithdrawMutateProps) => {
  const { onSubmit } = props
  const locale = useSessionLanguage()
  const navigate = useNavigate()
  const { t } = useTranslation()
  const location = useLocation()

  const apiClient = useApiClient(ClientApiClient)

  const handleSuccess = async (data: MasterTransaction, values: WalletWithdrawFormValues) => {
    onSubmit(data, values)
  }

  const handle2FAWithdrawal = ({
    response,
    payload,
    paymentProviderCategoryId,
    isWithdrawFromIBOrPartners,
  }: {
    response: MasterTransaction
    payload: Omit<InitWalletToPspWithdrawTransactionCommand, 'caller' | 'clientIpAddress'>
    paymentProviderCategoryId?: PaymentProviderType
    isWithdrawFromIBOrPartners: boolean
  }) => {
    const googleAuthProvider =
      response.twoFactor?.find(
        (twoFactor) => twoFactor?.provider?.id === TwoFAProviderEnum.GoogleAuthenticator
      ) ?? !response?.twoFactor?.[0]?.authMedium

    const authMedium = googleAuthProvider
      ? t('Profile.Google Authenticator app')
      : response.twoFactor?.[0]?.authMedium

    navigate('/2fa/confirm', {
      state: {
        twoFactorAuthData: {
          twoFactorAuthEvent: TwoFactorAuthEvents.Withdrawal,
          twoFactor: googleAuthProvider
            ? [{ ...response.twoFactor?.[0], authMedium }]
            : response.twoFactor,
          withdrawalData: {
            withdrawalPayload: payload,
            withdrawalProviderCategoryId: paymentProviderCategoryId,
          },
          isWithdrawFromIBOrPartners,
        },
      },
    })
  }

  return useMutation(
    async (values: WalletWithdrawFormValues) => {
      const payload = denormalizeCreateWalletToPspWithdrawTransaction(
        createPspToWalletWithdrawTransaction(values),
        locale
      )

      await sleep(1000)

      try {
        const response = await apiClient.transactionWithdraw(payload)
        const hasTwoFactor = (response?.twoFactor?.length ?? 0) > 0
        const isWithdrawFromIBOrPartners = location.pathname.includes(
          '/dashboard/introducing-broker/'
        )

        if (hasTwoFactor) {
          handle2FAWithdrawal({
            response,
            payload,
            paymentProviderCategoryId: values.paymentProvider.providerCategory.id,
            isWithdrawFromIBOrPartners,
          })
        }

        return response
      } catch (error: unknown) {
        const isErrorCaught = handleBaseTwoFactorErrors(error, navigate)
        if (!isErrorCaught) {
          throw error
        }
      }
    },
    {
      onSuccess: handleSuccess,
    }
  )
}

interface WalletWithdrawPageProps {
  wallet?: WalletDto
  walletType: WalletTypeEnum

  onSubmit(data: MasterTransaction, values: WalletWithdrawFormValues): void

  onCancel(): void
  title?: string
}

export const WalletWithdrawPage: React.FC<WalletWithdrawPageProps> = (props) => {
  const { wallet, walletType, onSubmit, onCancel, title } = props

  useScrollToTop()

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const mutation = useWalletWithdrawMutate({ onSubmit })

  const handleSubmit = async (values: WalletWithdrawFormValues) => {
    await mutation.mutate(values)
  }

  const handlePageExitConfirmation = () => {
    onCancel()
  }

  return (
    <React.Fragment>
      {!mutation.isLoading && (
        <WalletWithdrawForm
          formatMoney={formatMoney}
          wallet={wallet}
          walletType={walletType}
          onSubmit={handleSubmit}
          onCancel={handlePageExitConfirmation}
          title={title}
        />
      )}
      {mutation.isLoading && (
        <Loading isLoading={mutation.isLoading} showLoadingIcon text={t('Withdrawing')} />
      )}
    </React.Fragment>
  )
}

const createPspToWalletWithdrawTransaction = (
  values: WalletWithdrawFormValues
): CreateWalletToPspWithdrawTransaction => {
  return {
    fromWallet: {
      id: values.wallet.id,
      currency: {
        id: values.wallet.currency?.id,
      },
    },
    toPaymentProvider: {
      id: values.paymentProvider.id,
      currency: {
        id: values.paymentProvider.currency.id,
      },
    },
    amount: values.amount,
    comment: values.comment,
    terms: values.terms.paymentOperations,
    form: values.paymentProvider.parameters.form,
    fields: values.fields,
  }
}
