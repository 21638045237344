import React, { useEffect } from 'react'

import { useEntitySettings } from '../../../../global/context/EntityContext'
import { useSignup } from '../../../../global/context/SignupContext'
import { isVerificationExpedited } from '../../../../model/AccountDetailedDto'
import {
  isDepositBeforeKYC,
} from '../../../../utils/AccountAccess/accountKYCStatuses'
import { useAccountReadContext } from '../../../../utils/AccountContextContext'
import { FirstTimeDepositPageSignup } from './FirstTimeDepositPageSignup'
import { Navigate } from 'react-router-dom'

export const FirstTimeDepositOrDocumentsResolver: React.FC = () => {
  const { account } = useAccountReadContext()
  const { signupData } = useSignup()
  const [entity, setEntity] = useEntitySettings()

  useEffect(() => {
    if (signupData.lead) {
      if (signupData.lead.tickmillCompany.id) {
        if (entity.entity !== signupData.lead.tickmillCompany.id) {
          setEntity({ entity: signupData.lead.tickmillCompany.id })
        }
      }
    }
  }, [entity.entity, setEntity, signupData.lead])

  if (!isDepositBeforeKYC(account) && !isVerificationExpedited(account)) {
    return <Navigate to={`/sign-up/documents-info/${account?.id}`} replace />
  }

  return <FirstTimeDepositPageSignup />
}
