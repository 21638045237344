import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { CheckIcon } from '../../icons/CheckIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { ValidationOperationId, ValidationRuleId } from '../../model/ValidationDto'
import { errorOptions } from '../../routing/RouterToaster'
import { ResponseError, instanceOfAxiosError, useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { useFetchOne } from '../../utils/useFetch'

import styles from './PasswordValidation.module.scss'

interface PasswordValidationReturn {
  validationComponent: JSX.Element | null
  isPasswordValid: boolean
}

interface PasswordValidationProps {
  password: string
  confirmPassword?: string
  showOneValidation?: boolean
  validationOperationId?: ValidationOperationId
  passwordResetData?: {
    email: string
    token: string
  }
  leadData?: {
    id: string
    token: string
  }
}

const usePasswordValidationRulesFetch = (props: PasswordValidationProps) => {
  const {
    validationOperationId = ValidationOperationId.ValidateClientPlainPassword,
    leadData,
    passwordResetData,
  } = props

  const locale = useSessionLanguage()
  const apiClient = useApiClient(ClientApiClient)
  const navigate = useNavigate()

  const passwordValidationCallback = useCallback(
    async () => {
      try {
        if (leadData) {
          return await apiClient.getLeadPasswordValidationRules(leadData, locale)
        }
        if (passwordResetData) {
          return await apiClient.getResetPasswordValidationRules({
            email: passwordResetData.email,
            token: passwordResetData.token,
            languageId: locale,
          })
        }
        return await apiClient.getPasswordValidationRules(validationOperationId, locale)
      } catch (error: unknown) {
        if (instanceOfAxiosError((error as ResponseError)?.response)) {
          const errorResponse = (error as ResponseError).response.response?.data
          if (
            errorOptions.verifiable_action_unavailable === errorResponse?.code ||
            errorResponse?.code === 'invalid_token'
          ) {
            navigate('/login')
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [locale]
  )
  const { data: passwordValidationRules } = useFetchOne(passwordValidationCallback)

  return { passwordValidationRules }
}

export const usePasswordValidation = (props: PasswordValidationProps): PasswordValidationReturn => {
  const { password, confirmPassword, showOneValidation, leadData } = props

  const { t } = useTranslation()

  const { passwordValidationRules } = usePasswordValidationRulesFetch(props)
  const passwordValidations: Validation[] = []

  if (passwordValidationRules?.validationRules.length) {
    const filteredValidationRules = passwordValidationRules.validationRules.filter(
      (vRule) => vRule.shouldValidateOnFrontEnd
    )

    const newPasswordValidations = filteredValidationRules.map((rule) => {
      const flags = rule.id === ValidationRuleId.StringSplitElementCheck ? 'i' : ''
      const regex = new RegExp(rule.regex, flags)
      return { isValid: regex.test(password), message: rule.ruleText }
    })

    passwordValidations.push(...newPasswordValidations)
  }

  if (props?.passwordResetData || props?.leadData) {
    passwordValidations.push({
      isValid: password === confirmPassword,
      message: t('Validation.Please make sure your password match'),
    })
  }

  const validationComponent = showOneValidation ? (
    passwordValidations.filter((pV) => !pV.isValid)[0] ? (
      <ul>
        <ValidationLine i={0} validation={passwordValidations.filter((pV) => !pV.isValid)[0]} />
      </ul>
    ) : null
  ) : (
    <ul>
      {passwordValidations.map((validation, i) => (
        <ValidationLine i={i} validation={validation} key={i} />
      ))}
    </ul>
  )

  return {
    validationComponent,
    isPasswordValid: passwordValidations.every((pV) => pV.isValid),
  }
}

interface Validation {
  isValid: boolean
  message: string
}

const ValidationLine = ({ validation, i }: { validation: Validation; i: number }) => {
  return (
    <li key={i} className={styles.validationPoint}>
      <div className={styles.validationPointBox}>
        <div>
          {validation.isValid ? (
            <div className={styles.iconBox}>
              <CheckIcon size={18} color={'success'} />
            </div>
          ) : (
            <div className={styles.iconBox}>
              <WarningIcon color={'error'} size={20} />
            </div>
          )}
        </div>
        <div>{validation.message}</div>
      </div>
    </li>
  )
}
