import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavigateOptions } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { useSnackbar } from '../../global/context/SnackbarContext'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { DotsIcon } from '../../icons/DotsIcon'
import { WarningIcon } from '../../icons/WarningIcon'
import { TextSmall, TextSmallStrong } from '../../ui/Typography/Typography'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../utils/wallet.utils'
import { IntroducingBrokerMappedDto } from './IBWalletsPage'

import styles from './IBWalletCard.module.scss'

interface WalletCardProps {
  wallet: IntroducingBrokerMappedDto
  isLoading: boolean

  setWalletNumberModal({
    visible,
    data,
  }: {
    visible: boolean
    data?: IntroducingBrokerMappedDto
  }): void
  handleWithdrawalNavigation: (withdrawalOptions?: {
    walletId?: string
    navigateOptions?: NavigateOptions
  }) => Promise<void>
}

export const IBWalletCard: React.FC<WalletCardProps> = ({
  wallet,
  setWalletNumberModal,
  handleWithdrawalNavigation,
  isLoading,
}) => {
  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const { addSnackbar } = useSnackbar()

  const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
  const isTransferFromRestricted = isRestricted(
    WalletRestrictions.TRANSFER_FROM,
    wallet.restrictions
  )
  const isWithdrawRestricted = isRestricted(WalletRestrictions.WITHDRAW, wallet.restrictions)
  return (
    <div className={styles.card}>
      <TextSmall className={styles.warning}>
        {hasAllRestrictions && <WarningIcon size={20} color={'error'} />}
        {wallet.currency.id} {t('Wallet.IB Wallet')}
      </TextSmall>
      <span className='is-pulled-right'>
        <span
          onClick={() =>
            setWalletNumberModal({
              visible: true,
              data: wallet,
            })
          }
          className='has-cursor-pointer'
        >
          <DotsIcon />
        </span>
      </span>
      <h2 className={styles.currency}>{formatMoney(wallet.balance, wallet.currency.id)}</h2>
      <div className='mb-1'>
        <Link
          to={
            isTransferFromRestricted
              ? '#'
              : `/dashboard/introducing-broker/wallets/${wallet.id}/transfer`
          }
        >
          <Button
            onClick={() => {
              if (!hasAllRestrictions && isTransferFromRestricted) {
                addSnackbar.error({ message: t('This action is not available') })
              }
            }}
            size='M'
            appearance='primary'
            state={isTransferFromRestricted ? 'disabled' : 'normal'}
            className={styles.button}
          >
            <span className='is-flex is-align-items-center'>
              {!hasAllRestrictions && isTransferFromRestricted && (
                <WarningIcon size={20} className='mr-1' color='error' />
              )}
              {t('Transfer')}
            </span>
          </Button>
        </Link>
      </div>
      <div className='mb-4'>
        <Button.New
          onClick={() => {
            if (!hasAllRestrictions && isWithdrawRestricted) {
              addSnackbar.error({ message: t('This action is not available') })
            } else {
              handleWithdrawalNavigation({ walletId: wallet.id })
            }
          }}
          size='M'
          appearance='secondary'
          state={isWithdrawRestricted ? 'disabled' : 'normal'}
          className={styles.button}
          loading={isLoading}
        >
          <span className='is-flex is-align-items-center'>
            {!hasAllRestrictions && isWithdrawRestricted && (
              <WarningIcon size={20} className='mr-1' color='error' />
            )}
            {t('Withdraw')}
          </span>
        </Button.New>
      </div>
      <div className={styles.title}>
        <TextSmall isParagraph className={styles.label}>
          {t('IB.Incoming Reserved')}
        </TextSmall>
        <TextSmallStrong className={styles.value}>
          {formatMoney(wallet.incomingReserved, wallet.currency.id)}
        </TextSmallStrong>
      </div>
      <div className={styles.title}>
        <TextSmall isParagraph className={styles.label}>
          {t('IB.Outgoing Reserved')}
        </TextSmall>
        <TextSmallStrong className={styles.value}>
          {formatMoney(wallet.reserved, wallet.currency.id)}
        </TextSmallStrong>
      </div>
      <div className={styles.title}>
        <TextSmall isParagraph className={styles.label}>
          {t('IB.All Time Reward')}
        </TextSmall>
        <TextSmallStrong className={styles.value}>
          {formatMoney(wallet.allTimeReward, wallet.currency.id)}
        </TextSmallStrong>
      </div>
      <div className={classNames('pt-4', styles.view)}>
        <Link
          className={styles.history}
          to='/dashboard/introducing-broker/transaction-history'
          state={{ walletId: wallet.id }}
        >
          {t('View Transaction History')}
        </Link>
      </div>
    </div>
  )
}
