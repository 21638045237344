import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link, NavigateOptions } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { useSnackbar } from '../../global/context/SnackbarContext'
import { useFormatNumber } from '../../hooks/useFormatNumber'
import { WarningIcon } from '../../icons/WarningIcon'
import { AlertIconWrapper } from '../../ui/AlertIconWrapper/AlertIconWrapper'
import { TextSmall } from '../../ui/Typography/Typography'
import { WalletRestrictions, isRestricted, isWalletSuspended } from '../../utils/wallet.utils'
import { PaymentAgentWalletMappedDto } from './PaymentAgentWalletsPage'

import styles from './PaymentAgentWalletsCard.module.scss'

interface Props {
  wallet: PaymentAgentWalletMappedDto
  isLoading: boolean
  handleWithdrawalNavigation: (withdrawalOptions?: {
    walletId?: string
    navigateOptions?: NavigateOptions
  }) => Promise<void>
}

export const PaymentAgentWalletsCard: React.FC<Props> = (props) => {
  const { wallet, isLoading, handleWithdrawalNavigation } = props

  const { t } = useTranslation()
  const { formatMoney } = useFormatNumber()
  const { addSnackbar } = useSnackbar()

  const handleRestrictionModalOpen = () => {
    const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
    if (!hasAllRestrictions) {
      addSnackbar.error({ message: t('This action is not available') })
    }
  }

  const hasAllRestrictions = isWalletSuspended(wallet.restrictions)
  const isTransferFromRestricted = isRestricted(
    WalletRestrictions.TRANSFER_FROM,
    wallet.restrictions
  )
  const isWithdrawRestricted = isRestricted(WalletRestrictions.WITHDRAW, wallet.restrictions)
  const isDepositRestricted = isRestricted(WalletRestrictions.DEPOSIT, wallet.restrictions)

  return (
    <div className={classNames('card', styles.card)}>
      <div className={styles.container}>
        <div className={styles.card} key={wallet.id}>
          <td>
            {hasAllRestrictions && <WarningIcon size={20} color={'error'} />}

            {t(`Wallet.PA Currency Wallet`, {
              walletCurrencyId: wallet.currency.id,
            })}
          </td>
          <h2>{formatMoney(wallet.balance, wallet.currency.id)}</h2>
          <td>
            <TextSmall className={styles.reserved}>{t('Wallet.Reserved')}</TextSmall>
            {formatMoney(wallet.reserved, wallet.currency.id)}
          </td>
          <div className={styles.btn}>
            {isDepositRestricted && (
              <AlertIconWrapper fullWidth spaced isActive={!hasAllRestrictions}>
                <Button
                  size='M'
                  appearance='secondary'
                  state='disabled'
                  onClick={handleRestrictionModalOpen}
                  className={styles.button}
                >
                  {t('Deposit')}
                </Button>
              </AlertIconWrapper>
            )}
            {!isDepositRestricted && (
              <Link to={`/dashboard/payment-agent/wallets/${wallet.id}/deposit`}>
                <Button size='M' appearance='secondary' className={styles.button}>
                  {t('Deposit')}
                </Button>
              </Link>
            )}
          </div>
          <div className={styles.btn}>
            {isTransferFromRestricted && (
              <AlertIconWrapper fullWidth spaced isActive={!hasAllRestrictions}>
                <Button
                  size='M'
                  appearance='primary'
                  state='disabled'
                  onClick={handleRestrictionModalOpen}
                  className={styles.button}
                >
                  {t('Transfer')}
                </Button>
              </AlertIconWrapper>
            )}
            {!isTransferFromRestricted && (
              <Link to={`/dashboard/payment-agent/wallets/${wallet.id}/transfer`}>
                <Button size='M' appearance='primary' className={styles.button}>
                  {t('Transfer')}
                </Button>
              </Link>
            )}
          </div>
          <div className={styles.btn}>
            {isWithdrawRestricted && (
              <AlertIconWrapper spaced fullWidth isActive={!hasAllRestrictions}>
                <Button
                  size='M'
                  appearance='secondary'
                  state='disabled'
                  onClick={handleRestrictionModalOpen}
                  className={styles.button}
                >
                  {t('Withdraw')}
                </Button>
              </AlertIconWrapper>
            )}
            {!isWithdrawRestricted && (
              <Button
                size='M'
                appearance='secondary'
                loading={isLoading}
                className={styles.button}
                onClick={() => handleWithdrawalNavigation({ walletId: wallet.id })}
              >
                {t('Withdraw')}
              </Button>
            )}
          </div>
          <td className='mb-3 mt-3'>
            <Link
              className={classNames(styles.link, 'is-link is-right')}
              to='/dashboard/payment-agent/transaction-history'
              state={{ walletId: wallet.id }}
            >
              {t('View Transaction History')}
            </Link>
          </td>
        </div>
      </div>
    </div>
  )
}
