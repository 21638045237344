import React from 'react'
import { useTranslation } from 'react-i18next'

import { ExternalLink } from '../../../global/ExternalLink'
import { useSessionEntity } from '../../../global/context/EntityContext'
import { useLegalLinksContext } from '../../../global/context/LegalLinksContext'
import { InfoModal } from '../../../global/modal/InfoModal'
import { TickmillProductType } from '../../../model/TickmillProductType'
import { Text, TextStrong } from '../../../ui/Typography/Typography'
import { TickmillCompaniesEnum } from '../../../utils/companyName.utils'
import { isOne, isZero } from '../../../utils/validations'

interface RiskWarningModalProps {
  selectedProductIds?: TickmillProductType[]
  risk: number | undefined

  onCancel(): void
}

export const RiskWarningModal: React.FC<RiskWarningModalProps> = (props) => {
  const { onCancel, risk, selectedProductIds } = props
  const entity = useSessionEntity()

  const { docLinks } = useLegalLinksContext()

  const isProductTypeCFD =
    isOne(selectedProductIds?.length) && selectedProductIds?.includes(TickmillProductType.CFD)
  const isProductTypeETD =
    isOne(selectedProductIds?.length) && selectedProductIds?.includes(TickmillProductType.ETD)
  const isProductTypeCFDETD =
    isZero(selectedProductIds?.length) ||
    (selectedProductIds?.length === 2 &&
      selectedProductIds?.includes(TickmillProductType.CFD) &&
      selectedProductIds?.includes(TickmillProductType.ETD))

  const { t } = useTranslation()

  return (
    <InfoModal
      onCancel={onCancel}
      title={t('Sign up.Risk Warning')}
      renderBody={() => (
        <section className='modal-card-body'>
          <Text isParagraph>
            {entity === TickmillCompaniesEnum.TICKMILL_EU &&
              t('Sign up.Risk Warning Desc EU', {
                risk,
              })}
            {entity === TickmillCompaniesEnum.TICKMILL_AS &&
              !!risk &&
              t('Sign up.Risk Warning Desc Consolidated', {
                risk,
              })}
            {entity === TickmillCompaniesEnum.TICKMILL_AS &&
              !risk &&
              t('Sign up.Risk Warning Desc')}
            {entity === TickmillCompaniesEnum.TICKMILL_SC &&
              !!risk &&
              t('Sign up.Risk Warning Desc Consolidated', {
                risk,
              })}
            {entity === TickmillCompaniesEnum.TICKMILL_SC &&
              !risk &&
              t('Sign up.Risk Warning Desc')}
            {entity === TickmillCompaniesEnum.TICKMILL_PA &&
              !!risk &&
              t('Sign up.Risk Warning Desc Consolidated', {
                risk,
              })}
            {entity === TickmillCompaniesEnum.TICKMILL_PA &&
              !risk &&
              t('Sign up.Risk Warning Desc')}
            {entity === TickmillCompaniesEnum.GENERIC && t('Sign up.Risk Warning Desc')}
            {entity === TickmillCompaniesEnum.TICKMILL_UK && isProductTypeCFDETD && (
              <>
                {t('highRiskWarning.CFD', {
                  risk: risk,
                  entity: 'Tickmill UK Ltd',
                })}{' '}
                <ExternalLink className='is-link' url={docLinks?.riskDisclosure}>
                  {t('Footer.Risk Disclosure')}
                </ExternalLink>
                .
                <br />
                <br />
                {t('highRiskWarning.ETD', {
                  risk: risk,
                  entity: 'Tickmill UK Ltd',
                })}{' '}
                <ExternalLink className='is-link' url={docLinks?.riskDisclosure}>
                  {t('Footer.Risk Disclosure')}
                </ExternalLink>{' '}
                {t('highRiskWarning.document')}.
              </>
            )}
            {entity === TickmillCompaniesEnum.TICKMILL_UK &&
              isProductTypeETD &&
              !isProductTypeCFD && (
                <>
                  <TextStrong>
                    {t('Sign up.Risk Warning', {
                      risk,
                    })}
                    :
                  </TextStrong>{' '}
                  {t('highRiskWarning.ETD', {
                    risk,
                  })}{' '}
                  <ExternalLink className='is-link' url={docLinks?.riskDisclosure}>
                    {t('Footer.Risk Disclosure')}
                  </ExternalLink>{' '}
                  {t('highRiskWarning.document')}.
                </>
              )}
            {entity === TickmillCompaniesEnum.TICKMILL_UK &&
              isProductTypeCFD &&
              !isProductTypeETD && (
                <>
                  {t('highRiskWarning.CFD', {
                    risk: risk,
                    entity: 'Tickmill UK Ltd',
                  })}{' '}
                  <ExternalLink className='is-link' url={docLinks?.riskDisclosure}>
                    {t('Footer.Risk Disclosure')}
                  </ExternalLink>
                  .
                </>
              )}
          </Text>
        </section>
      )}
      renderFooter={() => (
        <React.Fragment>
          <button className='button' onClick={onCancel} type='button'>
            <b>{t('Got It')}</b>
          </button>
        </React.Fragment>
      )}
      onConfirm={onCancel}
    />
  )
}
