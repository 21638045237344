import React, { ChangeEvent, useCallback, useEffect, useMemo } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { Loading } from '../../global/Loading/Loading'
import { useSessionEntity } from '../../global/context/EntityContext'
import { useSessionLanguage } from '../../global/context/SessionSettingsContext'
import { SelectField } from '../../global/field/SelectField'
import { ScrollToIds } from '../../hooks/useScrollToElementIds'
import { PlatformInfoDto } from '../../model/PlatformInfoDto'
import {
  PlatformTypeEnum,
  PlatformTypeShort,
  formatPlatformName,
  getLandingPlatform,
  getOperatingSystemName,
  getPlatformType,
  isTMTPlatformType,
  platformShortNames,
  sortPlatforms,
} from '../../model/PlatformTypeEnum'
import { TabSwitcher, TabsSwitcher } from '../../ui/SwitcherTabs/SwitcherTabs'
import { useApiClient } from '../../utils/ApiClient'
import { ClientApiClient } from '../../utils/clientApi'
import { isTickmillSCType } from '../../utils/companyName.utils'
import { getDomainName } from '../../utils/cookie.utils'
import { useWindowResize } from '../../utils/domUtils'
import { useFetchOne } from '../../utils/useFetch'

import styles from './Platforms.module.scss'

interface UsePlatformInfo {
  platformInfo: PlatformInfoDto[]
  platformId?: PlatformTypeEnum
  selectedPlatform?: PlatformInfoDto
  isLoading: boolean
}

export const usePlatformInfo = (): UsePlatformInfo => {
  const locale = useSessionLanguage()
  const entity = useSessionEntity()
  const location = useLocation()

  const apiClient = useApiClient(ClientApiClient)

  const platformInfoCallback = useCallback(async () => {
    return apiClient.getPlatformInfo({
      domain: getDomainName(true),
      languageId: locale,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  const { data = [], isLoading } = useFetchOne(platformInfoCallback)

  const getPlatformInfo = (platformInfoData: PlatformInfoDto[]) => {
    return platformInfoData.filter((platform) => {
      if (isTMTPlatformType(platform.platfromType.id) && !isTickmillSCType(entity)) {
        return false
      }
      return true
    })
  }

  const platformInfo = useMemo(() => {
    return getPlatformInfo(data)
      .filter(() => !isLoading)
      .filter(
        (item, index, self) =>
          index === self.findIndex((t) => t.platfromType.id === item.platfromType.id)
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  const getPlatformId = () => {
    const selectedPlatform = getSelectedPlatformInfo()
    return selectedPlatform?.platfromType?.id
  }

  const getSelectedPlatformInfo = () => {
    const firstPlatform = getLandingPlatform(platformInfo)

    return (
      sortedPlatforms?.find((p) =>
        location.pathname.includes(formatPlatformName(p.platfromType.name))
      ) || firstPlatform
    )
  }

  const sortedPlatforms = useMemo(
    () =>
      sortPlatforms(platformInfo, [
        PlatformTypeEnum.TickmillTrader,
        PlatformTypeEnum.TradingView,
        PlatformTypeEnum.MT4,
        PlatformTypeEnum.MT5,
        PlatformTypeEnum.None,
      ]),
    [platformInfo]
  )

  return {
    platformInfo: sortedPlatforms,
    isLoading,
    platformId: getPlatformId(),
    selectedPlatform: getSelectedPlatformInfo(),
  }
}

export const Platforms: React.FC = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const platformInfoProps = usePlatformInfo()
  const { platformId, platformInfo, isLoading } = platformInfoProps

  useEffect(() => {
    if (platformId && platformInfo) {
      const platform = getPlatformType(platformId)
      const platformName = platform.shortName
      const isTickmillTrader = platformName === PlatformTypeShort.TickmillTrader
      const operatingSystemName = getOperatingSystemName()
      const deviceName = isTickmillTrader ? operatingSystemName : undefined

      const path =
        isTickmillTrader && deviceName
          ? `/dashboard/tools/platforms/${platformName}/${deviceName}`
          : `/dashboard/tools/platforms/${platformName}`

      const alreadyOnPlatform = platformShortNames.some((platformShortName) =>
        platformShortName === PlatformTypeShort.TickmillTrader
          ? location.pathname.includes(platformShortName) &&
            location.pathname.includes(operatingSystemName)
          : location.pathname.includes(platformShortName)
      )

      if (!platformInfo || alreadyOnPlatform) {
        return
      }

      if (
        location.pathname.includes(path) ||
        !platformShortNames.includes(platformName as PlatformTypeShort) ||
        platform.shortName === PlatformTypeShort.None
      ) {
        return
      }

      navigate(path)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [platformInfo])

  const handlePlatformTabChange = (platformItem: PlatformInfoDto) => {
    const platformName = formatPlatformName(platformItem.platfromType.name)

    if (!platformName) {
      console.warn('Invalid platform name')
      return
    }

    const isTickmillTrader = platformName === PlatformTypeShort.TickmillTrader
    const deviceName = isTickmillTrader ? getOperatingSystemName() : undefined

    const path = deviceName
      ? `/dashboard/tools/platforms/${platformName}/${deviceName}`
      : `/dashboard/tools/platforms/${platformName}`

    navigate(path)
  }

  return (
    <>
      <div id={ScrollToIds.ToolsPlatformsHeader} />
      <Loading showLoadingIcon isLoading={isLoading}>
        <div className={styles.wrapper}>
          <div className={styles.buttonWrapper}>
            <Tabs {...platformInfoProps} onChange={handlePlatformTabChange} />
          </div>
          <Outlet context={platformInfoProps} />
        </div>
      </Loading>
    </>
  )
}

interface TabsProps extends ReturnType<typeof usePlatformInfo> {
  onChange(data: PlatformInfoDto): void
}

const Tabs: React.FC<TabsProps> = (props) => {
  const { platformInfo, platformId, onChange } = props

  const location = useLocation()
  const isMobile = useWindowResize()

  if (isMobile) {
    const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
      const value = event.target.value
      const selectedPlatformType = platformInfo.find(
        (x) => x?.platfromType?.id.toString() === value.toString()
      )
      selectedPlatformType && onChange(selectedPlatformType)
    }

    return (
      <SelectField
        value={platformId}
        options={platformInfo.map((x) => ({
          label: x.platfromType.name,
          value: x.platfromType.id,
        }))}
        fullWidth
        onChange={handleSelectChange}
      />
    )
  }

  return (
    <div className={styles.tabs}>
      <TabsSwitcher
        items={platformInfo}
        renderItem={(x) => (
          <TabSwitcher
            isActive={location.pathname.includes(formatPlatformName(x.platfromType.name))}
            onClick={() => onChange(x)}
          >
            {x.platfromType.name}
          </TabSwitcher>
        )}
      />
    </div>
  )
}
