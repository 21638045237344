import React from 'react'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

import { Button } from '../../global/button/Button'
import { InfoCard } from '../../ui/Popups/InfoCard/InfoCard'
import { TextH3, TextStrong } from '../../ui/Typography/Typography'

import styles from './TransactionsPage.module.scss'

export interface TCardProps {
  title: string
  description?: string
  renderDescription?(): React.ReactNode
  buttonText: string
  buttonInfoText?: string
  additionalDescription?: string
  appearance?: 'primary' | 'secondary'
  icon: React.ReactNode
  to?: string
  bottomSpaced?: boolean
  isLoading?: boolean
  lockAction?: boolean
  onClick?: () => void
}

export enum TransactionCardType {
  depositCFD = 1,
  depositETD = 2,
  withdrawalCFD = 3,
  withdrawalETD = 4,
  transferCFD = 5,
  transferETD = 6,
  conversionETD = 7,
}

export const Card: React.FC<TCardProps> = (props) => {
  const {
    title,
    buttonText,
    appearance = 'secondary',
    icon,
    buttonInfoText,
    to,
    lockAction,
    isLoading = false,
  } = props
  const { description, renderDescription, additionalDescription, bottomSpaced } = props
  const { onClick } = props

  const navigate = useNavigate()

  const handleClick = () => {
    if (onClick) {
      onClick()
    } else if (to) {
      navigate(to)
    }
  }

  return (
    <div className={styles.card}>
      <div className={styles.textBox}>
        {icon}
        {title && <TextH3 className={styles.title}>{title}</TextH3>}
        {description && (
          <div>
            <p className={styles.text}>{description}</p>
          </div>
        )}
        {renderDescription && <div className={styles.text}>{renderDescription()}</div>}
      </div>
      <div className={styles.actionBlock}>
        <InfoCard fullWidth text={buttonInfoText} disabled={!buttonInfoText || !lockAction}>
          <Button
            loading={isLoading}
            size='L'
            appearance={appearance}
            className={styles.buttonCard}
            fullWidth
            disabled={lockAction}
            onClick={handleClick}
          >
            <TextStrong>{buttonText}</TextStrong>
          </Button>
        </InfoCard>
        {(additionalDescription || bottomSpaced) && (
          <p
            className={classNames(styles.text, {
              [styles.bottomSpaced]: bottomSpaced,
            })}
          >
            {additionalDescription}
          </p>
        )}
      </div>
    </div>
  )
}
