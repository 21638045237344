import React, { PropsWithChildren, ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'

import { TickmillLoadingIcon } from '../../icons/TickmillLoadingIcon'

import styles from './Loading.module.scss'

export interface LoadingProps {
  isLoading: boolean
  text?: string
  className?: string
  showLoadingIcon?: boolean
  isModal?: boolean
  asOverlay?: boolean
  debug?: boolean
  renderText?: () => ReactNode
  disableIconAnimation?: boolean
  wrapperClass?: string
}

export const useLoadingDots = (): string => {
  const [index, setIndex] = useState(0)
  const patterns = ['.', '..', '...', '..']

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1) % patterns.length)
    }, 250)
    return () => clearInterval(interval)
  }, [patterns.length])

  return patterns[index]
}

export const Loading: React.FunctionComponent<PropsWithChildren<LoadingProps>> = ({
  isLoading,
  text,
  showLoadingIcon,
  isModal = false,
  asOverlay = false,
  renderText,
  debug,
  children,
  className,
  disableIconAnimation,
  wrapperClass,
}) => {
  const { t } = useTranslation()

  if (!isLoading) {
    return <>{children}</>
  }

  const loadingContent = (
    <div
      className={classNames(styles.wrapper, wrapperClass, {
        [styles.isModal]: isModal,
        [styles.debug]: debug,
      })}
    >
      {showLoadingIcon && (
        <div className={classNames(styles.icon, { [styles.stopAnimation]: disableIconAnimation })}>
          <TickmillLoadingIcon />
        </div>
      )}

      {renderText ? (
        renderText()
      ) : (
        <div className={className}>
          <p>{text ?? t('Loading...')}</p>
        </div>
      )}
    </div>
  )

  return asOverlay ? (
    <div className={styles.overlay}>
      {loadingContent}
      {children}
    </div>
  ) : (
    loadingContent
  )
}
